export const globalStyles = {
    html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
    },
    body: {
        backgroundColor: "#f4f6f8",
        height: "100%",
        width: "100%",
    },
    a: {
        textDecoration: "none",
    },
    "#root": {
        height: "100%",
        width: "100%",
    },
};
