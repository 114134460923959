import React from "react";
import { useParams } from "react-router";
import { Grid } from "@mui/material";
import { DetailViewContainer } from "components/containers/DetailViewContainer";
import { TaskDetailsFragment, useTasksDetailQuery } from "lib/api";
import { Loading } from "components/utils/Loading";
import NotFoundPage from "components/layout/NotFoundPage";
import { formatTasksValue } from "utils/formatters";

export const TaskPage = () => {
    const { taskkey } = useParams();
    const { data, loading } = useTasksDetailQuery({ skip: !taskkey, variables: { key: +taskkey } });

    if (loading) return <Loading />;

    if (!taskkey || !data?.Task) return <NotFoundPage />;

    const { Task } = data;
    return (
        <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <DetailViewContainer<TaskDetailsFragment>
                    data={Task}
                    fields={[
                        "taskstatus",
                        "laststatuschangeddate",
                        "taskactiontype",
                        "tasksubject",
                        "taskduedate",
                        "taskduetime",
                        "taskcomments",
                        "unifieddate",
                        "tasklinkcontacts_entities",
                        "tasklinkpropressales_entities",
                        "tasklinkpropreslettings_entities",
                    ]}
                    label="translation:task"
                    translations={{
                        tasklinkcontacts_entities: "tasklinkcontacts",
                        tasklinkpropressales_entities: "tasklinkpropressales",
                        tasklinkpropreslettings_entities: "tasklinkpropreslettings",
                    }}
                    formatter={formatTasksValue}
                />
            </Grid>
        </Grid>
    );
};
