import Typography from "@mui/material/Typography/Typography";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Contactcategory, ContactQueryWhere, Contactstatus } from "lib/api";
import { EnumSelect } from "components/utils/EnumSelect";
import { SearchBar } from "components/utils/SearchBar";
import { selectContactsListFilters, setFilter } from "store/slices/contactsList.slice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { enumToObject } from "utils/convertors";

const filteredCategories = [
    Contactcategory.Buyer,
    Contactcategory.Owner,
    Contactcategory.Servicecontact,
    Contactcategory.Contactperson,
    Contactcategory.Tenant,
];
const categoriesOptions = enumToObject(Contactcategory, filteredCategories);
const statusesOptions = enumToObject(Contactstatus);

export const ContactListHeader: FC = () => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector(selectContactsListFilters);

    const { t } = useTranslation("backend");

    const onChange = useMemo(
        () => (field: string, value: string) => dispatch(setFilter({ field: field as keyof ContactQueryWhere, value })),
        [dispatch],
    );

    const onSelect = useMemo(
        () =>
            <Option extends unknown>(field: string, value: Option | null) => {
                dispatch(setFilter({ field: field as keyof ContactQueryWhere, value }));
            },
        [dispatch],
    );

    return (
        <Box paddingTop={2} paddingBottom={2}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>{t("translation:search_and_filter")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="utag" onChange={onChange} placeholder={t("translation:utag")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="title" onChange={onChange} placeholder={t("title")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="firstname" onChange={onChange} placeholder={t("firstname")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="surname" onChange={onChange} placeholder={t("surname")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="company" onChange={onChange} placeholder={t("company")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="streetname" onChange={onChange} placeholder={t("streetname")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="streetnumber" onChange={onChange} placeholder={t("streetnumber")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="city" onChange={onChange} placeholder={t("city")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="phone" onChange={onChange} placeholder={t("phone")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="email" onChange={onChange} placeholder={t("email")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="createdfor" onChange={onChange} placeholder={t("agent")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <EnumSelect<Contactstatus>
                                value={filters.status as Contactstatus | undefined}
                                name="status"
                                onChange={onSelect}
                                options={statusesOptions}
                                enableNoneSelection
                                emptyLabel={t("backend:status")}
                                translationPrefix="backend:contactstatus."
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <EnumSelect<Contactcategory>
                                value={filters.category as Contactcategory | undefined}
                                name="category"
                                onChange={onSelect}
                                options={categoriesOptions}
                                enableNoneSelection
                                emptyLabel={t("backend:contactcategory")}
                                translationPrefix="backend:contactcategory."
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
