/* global TableProps */
import React, { FC } from "react";
import { LettingsListRowFragment } from "lib/api";
import { Box, Divider, TablePagination, Typography } from "@mui/material";
import { Loading } from "components/utils/Loading";
import { useNavigateTo } from "hooks/useNavigateTo";
import { Go3ToolboxRoutes } from "const/Go3ToolboxRoutes";
import { PaginationSizes } from "const/pagination";
import { formatPrice } from "utils/formatters";
import { useTranslation } from "react-i18next";

const LettingsListRowMobile: FC<{ letting: LettingsListRowFragment; onClick: () => void }> = ({ letting, onClick }) => {
    const { t } = useTranslation("backend");
    const {
        utag,
        area_entity,
        streetname1,
        streetnumber,
        district_entity,
        listingowner_entity,
        propertystatus,
        netrentpmres,
        propertytype,
    } = letting;

    return (
        <>
            <Box
                sx={{
                    minHeight: "4ex",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: 1,
                    paddingRight: 1,
                    fontSize: "0.85rem",
                    paddingY: 1,
                }}
                onClick={onClick}
            >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="body1" component="div">
                        {streetname1} {streetnumber}
                        <br />
                        {area_entity?.areaname && (
                            <Typography variant="caption">
                                {district_entity?.districtname} {area_entity.areaname}
                            </Typography>
                        )}
                    </Typography>

                    <Typography variant="caption">
                        {t(`backend:sales_propertytype.${propertytype}`) ?? ""}
                        <br />
                        {listingowner_entity?.firstname} {listingowner_entity?.surname}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", color: "#757575" }}>
                    <Typography variant="caption">{formatPrice(netrentpmres)}</Typography>
                    <Typography variant="caption">{t(`backend:sales_propertystatus.${propertystatus}`) ?? ""}</Typography>
                    <Typography variant="caption">{utag}</Typography>
                </Box>
            </Box>
            <Divider />
        </>
    );
};

export const LettingsListMobileTable: FC<TableProps<LettingsListRowFragment>> = ({
    data,
    loading,
    page,
    setPage,
    pageSize,
    setPageSize,
}) => {
    const navigate = useNavigateTo();
    const { t } = useTranslation();

    if (loading) return <Loading />;

    return (
        <>
            {data?.map((letting) => (
                <LettingsListRowMobile
                    key={letting.key}
                    letting={letting}
                    onClick={() => navigate(Go3ToolboxRoutes.App.Lettings.DetailsPage.fullPath, { utag: `${letting.utag}` })}
                />
            ))}
            <TablePagination
                rowsPerPageOptions={PaginationSizes}
                component="div"
                count={-1}
                rowsPerPage={pageSize}
                page={page}
                labelRowsPerPage={t("translation:rows_per_page")}
                labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
                onPageChange={(_, $page) => {
                    setPage($page);
                }}
                onRowsPerPageChange={(event) => {
                    const $pageSize = parseInt(event.target.value);
                    setPageSize($pageSize);
                }}
            />
        </>
    );
};
