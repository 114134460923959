/* global Maybe */
import { generatePath, useNavigate } from "react-router";
import { isEmpty, omitBy, isNil } from "lodash-es";

export const useNavigateTo = () => {
    const navigate = useNavigate();

    return (path: string, params?: Record<string, Maybe<string | number>>): void => {
        if (!params || isEmpty(omitBy(params, isNil))) {
            navigate(path);
        } else {
            const url = generatePath(path, params as Record<string, string>);
            navigate(url);
        }
    };
};
