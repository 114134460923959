/* global Entity */
import React, { FC } from "react";
import { ActivitiesListRowFragment } from "lib/api";
import { selectActivitiesListSlice, setPage, setPageSize } from "store/slices/activitiesList.slice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useDesktopView } from "utils/useDesktopView";
import { DesktopListViewLayout } from "components/containers/DesktopListViewLayout";
import { ActivitiesListTable } from "./ActivitiesListTable";
import { ActivitiesListMobileTable } from "./ActivitiesListMobileTable";

interface ActivitiesListContentProps {
    data: Array<ActivitiesListRowFragment>;
    loading: boolean;
    entity: Entity;
}

export const ActivitiesListContent: FC<ActivitiesListContentProps> = ({ data, loading, entity }) => {
    const dispatch = useAppDispatch();
    const showDesktopView = useDesktopView();
    const {
        pagination: { page, pageSize },
    } = useAppSelector(selectActivitiesListSlice);

    const handleChangePage = (newPage: number) => {
        dispatch(setPage(newPage));
    };

    const handleChangePageSize = (newPageSize: number) => {
        dispatch(setPageSize(newPageSize));
    };
    return showDesktopView ? (
        <DesktopListViewLayout>
            <ActivitiesListTable
                data={data || []}
                loading={loading}
                page={page}
                setPage={handleChangePage}
                pageSize={pageSize}
                setPageSize={handleChangePageSize}
                entity={entity}
            />
        </DesktopListViewLayout>
    ) : (
        <ActivitiesListMobileTable
            data={data || []}
            loading={loading}
            page={page}
            setPage={handleChangePage}
            pageSize={pageSize}
            setPageSize={handleChangePageSize}
            entity={entity}
        />
    );
};
