import { FC, useState } from "react";
import { useAppSelector } from "hooks/redux";
import { PropreslettingQueryOrder, useLettingsListQuery, OrderBy } from "lib/api";
import { selectLettingsListSlice } from "store/slices/lettingsList.slice";
import { LettingsListContent } from "./LettingsListContent";
import { LettingsListHeader } from "./LettingsListHeader";

export const LettingsListPage: FC = () => {
    const {
        filters,
        pagination: { page, pageSize },
    } = useAppSelector(selectLettingsListSlice);
    const [orderBy] = useState<PropreslettingQueryOrder>({
        utag: OrderBy.Asc,
    });
    const { data, loading } = useLettingsListQuery({
        variables: {
            page: page + 1,
            pageSize,
            orderBy,
            where: filters,
        },
    });
    return (
        <>
            <LettingsListHeader />
            <LettingsListContent data={data?.Propreslettings.rows || []} loading={loading} />
        </>
    );
};
