import React, { FC } from "react";
import { Divider, Paper, Typography } from "@mui/material";

export const DetailViewSection: FC<{ label: string }> = ({ children, label }) => (
    <Paper sx={{ padding: 2, height: "100%" }}>
        <Typography variant="h5">{label}</Typography>
        <Divider sx={{ marginBottom: 2 }} />
        {children}
    </Paper>
);
