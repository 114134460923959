import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { setNotification } from "store/slices/notifications.slice";
import { AppDispatch, store } from "store";
import { logout } from "store/slices/auth.slice";
import { EnvironmentConfig } from "./environments";

export const IsPublicOperationContextKey = "isPublicOperation";
export const getPublicOperationContext = () => ({
    [IsPublicOperationContextKey]: true,
});

const httpLink = (env: EnvironmentConfig) => new HttpLink({ uri: env.apiUrl });

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => {
        const operationContext = operation.getContext();
        const { [IsPublicOperationContextKey]: isPublicOperation } = operationContext;

        if (isPublicOperation) {
            return {
                ...operationContext,
                headers,
            };
        }

        const { userCredentials, selectedAgentId } = store.getState().auth || {};
        const authHeaders =
            userCredentials?.token && selectedAgentId
                ? {
                      Authorization: `Bearer ${userCredentials.token}`,
                      AgentID: `${selectedAgentId}`,
                  }
                : {};

        return {
            headers: {
                ...headers,
                ...authHeaders,
            },
        };
    });

    return forward(operation);
});

const errorLink = (dispatch: AppDispatch) =>
    onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message }) => {
                dispatch(setNotification({ type: "error", message }));
            });
        }

        if (networkError) {
            dispatch(logout());
            const { message } = networkError;
            dispatch(setNotification({ type: "error", message }));
        }
    });

let apolloClient: ApolloClient<NormalizedCacheObject>;

export const configureApolloClient = (env: EnvironmentConfig) => {
    apolloClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: from([errorLink(store.dispatch), authMiddleware, httpLink(env)]),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "network-only",
                notifyOnNetworkStatusChange: true,
            },
        },
    });
    return apolloClient;
};

export const getApolloClient = () => apolloClient;
