/* global Maybe, Entity, ActivityType, ValueOf */
import { format } from "date-fns";
import i18next from "i18next";
import { capitalize, isNil } from "lodash-es";
import { Go3ToolboxRoutes } from "const/Go3ToolboxRoutes";
import {
    AppointmentDetailsFragment,
    ContactDetailsFragment,
    DocumentDetailsFragment,
    EmailDetailsFragment,
    LettingDetailsFragment,
    SaleDetailsFragment,
    TaskDetailsFragment,
    ViewingDetailsFragment,
} from "lib/api";

export const formatDate = (date: string) => (date ? format(new Date(date), i18next.t("translation:dateFormat")) : "-");
export const formatTime = (date: Date) => (date ? format(date, i18next.t("translation:time")) : "-");
export const formatDateTime = (date: Date) => (date ? format(date, i18next.t("translation:dateTime")) : "-");

export const formatName = (firstname?: string, surname?: string): string => {
    if (!firstname && !surname) {
        return "-";
    }

    return `${firstname}${firstname && surname ? " " : ""}${surname}`;
};

export const formatPrice = (price: Maybe<number | string>) => {
    if (isNil(price)) {
        return "";
    }

    if (typeof price === "string") {
        if (!parseFloat(price)) {
            return "";
        }
        return new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
            maximumFractionDigits: 2,
        }).format(parseFloat(price));
    }

    return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: 2,
    }).format(price);
};

export const formatPropertiesValue = (key: keyof SaleDetailsFragment, value: ValueOf<SaleDetailsFragment>) => {
    switch (true) {
        case key.includes("price"):
            return formatPrice(value);
        case key === "propertytype":
            return i18next.t(`backend:propertytype.${value}`);
        case key === "propertystatus":
            return i18next.t(`backend:sales_propertystatus.${value}`);
        case key === "envsource":
            return i18next.t(`backend:envsource.${value}`);
        case key === "mandateto":
            return formatDate(value as string);
        case key === "typeofmandate":
            return i18next.t(`backend:letting_typeofmandate.jointhandling.${value}`);
        default:
            return value;
    }
};

export const formatContactsValue = (key: keyof ContactDetailsFragment, value: ValueOf<ContactDetailsFragment>) => {
    switch (true) {
        case key === "favouredwayofcontact":
            return i18next.t(`backend:${value}`);
        case key.includes("date"):
        case key.includes("birthday"):
        case key === "lastaction":
            return formatDate(value as string);
        case key === "status":
        case key === "secondstatus":
        case key === "thirdstatus":
            return value && i18next.t(`backend:contactstatus.${value}`);
        case key === "contactcategory":
        case key === "secondcategory":
        case key === "thirdcategory":
            return value && i18next.t(`backend:contactcategory.${value}`);
        default:
            return value;
    }
};

export const formatLettingsValue = (key: keyof LettingDetailsFragment, value: ValueOf<LettingDetailsFragment>) => {
    switch (true) {
        case key.includes("price"):
        case key === "netrentpmres":
        case key === "netrentsurfsystpmres":
            return formatPrice(value);
        case key === "propertytype":
            return i18next.t(`backend:propertytype.${value}`);
        case key === "propertystatus":
            return i18next.t(`backend:sales_propertystatus.${value}`);
        case key === "envsource":
            return i18next.t(`backend:envsource.${value}`);
        case key === "mandateto":
            return formatDate(value as string);
        case key === "typeofmandate":
            return i18next.t(`backend:letting_typeofmandate.jointhandling.${value}`);
        case key === "garden":
        case key === "balcony":
            return value ? "Yes" : "No";
        default:
            return value;
    }
};

export const formatTasksValue = (key: keyof TaskDetailsFragment, value: ValueOf<TaskDetailsFragment>) => {
    switch (true) {
        case key === "tasklinkcontacts_entities":
            return Array.isArray(value) && value.map((contact) => `${contact.firstname} ${contact.surname}`).join(";");
        case key === "tasklinkpropressales_entities":
        case key === "tasklinkpropreslettings_entities":
            return Array.isArray(value) && value.map((property) => `${property.streetname1} ${property.streetnumber}`).join(";");
        case key === "taskduedate":
        case key === "unifieddate":
            return formatDate(value);
        default:
            return value;
    }
};

export const formatEmailsValue = (key: keyof EmailDetailsFragment, value: ValueOf<EmailDetailsFragment>) => {
    switch (true) {
        case key === "emaillinkcontacts_entities":
            return Array.isArray(value) && value.map((contact) => `${contact.firstname} ${contact.surname}`).join(";");
        case key === "emaillinkpropressales_entities":
        case key === "emaillinkpropreslettings_entities":
            return Array.isArray(value) && value.map((property) => `${property.streetname1} ${property.streetnumber}`).join(";");
        case key === "unifieddate":
            return formatDate(value);
        case key === "emailattachments":
            return Array.isArray(value) && value.map((email) => `<a href=${email.url}>${email.url}</a>`).join("\n");
        default:
            return value;
    }
};

export const formatAppointmentsValue = (key: keyof AppointmentDetailsFragment, value: ValueOf<AppointmentDetailsFragment>) => {
    switch (true) {
        case key === "unifieddate":
            return formatDate(value);
        case key === "apmtlinkcontacts_entities":
            return Array.isArray(value) && value.map((contact) => `${contact.firstname} ${contact.surname}`).join(";");
        case key === "apmtlinkpropressales_entities":
        case key === "apmtlinkpropreslettings_entities":
            return Array.isArray(value) && value.map((property) => `${property.streetname1} ${property.streetnumber}`).join(";");
        case key === "apmtattachments":
            return Array.isArray(value) && value.map((attachment) => attachment.url).join(";");
        default:
            return value;
    }
};

export const formatDocumentValue = (key: keyof DocumentDetailsFragment, value: ValueOf<DocumentDetailsFragment>) => {
    switch (true) {
        case key === "unifieddate":
            return formatDate(value);
        case key === "documentlinkcontacts_entities":
            return Array.isArray(value) && value.map((contact) => `${contact.firstname} ${contact.surname}`).join(";");
        case key === "documentlinkpropressales_entities":
        case key === "documentlinkpropreslettings_entities":
            return Array.isArray(value) && value.map((property) => `${property.streetname1} ${property.streetnumber}`).join(";");
        case key === "documentsavedfile":
            return Array.isArray(value) && value.map((attachment) => attachment.url).join(";");
        default:
            return value;
    }
};
export const formatViewingsValue = (key: keyof ViewingDetailsFragment, value: ValueOf<ViewingDetailsFragment>) => {
    switch (true) {
        case key === "unifieddate":
            return formatDate(value);
        default:
            return value;
    }
};

export const formatEntity = (entity: Entity) => {
    if (entity === "Contacts") return "contact";
    if (entity === "Sales") return "propressale";
    if (entity === "Lettings") return "propresletting";

    return "";
};

export const getActivityPath = (activityType: ActivityType, entity: Entity): string => {
    const CapitalizedActivityType = capitalize(activityType) as "Appointment" | "Email" | "Task" | "Document";
    return Go3ToolboxRoutes.App[entity].DetailsPage.Activities.DetailsPage[CapitalizedActivityType].fullPath;
};
