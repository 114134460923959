// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from "redux";

import authReducer, { AUTH_SLICE_NAME } from "./auth.slice";
import contactsListReducer, { CONTACT_LIST_SLICE_NAME } from "./contactsList.slice";
import notificationsReducer, { NOTIFICATIONS_SLICE_NAME } from "./notifications.slice";
import salesListReducer, { SALES_LIST_SLICE_NAME } from "./salesList.slice";
import lettingsReducer, { LETTINGS_LIST_SLICE_NAME } from "./lettingsList.slice";
import activitiesReducer, { ACTIVITIES_LIST_SLICE_NAME } from "./activitiesList.slice";
import searchDemandsReducer, { SEARCH_DEMANDS_LIST_SLICE_NAME } from "./searchDemandsList.slice";

const rootReducer = combineReducers({
    [AUTH_SLICE_NAME]: authReducer,
    [CONTACT_LIST_SLICE_NAME]: contactsListReducer,
    [NOTIFICATIONS_SLICE_NAME]: notificationsReducer,
    [SALES_LIST_SLICE_NAME]: salesListReducer,
    [LETTINGS_LIST_SLICE_NAME]: lettingsReducer,
    [ACTIVITIES_LIST_SLICE_NAME]: activitiesReducer,
    [SEARCH_DEMANDS_LIST_SLICE_NAME]: searchDemandsReducer,
});

export default rootReducer;
