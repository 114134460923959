import { FC, useState } from "react";
import { OrderBy, PropressaleQueryOrder, useSalesListQuery } from "lib/api";
import { useAppSelector } from "hooks/redux";
import { selectSalesListSlice } from "store/slices/salesList.slice";
import { SalesListContent } from "./SalesListContent";
import { SalesListHeader } from "./SalesListHeader";

export const SalesListPage: FC = () => {
    const {
        filters,
        pagination: { page, pageSize },
    } = useAppSelector(selectSalesListSlice);
    const [orderBy] = useState<PropressaleQueryOrder>({
        utag: OrderBy.Asc,
    });
    const { data, loading } = useSalesListQuery({
        variables: {
            page: page + 1,
            rpp: pageSize,
            order: orderBy,
            where: filters,
        },
    });
    return (
        <>
            <SalesListHeader />
            <SalesListContent data={data?.Propressales.rows || []} loading={loading} />
        </>
    );
};
