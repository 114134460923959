import React, { useEffect } from "react";
import GoogleLogin from "react-google-login";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Go3ToolboxRoutes } from "const/Go3ToolboxRoutes";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { logout, selectUserCredentials, signinGoogle } from "store/slices/auth.slice";
import { setNotification } from "store/slices/notifications.slice";

export const LoginView = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const userCredentials = useAppSelector(selectUserCredentials);
    const navigate = useNavigate();

    useEffect(() => {
        if (!userCredentials) {
            return;
        }
        if (userCredentials.login?.agents) {
            navigate(Go3ToolboxRoutes.App.Contacts.ListPage.fullPath);
            dispatch(setNotification({ type: "success", message: t("notification.auth.logged_in") }));
        } else {
            dispatch(setNotification({ type: "error", message: t("error.auth.no_agent_error") }));
        }
    }, [navigate, userCredentials]);

    const onSuccess = (rsp: any) => dispatch(signinGoogle(rsp));

    const onFailure = (error: any) => {
        dispatch(logout());
        dispatch(setNotification({ type: "error", message: t(`error.auth.google_${error.error}`) }));
    };

    return (
        <Card>
            <CardContent>
                <Typography component="h1" variant="h5">
                    {t("appTitle")}
                </Typography>

                <Box display="flex" justifyContent="center" paddingTop={3}>
                    <GoogleLogin
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        clientId={process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID || ""}
                        isSignedIn={Boolean(userCredentials)}
                        prompt="consent"
                    >
                        {t("loginButton")}
                    </GoogleLogin>
                </Box>
            </CardContent>
        </Card>
    );
};
