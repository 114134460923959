export const Go3ToolboxRoutes = {
    path: "",
    fullPath: "/",
    App: {
        path: "app",
        fullPath: "/app",
        Contacts: {
            path: "contacts",
            fullPath: "/app/contacts",
            ListPage: {
                path: "",
                fullPath: "/app/contacts",
            },
            DetailsPage: {
                path: ":utag",
                fullPath: "/app/contacts/:utag",
                Activities: {
                    path: "activities",
                    fullPath: "/app/contacts/:utag/activities",
                    ListPage: {
                        path: "",
                        fullPath: "/app/contacts/:utag/activities",
                    },
                    DetailsPage: {
                        path: "",
                        fullPath: "/app/contacts/:utag/activities",
                        Task: {
                            path: "task/:taskkey",
                            fullPath: "/app/contacts/:utag/activities/task/:taskkey",
                        },
                        Appointment: {
                            path: "appointment/:appointmentkey",
                            fullPath: "/app/contacts/:utag/activities/appointment/:appointmentkey",
                        },
                        Email: {
                            path: "email/:emailkey",
                            fullPath: "/app/contacts/:utag/activities/email/:emailkey",
                        },
                        Document: {
                            path: "document/:documentkey",
                            fullPath: "/app/contacts/:utag/activities/document/:documentkey",
                        },
                        Viewing: {
                            path: "viewing/:viewingkey",
                            fullPath: "/app/contacts/:utag/activities/viewing/:viewingkey",
                        },
                    },
                },
                Demands: {
                    path: "/demands",
                    fullPath: "/app/contacts/:utag/demands",
                },
            },
        },
        Sales: {
            path: "sales",
            fullPath: "/app/sales",
            ListPage: {
                path: "",
                fullPath: "/app/sales",
            },
            DetailsPage: {
                path: ":utag",
                fullPath: "/app/sales/:utag",
                Activities: {
                    path: "/activities",
                    fullPath: "/app/sales/:utag/activities",
                    ListPage: {
                        path: "",
                        fullPath: "/app/sales/:utag/activities",
                    },
                    DetailsPage: {
                        path: "",
                        fullPath: "/app/sales/:utag/activities/:key",
                        Task: {
                            path: "task/:taskkey",
                            fullPath: "/app/sales/:utag/activities/task/:taskkey",
                        },
                        Appointment: {
                            path: "appointment/:appointmentkey",
                            fullPath: "/app/sales/:utag/activities/appointment/:appointmentkey",
                        },
                        Email: {
                            path: "email/:emailkey",
                            fullPath: "/app/sales/:utag/activities/email/:emailkey",
                        },
                        Document: {
                            path: "document/:documentkey",
                            fullPath: "/app/sales/:utag/activities/document/:documentkey",
                        },
                        Viewing: {
                            path: "viewing/:viewingkey",
                            fullPath: "/app/sales/:utag/activities/viewing/:viewingkey",
                        },
                    },
                },
            },
        },
        Lettings: {
            path: "lettings",
            fullPath: "/app/lettings",
            ListPage: {
                path: "",
                fullPath: "/app/lettings",
            },
            DetailsPage: {
                path: ":utag",
                fullPath: "/app/lettings/:utag",
                Activities: {
                    path: "activities",
                    fullPath: "/app/lettings/:utag/activities",
                    ListPage: {
                        path: "",
                        fullPath: "/app/lettings/:utag/activities",
                    },
                    DetailsPage: {
                        path: "",
                        fullPath: "/app/lettings/:utag/activities/:key",
                        Task: {
                            path: "task/:taskkey",
                            fullPath: "/app/lettings/:utag/activities/task/:taskkey",
                        },
                        Appointment: {
                            path: "appointment/:appointmentkey",
                            fullPath: "/app/lettings/:utag/activities/appointment/:appointmentkey",
                        },
                        Email: {
                            path: "email/:emailkey",
                            fullPath: "/app/lettings/:utag/activities/email/:emailkey",
                        },
                        Document: {
                            path: "document/:documentkey",
                            fullPath: "/app/lettings/:utag/activities/document/:documentkey",
                        },
                        Viewing: {
                            path: "viewing/:viewingkey",
                            fullPath: "/app/lettings/:utag/activities/viewing/:viewingkey",
                        },
                    },
                },
            },
        },
    },
    Login: {
        path: "login",
        fullPath: "/login",
    },
    NotFound: {
        path: "404",
        fullPath: "/404",
    },
};
