import { FC } from "react";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Portals from "const/Portals";

const Navbar: FC<{ onMobileNavOpen: () => void }> = ({ onMobileNavOpen, ...rest }) => (
    <Box
        sx={{
            display: { md: "block", lg: "none" },
        }}
    >
        <AppBar elevation={1} {...rest}>
            <Toolbar>
                <Box sx={{ marginLeft: 2, marginRight: 2 }}>
                    <IconButton color="inherit" onClick={onMobileNavOpen}>
                        <MenuIcon />
                    </IconButton>
                </Box>

                <Box id={Portals.Navbar} />

                <Box sx={{ flexGrow: 2 }} />
            </Toolbar>
        </AppBar>
    </Box>
);

export default Navbar;
