/* eslint-disable react/no-danger */
/* global Maybe */
import { useTranslation } from "react-i18next";
import { get } from "lodash-es";
import { DetailViewField } from "./DetailViewField";
import { DetailViewSection } from "./DetailViewSection";

interface DetailViewProps<T> {
    fields: Array<string>;
    label: string;
    data: T;
    translations?: Record<string, string>;
    formatter: (key: any, value: any) => Maybe<string | number>;
}

const htmlConvertableKeys = ["emailbody", "emailattachments"];

export const DetailViewContainer = <Entity extends Record<string, any>>({
    fields,
    label,
    data,
    translations = {},
    formatter,
}: DetailViewProps<Entity>) => {
    const { t } = useTranslation("backend");
    const records = fields.map((field) => {
        const translation = translations?.[field];
        let fieldName;

        if (field.split(".").length > 1) {
            [, fieldName] = field.split(".");
        } else {
            fieldName = field;
        }

        const generateValue = (key: string): Maybe<string | number> =>
            key.split(" ").length > 1
                ? key
                      .split(" ")
                      .reduce((acc, cur) => `${acc} ${formatter(cur, get(data, cur))}`.replace(/(undefined|null)/g, "").trim(), "")
                : formatter(field, get(data, key));

        return (
            <DetailViewField key={field as string} label={translation ? t(translation) : t(fieldName)}>
                {htmlConvertableKeys.includes(field) ? (
                    <div dangerouslySetInnerHTML={{ __html: generateValue(field) as string }} />
                ) : (
                    generateValue(field)
                )}
            </DetailViewField>
        );
    });

    return <DetailViewSection label={t(label)}>{records}</DetailViewSection>;
};
