import { PropsWithChildren } from "react";
import { MenuItem, Select, SelectProps as _SelectProps, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface EnumSelectProps<TEnum> {
    value: TEnum | undefined;
    name: string;
    onChange: (name: string, value: TEnum) => void;
    options: Record<string, string>;

    id?: string;
    enableNoneSelection?: boolean;
    emptyLabel?: string;
    translationPrefix: string;
    SelectProps?: Partial<_SelectProps<TEnum | string>>;
}

export const EnumSelect = <TEnum,>({
    value,
    name,
    onChange,
    options,

    id,
    enableNoneSelection,
    emptyLabel,
    translationPrefix,
    SelectProps,
}: PropsWithChildren<EnumSelectProps<TEnum>>) => {
    const { t } = useTranslation("backend");
    const domId = id || emptyLabel;

    // @ts-ignore start
    return (
        <Select<TEnum | string>
            id={`enum-select-${domId}`}
            displayEmpty={enableNoneSelection}
            fullWidth
            autoWidth
            placeholder={emptyLabel}
            sx={{ minWidth: "100%" }}
            value={value || ""}
            onChange={(evt) => onChange(name, evt.target.value as TEnum)}
            {...SelectProps}
        >
            {enableNoneSelection && (
                <MenuItem value="">
                    <Box sx={{ color: (theme) => theme.palette.grey.A400 }}>{emptyLabel}</Box>
                </MenuItem>
            )}
            {Object.keys(options).map((optionKey) => (
                <MenuItem key={options[optionKey]} value={options[optionKey]}>
                    {t(`${translationPrefix}${options[optionKey]}`)}
                </MenuItem>
            ))}
        </Select>
    );
};
