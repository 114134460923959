import { FC } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { LettingsListRowFragment } from "lib/api";
import { selectLettingsListSlice, setPage, setPageSize } from "store/slices/lettingsList.slice";
import { useDesktopView } from "utils/useDesktopView";
import { DesktopListViewLayout } from "components/containers/DesktopListViewLayout";
import { LettingsListTable } from "./LettingsListTable";
import { LettingsListMobileTable } from "./LettingsListMobileTable";

interface Props {
    data: Array<LettingsListRowFragment>;
    loading: boolean;
}

export const LettingsListContent: FC<Props> = ({ data, loading }) => {
    const dispatch = useAppDispatch();
    const showDesktopView = useDesktopView();
    const {
        pagination: { page, pageSize },
    } = useAppSelector(selectLettingsListSlice);

    const handleChangePage = (newPage: number) => {
        dispatch(setPage(newPage));
    };

    const handleChangePageSize = (newPageSize: number) => {
        dispatch(setPageSize(newPageSize));
    };
    return showDesktopView ? (
        <DesktopListViewLayout>
            <LettingsListTable
                data={data || []}
                loading={loading}
                page={page}
                setPage={handleChangePage}
                pageSize={pageSize}
                setPageSize={handleChangePageSize}
            />
        </DesktopListViewLayout>
    ) : (
        <LettingsListMobileTable
            data={data || []}
            loading={loading}
            page={page}
            setPage={handleChangePage}
            pageSize={pageSize}
            setPageSize={handleChangePageSize}
        />
    );
};
