import React from "react";
import { useParams } from "react-router";
import { Grid } from "@mui/material";
import { DetailViewContainer } from "components/containers/DetailViewContainer";
import { EmailDetailsFragment, useEmailsDetailQuery } from "lib/api";
import { Loading } from "components/utils/Loading";
import NotFoundPage from "components/layout/NotFoundPage";
import { formatEmailsValue } from "utils/formatters";

export const EmailPage = () => {
    const { emailkey } = useParams();
    const { data, loading } = useEmailsDetailQuery({ skip: !emailkey, variables: { key: +emailkey } });

    if (loading) return <Loading />;

    if (!emailkey || !data?.Email) return <NotFoundPage />;

    const { Email } = data;
    return (
        <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <DetailViewContainer<EmailDetailsFragment>
                    data={Email}
                    fields={[
                        "emailtype",
                        "emailfrom",
                        "emailto",
                        "emailsubject",
                        "emaillinkcontacts_entities",
                        "emaillinkpropressales_entities",
                        "emaillinkpropreslettings_entities",
                        "unifieddate",
                        "emailbody",
                        "emailattachments",
                    ]}
                    label="translation:email"
                    translations={{
                        emaillinkcontacts_entities: "emaillinkcontacts",
                        emaillinkpropressales_entities: "emaillinkpropressales",
                        emaillinkpropreslettings_entities: "emaillinkpropreslettings",
                    }}
                    formatter={formatEmailsValue}
                />
            </Grid>
        </Grid>
    );
};
