/* global TableProps */
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Loading } from "components/utils/Loading";
import { ContactsListRowFragment } from "lib/api";
import { Go3ToolboxRoutes } from "const/Go3ToolboxRoutes";
import { PaginationSizes } from "const/pagination";
import { tContactcategory, tContactstatus } from "utils/translateBackendEnums";
import { useNavigateTo } from "hooks/useNavigateTo";
import { formatDate, formatName } from "utils/formatters";

export const ContactsListTable: FC<TableProps<ContactsListRowFragment>> = ({ data, loading, page, setPage, pageSize, setPageSize }) => {
    const { t } = useTranslation("backend");
    const navigateTo = useNavigateTo();

    const columns: GridColDef[] = [
        { field: "surname", headerName: t("surname"), width: 120 },
        { field: "firstname", headerName: t("firstname"), width: 160 },
        { field: "contactcategory", headerName: t("contactcategory"), width: 120 },
        { field: "mobileoffice", headerName: t("mobileoffice"), width: 160 },
        { field: "email1", headerName: t("email1"), flex: 0.3 },
        { field: "createdfor", headerName: t("agent"), width: 160 },
        { field: "status", headerName: t("status"), width: 160 },
        { field: "lastaction", headerName: t("lastaction"), flex: 0.3 },
    ];

    const rows =
        data?.map((contact: ContactsListRowFragment) => {
            const { key, contactcategory, status, lastaction, owner_agent_entity } = contact;
            return {
                ...contact,
                id: key,
                contactcategory: tContactcategory(contactcategory),
                createdfor: formatName(owner_agent_entity?.firstname, owner_agent_entity?.surname),
                status: tContactstatus(status),
                lastaction: formatDate(lastaction),
            };
        }) || [];

    if (loading) return <Loading />;

    return (
        <DataGrid
            rows={rows}
            density="compact"
            columns={columns}
            onRowClick={({ row }) => navigateTo(Go3ToolboxRoutes.App.Contacts.DetailsPage.fullPath, { utag: row.utag })}
            autoHeight
            pagination
            rowCount={(page + 1) * pageSize + 1}
            paginationMode="server"
            rowsPerPageOptions={PaginationSizes}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            componentsProps={{
                pagination: {
                    labelRowsPerPage: t("translation:rows_per_page"),
                },
            }}
        />
    );
};
