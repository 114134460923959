import React from "react";
import { useParams } from "react-router";
import { Grid } from "@mui/material";
import { DetailViewContainer } from "components/containers/DetailViewContainer";
import { AppointmentDetailsFragment, useAppointmentsDetailQuery } from "lib/api";
import { Loading } from "components/utils/Loading";
import NotFoundPage from "components/layout/NotFoundPage";
import { formatAppointmentsValue } from "utils/formatters";

export const AppointmentPage = () => {
    const { appointmentkey } = useParams();
    const { data, loading } = useAppointmentsDetailQuery({ skip: !appointmentkey, variables: { key: +appointmentkey } });

    if (loading) return <Loading />;

    if (!appointmentkey || !data?.Appointment) return <NotFoundPage />;

    const { Appointment } = data;
    return (
        <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <DetailViewContainer<AppointmentDetailsFragment>
                    data={Appointment}
                    fields={[
                        "apmtcategory",
                        "apmtstatus",
                        "laststatuschangeddate",
                        "apmtactiontype",
                        "apmtsubject",
                        "apmtdate",
                        "apmtstarttime",
                        "apmtendtime",
                        "apmtcomments",
                        "apmtlinkcontacts_entities",
                        "apmtlinkpropressales_entities",
                        "apmtlinkpropreslettings_entities",
                        "unifieddate",
                        "apmtattachments",
                    ]}
                    label="translation:appointment"
                    translations={{
                        apmtlinkcontacts_entities: "apmtlinkcontacts",
                        apmtlinkpropressales_entities: "apmtlinkpropressales",
                        apmtlinkpropreslettings_entities: "apmtlinkpropreslettings",
                    }}
                    formatter={formatAppointmentsValue}
                />
            </Grid>
        </Grid>
    );
};
