import { FC } from "react";
import { ContactDetailsFragment, useContactsDetailQuery } from "lib/api";
import { Grid } from "@mui/material/index";
import { useParams } from "react-router";
import NotFoundPage from "components/layout/NotFoundPage";
import { Loading } from "components/utils/Loading";
import { DetailViewContainer } from "components/containers/DetailViewContainer";
import { DetailPageNavigation } from "components/containers/DetailPageNavigation";
import { formatContactsValue } from "utils/formatters";
import { Go3ToolboxRoutes } from "const/Go3ToolboxRoutes";
import { useTranslation } from "react-i18next";

export const ContactsDetailPage: FC = () => {
    const { utag } = useParams();
    const { t } = useTranslation("backend");

    const { data, loading } = useContactsDetailQuery({
        skip: !utag,
        variables: {
            utag,
        },
    });

    if (loading) return <Loading />;

    if (!utag || !data?.contact) return <NotFoundPage />;

    const { contact } = data;
    return (
        <>
            <DetailPageNavigation
                links={[
                    { href: `${Go3ToolboxRoutes.App.Contacts.fullPath}/${utag}`, title: "Detail View" },
                    { href: `${Go3ToolboxRoutes.App.Contacts.fullPath}/${utag}/activities`, title: t("translation:activities") },
                    { href: `${Go3ToolboxRoutes.App.Contacts.fullPath}/${utag}/demands`, title: t("searchcriteria-plural") },
                ]}
            />
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <DetailViewContainer<ContactDetailsFragment>
                        data={contact}
                        fields={[
                            "company",
                            "department",
                            "title_value",
                            "newtitle",
                            "firstname",
                            "surname",
                            "birthday",
                            "firstnamepartner",
                            "surnamepartner",
                        ]}
                        translations={{ title_value: "title" }}
                        label="translation:name"
                        formatter={formatContactsValue}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <DetailViewContainer<ContactDetailsFragment>
                        data={contact}
                        fields={[
                            "phoneoffice1",
                            "mobileoffice",
                            "mobileprivate",
                            "telprivate1",
                            "fax1",
                            "email1",
                            "email2",
                            "favouredwayofcontact",
                        ]}
                        label="translation:contacts.communication"
                        formatter={formatContactsValue}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <DetailViewContainer<ContactDetailsFragment>
                        data={contact}
                        fields={["streetname1", "streetnumber1", "postalcode1", "city1", "country1"]}
                        label="translation:contacts.address"
                        formatter={formatContactsValue}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <DetailViewContainer<ContactDetailsFragment>
                        data={contact}
                        fields={[
                            "envsource",
                            "lastaction",
                            "idpassport",
                            "owner_agent_entity.firstname owner_agent_entity.surname",
                            "idpassportcomments",
                            "contactcategory",
                            "status",
                            "secondagent_entity.firstname secondagent_entity.surname",
                            "thirdagent_entity.firstname thirdagent_entity.surname",
                            "secondcategory",
                            "secondstatus",
                            "thirdcategory",
                            "thirdstatus",
                            "utag",
                        ]}
                        label="translation:internal"
                        translations={{
                            "owner_agent_entity.firstname owner_agent_entity.surname": "translation:agent",
                            "secondagent_entity.firstname secondagent_entity.surname": "secondagent",
                            "thirdagent_entity.firstname thirdagent_entity.surname": "thirdagent",
                            utag: "translation:utag",
                        }}
                        formatter={formatContactsValue}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <DetailViewContainer<ContactDetailsFragment>
                        data={contact}
                        fields={["commentsgenera"]}
                        label="commentsgeneral"
                        formatter={formatContactsValue}
                        translations={{ commentsgenera: "commentsgeneral" }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <DetailViewContainer<ContactDetailsFragment>
                        data={contact}
                        fields={[]}
                        label="translation:extra_information"
                        formatter={formatContactsValue}
                    />
                </Grid>
            </Grid>
        </>
    );
};
