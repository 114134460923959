/* global TableProps */
import { Box, Divider, IconButton, TablePagination, Typography } from "@mui/material";
import { Email as EmailIcon, Phone as PhoneIcon } from "@mui/icons-material";
import { FC, MouseEventHandler } from "react";

import { Loading } from "components/utils/Loading";
import { ContactsListRowFragment } from "lib/api";
import { Go3ToolboxRoutes } from "const/Go3ToolboxRoutes";
import { PaginationSizes } from "const/pagination";
import { tContactcategory } from "utils/translateBackendEnums";
import { useNavigateTo } from "hooks/useNavigateTo";
import { useTranslation } from "react-i18next";

const ContactListRowMobile: FC<{ contact: ContactsListRowFragment; onClick: () => void }> = ({ contact, onClick }) => {
    const handleEmailClick: MouseEventHandler = (evt) => {
        evt.stopPropagation();
        // @ts-ignore
        window.location.href = `mailto:${contact.email1}`;
    };

    const handlePhoneClick: MouseEventHandler = (evt) => {
        evt.stopPropagation();
        // @ts-ignore
        window.location.href = `tel:${contact.mobileoffice}`;
    };

    return (
        <>
            <Box
                sx={{
                    minHeight: "4ex",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: 1,
                    paddingRight: 1,
                    fontSize: "0.85rem",
                }}
                onClick={onClick}
            >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h6" component="div">
                        {contact.firstname} {contact.surname}
                        {contact.contactcategory && (
                            <Typography variant="caption" sx={{ marginLeft: 1 }}>
                                {tContactcategory(contact.contactcategory)}
                            </Typography>
                        )}
                    </Typography>

                    <Box sx={{ display: "flex", flexDirection: "column", color: "#757575" }}>
                        <div>{contact.email1}</div>
                        <div>{contact.mobileoffice}</div>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", color: "#757575" }}>
                    {contact.email1 && (
                        <IconButton color="secondary" onClick={handleEmailClick}>
                            <EmailIcon />
                        </IconButton>
                    )}
                    {contact.mobileoffice && (
                        <IconButton color="secondary" onClick={handlePhoneClick}>
                            <PhoneIcon />
                        </IconButton>
                    )}
                </Box>
            </Box>
            <Divider />
        </>
    );
};

export const ContactsListMobileTable: FC<TableProps<ContactsListRowFragment>> = ({
    data,
    loading,
    page,
    setPage,
    pageSize,
    setPageSize,
}) => {
    const navigate = useNavigateTo();
    const { t } = useTranslation();

    if (loading) return <Loading />;

    return (
        <>
            {data?.map((contact) => (
                <ContactListRowMobile
                    key={contact.key}
                    contact={contact}
                    onClick={() => navigate(Go3ToolboxRoutes.App.Contacts.DetailsPage.fullPath, { utag: `${contact.utag}` })}
                />
            ))}
            <TablePagination
                rowsPerPageOptions={PaginationSizes}
                component="div"
                count={-1}
                rowsPerPage={pageSize}
                page={page}
                labelRowsPerPage={t("translation:rows_per_page")}
                labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
                onPageChange={(_, $page) => {
                    setPage($page);
                }}
                onRowsPerPageChange={(event) => {
                    const $pageSize = parseInt(event.target.value);
                    setPageSize($pageSize);
                }}
            />
        </>
    );
};
