import React, { FC, Suspense } from "react";
import { configureApp } from "config";
import { Environment } from "config/environments";
import { Provider as ReduxStoreProvider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { store, persistor } from "store";
import { PersistGate } from "redux-persist/integration/react";
import { Loading } from "components/utils/Loading";
import { Notifications } from "components/utils/Notifications";
import { Routes } from "routes";

const config = configureApp(process.env.REACT_APP_ENV as Environment);

const App: FC = () => (
    <Suspense fallback={<Loading />}>
        <ReduxStoreProvider store={store}>
            <PersistGate loading={<Loading />} persistor={persistor}>
                <ApolloProvider client={config.apolloClient}>
                    <Notifications />
                    <Routes />
                </ApolloProvider>
            </PersistGate>
        </ReduxStoreProvider>
    </Suspense>
);

export default App;
