/* global TableProps */
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";

import { PropresLettingSearchCriteria, PropresSalesSearchCriteria, SearchDemandsRowFragment } from "lib/api";
import { Loading } from "components/utils/Loading";
import { PropertyCriteriaCell } from "./PropertyCriteriaCell";

export const SearchDemandsListTable: FC<TableProps<SearchDemandsRowFragment>> = ({ data, loading }) => {
    const { t } = useTranslation("backend");

    const fallbackHeight = 320;
    const [minHeight, setMinHeight] = useState<number>(fallbackHeight);

    const setRowHeight = (height: number) => {
        setMinHeight((prevState) => {
            if (height > prevState) return height;
            return prevState;
        });
    };

    const columns: GridColDef[] = [
        {
            field: "agent",
            headerName: t("agent"),
            renderCell: (params: GridCellParams) => {
                const {
                    value: { firstname, surname },
                } = params as { value: { firstname: string; surname: string } };

                return (
                    <div>
                        {firstname} {surname}
                    </div>
                );
            },
            flex: 0.1,
        },
        {
            field: "PropertySalesSearchDemands",
            headerName: t("propressales-plural"),
            renderCell: (params: GridCellParams) => {
                const {
                    value: { propertySales },
                } = params as { value: { propertySales: PropresSalesSearchCriteria } };

                return !isEmpty(propertySales) ? (
                    <PropertyCriteriaCell properties={{ ...propertySales, __typename: undefined }} setRowHeight={setRowHeight} />
                ) : (
                    <div />
                );
            },
            flex: 0.35,
        },
        {
            field: "PropertyLettingsSearchDemands",
            headerName: t("propresletting-plural"),
            renderCell: (params: GridCellParams) => {
                const {
                    value: { propertyLettings },
                } = params as { value: { propertyLettings: PropresLettingSearchCriteria } };

                return !isEmpty(propertyLettings) ? (
                    <PropertyCriteriaCell properties={{ ...propertyLettings, __typename: undefined }} setRowHeight={setRowHeight} />
                ) : (
                    <div />
                );
            },
            flex: 0.35,
        },
        {
            field: "demandabc",
            headerName: t("demandabc"),
            flex: 0.07,
        },
        {
            field: "demandstatus",
            headerName: t("demandstatus"),
            flex: 0.07,
        },
        {
            field: "demandexpiration",
            headerName: t("demandexpiration"),
            flex: 0.07,
        },
        {
            field: "demandcomments",
            headerName: t("demandcomments"),
            flex: 0.2,
        },
    ];

    const rows =
        data?.map((searchDemand: SearchDemandsRowFragment) => {
            const {
                key,
                createdfor,
                propresSalesSearchCriteria,
                propresLettingSearchCriteria,
                demandabc,
                demandstatus,
                demandexpiration,
                demandcomments,
            } = searchDemand;
            return {
                id: key,
                agent: {
                    firstname: createdfor?.firstname,
                    surname: createdfor?.surname,
                },
                PropertySalesSearchDemands: {
                    propertySales: propresSalesSearchCriteria,
                },
                PropertyLettingsSearchDemands: {
                    propertyLettings: propresLettingSearchCriteria,
                },
                demandabc,
                demandstatus,
                demandexpiration,
                demandcomments,
            };
        }) || [];

    if (loading) return <Loading />;
    return (
        <DataGrid
            rows={rows}
            columns={columns}
            rowHeight={minHeight}
            componentsProps={{
                pagination: {
                    labelRowsPerPage: t("translation:rows_per_page"),
                },
            }}
        />
    );
};
