import React, { FC, useMemo } from "react";
import Typography from "@mui/material/Typography/Typography";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useTranslation } from "react-i18next";
import { selectSalesListFilters, setFilter } from "store/slices/salesList.slice";
import { PropressaleQueryWhere, SalesPropertystatus, SalesPropertytype } from "lib/api";
import { SearchBar } from "components/utils/SearchBar";
import { EnumSelect } from "components/utils/EnumSelect";
import { BooleanSelect } from "components/utils/BooleanSelect";
import { enumToObject } from "utils/convertors";

const salesTypeOptions = enumToObject(SalesPropertytype);
const salesTypeStatuses = enumToObject(SalesPropertystatus);

export const SalesListHeader: FC = () => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector(selectSalesListFilters);

    const { t } = useTranslation("backend");

    const onChange = useMemo(
        () => (field: string, value: string) => dispatch(setFilter({ field: field as keyof PropressaleQueryWhere, value })),
        [dispatch],
    );

    const onSelect = useMemo(
        () =>
            <Option extends unknown>(field: string, value: Option) => {
                dispatch(setFilter({ field: field as keyof PropressaleQueryWhere, value }));
            },
        [dispatch],
    );

    return (
        <Box paddingTop={2} paddingBottom={2}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>{t("translation:search_and_filter")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="utag" onChange={onChange} placeholder={t("translation:utag")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="createdfor" onChange={onChange} placeholder={t("agent")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <EnumSelect<SalesPropertytype>
                                value={filters.propertytype as SalesPropertytype | undefined}
                                name="propertytype"
                                onChange={onSelect}
                                options={salesTypeOptions}
                                enableNoneSelection
                                emptyLabel={t("propertytype")}
                                translationPrefix="sales_propertytype."
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <EnumSelect<SalesPropertystatus>
                                value={filters.propertystatus as SalesPropertystatus | undefined}
                                name="propertystatus"
                                onChange={onSelect}
                                options={salesTypeStatuses}
                                enableNoneSelection
                                emptyLabel={t("propertystatus")}
                                translationPrefix="sales_propertystatus."
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="streetname1" onChange={onChange} placeholder={t("streetname1")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="streetnumber" onChange={onChange} placeholder={t("streetnumber")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="postcode" onChange={onChange} placeholder={t("addressres.postcode$city")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="city" onChange={onChange} placeholder={t("city")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="area" onChange={onChange} placeholder={t("area")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="district" onChange={onChange} placeholder={t("district")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="propertyname" onChange={onChange} placeholder={t("propertyname")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar
                                type="number"
                                name="surfacelivinarea.Min"
                                onChange={onChange}
                                placeholder={t("translation:surfacelivinarea.min")}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar
                                type="number"
                                name="surfacelivinarea.Max"
                                onChange={onChange}
                                placeholder={t("translation:surfacelivinarea.max")}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar
                                type="number"
                                name="plotsurface.Min"
                                onChange={onChange}
                                placeholder={t("translation:plotsurface.min")}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar
                                type="number"
                                name="plotsurface.Max"
                                onChange={onChange}
                                placeholder={t("translation:plotsurface.max")}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar type="number" name="numberbedrooms" onChange={onChange} placeholder={t("numberbedrooms")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="askingprice.Min" onChange={onChange} placeholder={t("translation:askingprice.min")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="askingprice.Max" onChange={onChange} placeholder={t("translation:askingprice.max")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <BooleanSelect
                                value={filters.sign}
                                name="sign"
                                onChange={onSelect}
                                enableNoneSelection
                                emptyLabel={t("sign")}
                                translationPrefix="nameless_423."
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <BooleanSelect
                                value={filters.keyonshop}
                                name="keyonshop"
                                onChange={onSelect}
                                enableNoneSelection
                                emptyLabel={t("keyonshop")}
                                translationPrefix="nameless_423."
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
