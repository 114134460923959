import { FC, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Avatar, Box, Button, Divider, Drawer, FormControl, FormLabel, List, MenuItem, Select, Typography } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import HouseIcon from "@mui/icons-material/House";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import { Go3ToolboxRoutes } from "const/Go3ToolboxRoutes";
import { GoogleLogout } from "react-google-login";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { logout, selectSelectedAgentId, selectUserCredentials, setSelectedAgentId } from "store/slices/auth.slice";
import { formatName } from "utils/formatters";
import { ReactComponent as Logo } from "assets/E&V_Logo_RGB_oReg.svg";
import { SidebarWidth } from "./const";
import SidebarItem from "./SidebarItem";
import { LanguageSelector } from "./LanguageSelector";

const items = [
    {
        href: Go3ToolboxRoutes.App.Contacts.ListPage.fullPath,
        icon: GroupIcon,
        title: "contact-plural",
    },
    {
        href: Go3ToolboxRoutes.App.Sales.ListPage.fullPath,
        icon: HouseIcon,
        title: "propressales-plural",
    },
    {
        href: Go3ToolboxRoutes.App.Lettings.ListPage.fullPath,
        icon: HomeIcon,
        title: "propresletting-plural",
    },
];

const Sidebar: FC<{ onMobileClose: () => void; openMobile: boolean }> = ({ onMobileClose = () => {}, openMobile = false }) => {
    const location = useLocation();
    const { t, i18n } = useTranslation("backend");
    const userCredentials = useAppSelector(selectUserCredentials);
    const selectedAgentId = useAppSelector(selectSelectedAgentId);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    p: 2,
                }}
            >
                <Logo />
                <Avatar
                    component={RouterLink}
                    sx={{
                        cursor: "pointer",
                        width: 32,
                        height: 32,
                        marginTop: 2,
                    }}
                    to={Go3ToolboxRoutes.App.Contacts.ListPage.fullPath}
                />
                <Typography color="textPrimary" variant="h6" sx={{ mb: 2 }}>
                    {formatName(userCredentials?.login?.loginfirstname, userCredentials?.login?.loginsurname)}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        minHeight: 96,
                        alignItems: "center",
                    }}
                >
                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">{t("agent")}</FormLabel>
                        <Select
                            id="agent-select"
                            labelId="agent-select"
                            value={selectedAgentId || ""}
                            onChange={(event) => {
                                dispatch(setSelectedAgentId(event.target.value as number));
                            }}
                            sx={{
                                minWidth: 196,
                            }}
                        >
                            {(userCredentials?.login?.agents || []).map((agent) => (
                                <MenuItem key={agent.key} value={agent.key}>
                                    <Box sx={{ fontWeight: 300 }}>
                                        <Typography>{formatName(agent.firstname, agent.surname)}</Typography>
                                        <Typography component="span" sx={{ display: "inline-block", color: "secondary.light" }}>
                                            {agent.department.departmentinternalname}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
                <List>
                    {items.map((item) => (
                        <SidebarItem href={item.href} key={item.title} title={t(item.title)} icon={item.icon} />
                    ))}
                </List>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box
                sx={{
                    backgroundColor: "background.default",
                    m: 2,
                    p: 2,
                }}
            >
                <LanguageSelector defaultLanguage={i18n.language} />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 1,
                        justifyContent: "center",
                        pt: 2,
                    }}
                >
                    <GoogleLogout
                        clientId={process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID || ""}
                        onLogoutSuccess={() => dispatch(logout())}
                        render={(props) => (
                            <Button variant="contained" endIcon={<LogoutIcon>Logout</LogoutIcon>} sx={{ px: 2 }} {...props}>
                                {t("translation:logout")}
                            </Button>
                        )}
                    />
                    <Button
                        variant="contained"
                        onClick={() => {
                            window.location.href = "mailto:go3toolboxfeedback@engelvoelkers.com";
                        }}
                        sx={{ px: 2 }}
                    >
                        {t("translation:send_feedback")}
                    </Button>
                </Box>
            </Box>
        </Box>
    );

    return (
        <>
            <Box
                sx={{
                    display: { md: "block", lg: "none" },
                }}
            >
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                    PaperProps={{
                        sx: {
                            width: SidebarWidth,
                        },
                    }}
                >
                    {content}
                </Drawer>
            </Box>
            <Box
                sx={{
                    display: { xs: "none", sm: "none", md: "none", lg: "block" },
                }}
            >
                <Drawer
                    anchor="left"
                    open
                    variant="persistent"
                    PaperProps={{
                        elevation: 2,
                        sx: {
                            width: SidebarWidth,
                            // top: NavbarHeight,
                            // height: `calc(100% - ${NavbarHeight}px)`
                        },
                    }}
                >
                    {content}
                </Drawer>
            </Box>
        </>
    );
};

export default Sidebar;
