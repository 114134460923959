import React, { FC } from "react";
import { SalesListRowFragment } from "lib/api";
import { selectSalesListSlice, setPage, setPageSize } from "store/slices/salesList.slice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useDesktopView } from "utils/useDesktopView";
import { DesktopListViewLayout } from "components/containers/DesktopListViewLayout";
import { SalesListTable } from "./salesListTable";
import SalesListMobileTable from "./SalesListMobileTable";

interface PropertiesListProps {
    data: Array<SalesListRowFragment>;
    loading: boolean;
}

export const SalesListContent: FC<PropertiesListProps> = ({ data, loading }) => {
    const dispatch = useAppDispatch();
    const showDesktopView = useDesktopView();
    const {
        pagination: { page, pageSize },
    } = useAppSelector(selectSalesListSlice);

    const handleChangePage = (newPage: number) => {
        dispatch(setPage(newPage));
    };

    const handleChangePageSize = (newPageSize: number) => {
        dispatch(setPageSize(newPageSize));
    };
    return showDesktopView ? (
        <DesktopListViewLayout>
            <SalesListTable
                data={data || []}
                loading={loading}
                page={page}
                setPage={handleChangePage}
                pageSize={pageSize}
                setPageSize={handleChangePageSize}
            />
        </DesktopListViewLayout>
    ) : (
        <SalesListMobileTable
            data={data || []}
            loading={loading}
            page={page}
            setPage={handleChangePage}
            pageSize={pageSize}
            setPageSize={handleChangePageSize}
        />
    );
};
