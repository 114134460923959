/* global TableProps */
import React, { FC } from "react";
import { isEmpty } from "lodash-es";
import { PropresLettingSearchCriteria, PropresSalesSearchCriteria, SearchDemandsRowFragment } from "lib/api";
import { Box, Divider, Typography } from "@mui/material";
import { Loading } from "components/utils/Loading";
import { PropertyCriteriaCell } from "./PropertyCriteriaCell";

const SearchDemandsListRowMobile: FC<{ searchDemand: SearchDemandsRowFragment }> = ({ searchDemand }) => {
    const {
        createdfor,
        propresSalesSearchCriteria,
        propresLettingSearchCriteria,
        demandabc,
        demandstatus,
        demandexpiration,
        demandcomments,
    } = searchDemand;

    return (
        <>
            <Box
                sx={{
                    minHeight: "4ex",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    paddingLeft: 1,
                    paddingRight: 1,
                    fontSize: "0.85rem",
                    paddingY: 1,
                }}
            >
                <Box>
                    <Typography variant="body1">
                        {createdfor?.firstname} {createdfor?.surname}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="caption">{demandstatus}</Typography>
                        <Typography variant="caption">{demandabc}</Typography>
                        <Typography variant="caption">{demandexpiration}</Typography>
                        <Typography variant="caption" component="div">
                            {demandcomments}
                        </Typography>
                    </Box>

                    {!isEmpty(propresSalesSearchCriteria) ? (
                        <Box sx={{ paddingY: 2 }}>
                            <Typography sx={{ fontWeight: "bold" }}>Property Sales</Typography>
                            <PropertyCriteriaCell
                                properties={{ ...(propresSalesSearchCriteria as PropresSalesSearchCriteria), __typename: undefined }}
                            />
                        </Box>
                    ) : null}
                    {!isEmpty(propresLettingSearchCriteria) ? (
                        <Box sx={{ paddingY: 2 }}>
                            <Typography sx={{ fontWeight: "bold" }}>Property Lettings</Typography>
                            <PropertyCriteriaCell
                                properties={{ ...(propresLettingSearchCriteria as PropresLettingSearchCriteria), __typename: undefined }}
                            />
                        </Box>
                    ) : null}
                </Box>
            </Box>
            <Divider />
        </>
    );
};

export const SearchDemandsListMobileTable: FC<TableProps<SearchDemandsRowFragment>> = ({ data, loading }) => {
    if (loading) return <Loading />;

    return (
        <>
            {data?.map((searchDemand) => (
                <SearchDemandsListRowMobile key={searchDemand.key} searchDemand={searchDemand} />
            ))}
        </>
    );
};
