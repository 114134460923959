/* global Maybe */
import React, { PropsWithChildren } from "react";
import { MenuItem, Select, SelectProps as _SelectProps, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface BooleanSelectOptions {
    [key: string]: boolean;
}

interface BooleanSelectProps {
    value: Maybe<boolean>;
    name: string;
    onChange: (name: string, value: Maybe<boolean>) => void;
    id?: string;
    enableNoneSelection?: boolean;
    emptyLabel?: string;
    translationPrefix: string;
    SelectProps?: Partial<_SelectProps<Maybe<boolean> | string>>;
}

const options: BooleanSelectOptions = { yes: true, no: false };

const stringify = (value: Maybe<boolean>): string => {
    if (value == null) {
        return "";
    }
    return String(value);
};

const parseString = (value: string): boolean | undefined => {
    if (value === "true" || value === "false") {
        return JSON.parse(value);
    }
    return undefined;
};
export const BooleanSelect = ({
    value,
    name,
    onChange,
    id,
    enableNoneSelection,
    emptyLabel,
    translationPrefix,
    SelectProps,
}: PropsWithChildren<BooleanSelectProps>) => {
    const { t } = useTranslation("backend");
    const domId = id || emptyLabel;

    const handleChange = (e: any) => {
        onChange(name, parseString(e.target.value));
    };

    return (
        <Select<Maybe<boolean> | string>
            id={`bool-select-${domId}`}
            displayEmpty={enableNoneSelection}
            fullWidth
            autoWidth
            placeholder={emptyLabel}
            sx={{ minWidth: "100%" }}
            value={stringify(value)}
            onChange={handleChange}
            {...SelectProps}
        >
            {enableNoneSelection && (
                <MenuItem value="">
                    <Box sx={{ color: (theme) => theme.palette.grey.A400 }}>{emptyLabel}</Box>
                </MenuItem>
            )}
            {Object.keys(options).map((optionKey) => (
                <MenuItem key={optionKey} value={stringify(options[optionKey])}>
                    {t(`${translationPrefix}${optionKey}`)}
                </MenuItem>
            ))}
        </Select>
    );
};
