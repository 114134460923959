import { FC } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";

interface Galleryprops {
    images: { original: string; thumbnail: string }[];
}

export const Gallery: FC<Galleryprops> = ({ images }) => {
    const { t } = useTranslation();
    return images.length > 0 ? (
        <ImageGallery showPlayButton={false} items={images} />
    ) : (
        <Typography variant="body1">{t("translation:properties.no_picture")}</Typography>
    );
};
