/* global TableProps */
import { FC } from "react";
import { LettingsListRowFragment } from "lib/api";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import { Go3ToolboxRoutes } from "const/Go3ToolboxRoutes";
import { useTranslation } from "react-i18next";
import { useNavigateTo } from "hooks/useNavigateTo";
import { ButtonBase } from "@mui/material";
import { formatName, formatPrice } from "utils/formatters";
import { Loading } from "components/utils/Loading";
import { PaginationSizes } from "const/pagination";

export const LettingsListTable: FC<TableProps<LettingsListRowFragment>> = ({ data, loading, page, setPage, pageSize, setPageSize }) => {
    const { t } = useTranslation("backend");
    const navigateTo = useNavigateTo();
    const { fullPath: lettingDetailFullPath } = Go3ToolboxRoutes.App.Lettings.DetailsPage;
    const { fullPath: contactDetailFullPath } = Go3ToolboxRoutes.App.Contacts.DetailsPage;

    const columns: GridColDef[] = [
        { field: "utag", headerName: t("translation:utag"), width: 130 },
        { field: "propertytype", headerName: t("propertytype"), width: 180 },
        { field: "streetname1", headerName: t("streetname1"), flex: 0.3 },
        { field: "streetnumber", headerName: t("streetnumber"), width: 180 },
        { field: "area", headerName: t("area"), width: 120 },
        { field: "district", headerName: t("district"), width: 120 },
        {
            field: "listingowner",
            headerName: t("listingowner"),
            renderCell: (params: GridCellParams) => {
                const {
                    value: { firstname, surname, utag },
                } = params as { value: { firstname: string; surname: string; utag: string } };

                return (
                    <ButtonBase
                        onClick={(e) => {
                            e.stopPropagation();
                            if (utag) {
                                navigateTo(contactDetailFullPath, { utag });
                            }
                        }}
                        type="button"
                    >
                        {firstname} {surname}
                    </ButtonBase>
                );
            },
            flex: 0.1,
        },
        { field: "netrentpmres", headerName: t("netrentpmres"), width: 160 },
        { field: "propertystatus", headerName: t("propertystatus"), width: 160 },
        { field: "createdfor", headerName: t("translation:agent"), flex: 0.3 },
    ];

    const rows =
        data?.map((property: LettingsListRowFragment) => {
            const { key, area_entity, district_entity, createdfor, listingowner_entity, netrentpmres, propertystatus, propertytype } =
                property;
            return {
                ...property,
                id: key,
                area: area_entity?.areaname,
                netrentpmres: formatPrice(netrentpmres),
                createdfor: formatName(createdfor?.firstname, createdfor?.surname),
                district: district_entity?.districtname,
                propertystatus: t(`backend:letting_propertystatus.${propertystatus}`) || "",
                propertytype: t(`backend:letting_propertytype.${propertytype}`) || "",
                listingowner: {
                    firstname: listingowner_entity?.firstname,
                    surname: listingowner_entity?.surname,
                    utag: listingowner_entity?.utag,
                },
            };
        }) || [];

    if (loading) return <Loading />;
    return (
        <DataGrid
            rows={rows}
            density="compact"
            columns={columns}
            onRowClick={({ row }) => navigateTo(lettingDetailFullPath, { utag: row.utag })}
            autoHeight
            pagination
            rowCount={(page + 1) * pageSize + 1}
            paginationMode="server"
            rowsPerPageOptions={PaginationSizes}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            componentsProps={{
                pagination: {
                    labelRowsPerPage: t("translation:rows_per_page"),
                },
            }}
        />
    );
};
