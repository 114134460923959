import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectNotifications, removeNotification } from "store/slices/notifications.slice";

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";

export const Notifications = () => {
    const dispatch = useAppDispatch();
    const notifications = useAppSelector(selectNotifications);

    useEffect(
        () => () => {
            setTimeout(() => {
                dispatch(removeNotification());
            }, 3000);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [notifications],
    );

    const handleClose = () => {
        dispatch(removeNotification());
    };
    return (
        <>
            {notifications.length > 0 && (
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={Boolean(notifications[notifications.length - 1])}
                    autoHideDuration={3000}
                    onClose={handleClose}
                >
                    <Alert
                        severity={notifications[notifications.length - 1].type}
                        variant="filled"
                        action={
                            <>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </>
                        }
                    >
                        {notifications[notifications.length - 1].message}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};
