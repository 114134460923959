import { configureI18next } from "./i18next";
import { configureApolloClient } from "./apolloClient";
import { configureEnv, Environment } from "./environments";

export const configureApp = (environment: Environment) => {
    configureI18next();

    const env = configureEnv(environment);
    const apolloClient = configureApolloClient(env);

    return { env, apolloClient };
};
