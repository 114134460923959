import React, { useState, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AuthenticationRedirectChecker from "components/auth/AuthenticationRedirectChecker";
import { useAppSelector } from "hooks/redux";
import { selectSelectedAgentId } from "store/slices/auth.slice";
import { getApolloClient } from "config/apolloClient";
import { useNavigate } from "react-router";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { NavbarHeight } from "./const";

const LayoutRoot = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
}));

const LayoutWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    [theme.breakpoints.down("lg")]: {
        paddingTop: NavbarHeight,
        paddingLeft: 0,
    },
    [theme.breakpoints.up("lg")]: {
        paddingTop: 0,
        paddingLeft: 256,
    },
}));

const LayoutContainer = styled("div")({
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
});

const LayoutContent = styled("div")(({ theme }) => ({
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
    [theme.breakpoints.down("lg")]: {
        padding: 0,
    },
    [theme.breakpoints.up("lg")]: {
        padding: 16,
    },
}));

const MainLayout = () => {
    const navigate = useNavigate();
    const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
    const selectedAgentId = useAppSelector(selectSelectedAgentId);
    const client = getApolloClient();
    const oldSelectedAgentId = useRef(selectedAgentId);

    useEffect(() => {
        // Shahin: Prevent refetch queries on initial load
        if (oldSelectedAgentId.current === selectedAgentId) return;

        oldSelectedAgentId.current = selectedAgentId;
        const refetchQueries = async () => {
            navigate("/");
            if (selectedAgentId) await client.refetchQueries({ include: "all" });
        };

        refetchQueries();
    }, [selectedAgentId]);

    return (
        <AuthenticationRedirectChecker>
            <LayoutRoot>
                <Navbar onMobileNavOpen={() => setMobileNavOpen(true)} />
                <Sidebar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
                <LayoutWrapper>
                    <LayoutContainer>
                        <LayoutContent>
                            <Outlet />
                        </LayoutContent>
                    </LayoutContainer>
                </LayoutWrapper>
            </LayoutRoot>
        </AuthenticationRedirectChecker>
    );
};

export default MainLayout;
