import React, { FC, useMemo } from "react";
import Typography from "@mui/material/Typography/Typography";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useTranslation } from "react-i18next";
import { selectLettingsListFilters, setFilter } from "store/slices/lettingsList.slice";
import { LettingPropertystatus, LettingPropertytype, PropreslettingQueryWhere } from "lib/api";
import { SearchBar } from "components/utils/SearchBar";
import { EnumSelect } from "components/utils/EnumSelect";
import { BooleanSelect } from "components/utils/BooleanSelect";
import { enumToObject } from "utils/convertors";

const lettingTypeOptions = enumToObject(LettingPropertytype);
const lettingTypeStatuses = enumToObject(LettingPropertystatus);

export const LettingsListHeader: FC = () => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector(selectLettingsListFilters);

    const { t } = useTranslation("backend");

    const onChange = useMemo(
        () => (field: string, value: string) => dispatch(setFilter({ field: field as keyof PropreslettingQueryWhere, value })),
        [dispatch],
    );

    const onSelect = useMemo(
        () =>
            <Option extends unknown>(field: string, value: Option) => {
                dispatch(setFilter({ field: field as keyof PropreslettingQueryWhere, value }));
            },
        [dispatch],
    );

    return (
        <Box paddingTop={2} paddingBottom={2}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>{t("translation:search_and_filter")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="utag" onChange={onChange} placeholder={t("translation:utag")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="createdfor" onChange={onChange} placeholder={t("translation:agent")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <EnumSelect<LettingPropertytype>
                                value={filters.propertytype as LettingPropertytype | undefined}
                                name="propertytype"
                                onChange={onSelect}
                                options={lettingTypeOptions}
                                enableNoneSelection
                                emptyLabel={t("backend:propertytype")}
                                translationPrefix="backend:letting_propertytype."
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <EnumSelect<LettingPropertystatus>
                                value={filters.propertystatus as LettingPropertystatus | undefined}
                                name="propertystatus"
                                onChange={onSelect}
                                options={lettingTypeStatuses}
                                enableNoneSelection
                                emptyLabel={t("backend:propertystatus")}
                                translationPrefix="backend:letting_propertystatus."
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="streetname1" onChange={onChange} placeholder={t("backend:streetname1")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="streetnumber" onChange={onChange} placeholder={t("backend:streetnumber")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="postcode" onChange={onChange} placeholder={t("backend:addressres.postcode$city")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="city" onChange={onChange} placeholder={t("backend:city")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="area" onChange={onChange} placeholder={t("backend:area")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="district" onChange={onChange} placeholder={t("backend:district")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="propertyname" onChange={onChange} placeholder={t("backend:propertyname")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar
                                type="number"
                                name="surfacelivinarea.Min"
                                onChange={onChange}
                                placeholder={t("translation:surfacelivinarea.min")}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar
                                type="number"
                                name="surfacelivinarea.Max"
                                onChange={onChange}
                                placeholder={t("translation:surfacelivinarea.max")}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar
                                type="number"
                                name="plotsurface.Min"
                                onChange={onChange}
                                placeholder={t("translation:plotsurface.min")}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar
                                type="number"
                                name="plotsurface.Max"
                                onChange={onChange}
                                placeholder={t("translation:plotsurface.max")}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar type="number" name="numberbedrooms" onChange={onChange} placeholder={t("backend:numberbedrooms")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="netrentpmres" onChange={onChange} placeholder={t("backend:netrentpmres")} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <BooleanSelect
                                value={filters.sign}
                                name="sign"
                                onChange={onSelect}
                                enableNoneSelection
                                emptyLabel={t("backend:sign")}
                                translationPrefix="translation:propertysign."
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <BooleanSelect
                                value={filters.keyonshop}
                                name="keyonshop"
                                onChange={onSelect}
                                enableNoneSelection
                                emptyLabel={t("backend:keyonshop")}
                                translationPrefix="translation:keyonshop."
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
