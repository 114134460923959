import { createTheme } from "@mui/material";

export const ThemeColors = {
    primary: "#eb0a0a",
    primaryText: "#444",
    mediumGrey: "#777",
    lightGrey: "#BCBDBC",
    fullLightGrey: "#f0f0f0",
    lighterGrey: "#fafafa",
    white: "#fff",
    black: "#000",
    redB3: "#b30000",
    red: "#FF0000",
};

declare module "@mui/material/styles/createTheme" {
    interface Theme {
        icons: {
            width: number;
            height: number;
        };
    }

    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        icons: {
            width?: number;
            height?: number;
        };
    }
}

const spacing = 8;
const fontFamily =
    'Helvetica Neue, "Helvetica Neue", Helvetica, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif';

declare module "@mui/material/styles/createPalette" {
    interface Palette {
        red: Palette["grey"];
        neutral: Palette["primary"];
    }
    interface PaletteOptions {
        red: PaletteOptions["grey"];
        neutral: PaletteOptions["primary"];
    }
}

const muiCustomTheme = createTheme({
    palette: {
        primary: {
            light: `${ThemeColors.primary}AA`,
            main: ThemeColors.primary,
            dark: ThemeColors.red,
            contrastText: ThemeColors.white,
        },
        secondary: {
            light: ThemeColors.mediumGrey,
            main: ThemeColors.primaryText,
            dark: `${ThemeColors.primaryText}DD`,
            contrastText: ThemeColors.white,
        },
        grey: {
            100: ThemeColors.lighterGrey,
            300: ThemeColors.fullLightGrey,
            400: ThemeColors.lightGrey,
            600: ThemeColors.mediumGrey,
            900: ThemeColors.primaryText,
        },
        red: {
            100: ThemeColors.red,
            200: ThemeColors.redB3,
        },
        neutral: {
            main: ThemeColors.white,
            dark: ThemeColors.black,
        },
        text: {
            primary: ThemeColors.primaryText,
            secondary: ThemeColors.mediumGrey,
            disabled: ThemeColors.lightGrey,
            // hint: ThemeColors.mediumGrey,
        },
        divider: ThemeColors.lightGrey,
        background: {
            default: ThemeColors.white,
            paper: ThemeColors.white,
        },
    },
    icons: {
        width: 20,
        height: 20,
    },
    spacing,
    shadows: [
        "none",
        // 1 Card:
        "0px 0px 24px rgba(0, 0, 0, 0.1)",
        // 2 Select & Input:
        "0px 0px 6px rgba(0, 0, 0, 0.2)",
        // 3:
        "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
        // 4:
        "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        // 5:
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
        // 6:
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
        // 7:
        "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
        // 8:
        "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        // 9:
        "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
        // 10:
        "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
        // 11:
        "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
        // 12:
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
        // 13:
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
        // 14:
        "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
        // 15:
        "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
        // 16:
        "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
        // 17:
        "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
        // 18:
        "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
        // 19:
        "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
        // 20:
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
        // 21:
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
        // 22:
        "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
        // 23:
        "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
        // 24:
        "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
    ],
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },

        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled .MuiSvgIcon-root": {
                        color: ThemeColors.lightGrey,
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "&$error": {
                        color: ThemeColors.redB3,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily,
                    textTransform: "none",
                    padding: `${spacing * 0.625}px ${spacing * 3}px`,
                    lineHeight: `${spacing * 2.75}px`,
                    fontSize: 14,
                    fontWeight: 500,
                },
                sizeLarge: {
                    padding: `${spacing * 1.125}px ${spacing * 3}px`,
                    fontSize: 16,
                    lineHeight: `${spacing * 3.25}px`,
                },
                contained: {
                    boxShadow: "none",
                },
                iconSizeMedium: {
                    "& > :first-child": {
                        fontSize: "24px",
                    },
                },
                iconSizeLarge: {
                    "& > :first-child": {
                        fontSize: "24px",
                    },
                },
                containedPrimary: {
                    border: `2px solid ${ThemeColors.primary}`,
                    "&:hover": {
                        boxShadow: "0px 16px 18px rgba(0, 0, 0, 0.13)",
                        backgroundColor: ThemeColors.primary,
                    },
                    "&:active": {
                        backgroundColor: ThemeColors.redB3,
                        boxShadow: "none",
                    },
                    "&:focus": {
                        border: `2px solid ${ThemeColors.redB3}`,
                        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.5)",
                    },
                    "&.Mui-disabled": {
                        color: ThemeColors.white,
                        backgroundColor: ThemeColors.lightGrey,
                        border: `2px solid ${ThemeColors.lightGrey}`,
                        "&:focus": {
                            border: "none",
                        },
                    },
                },
                containedSecondary: {
                    border: `2px solid ${ThemeColors.primaryText}`,
                    "&:hover": {
                        boxShadow: "0px 16px 18px rgba(0, 0, 0, 0.13)",
                        backgroundColor: ThemeColors.primaryText,
                    },
                    "&:focus": {
                        borderColor: `${ThemeColors.black}`,
                        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.5)",
                    },
                    "&:active": {
                        borderColor: `${ThemeColors.mediumGrey}`,
                        backgroundColor: ThemeColors.mediumGrey,
                        boxShadow: "none",
                    },
                    "&.Mui-disabled": {
                        color: ThemeColors.white,
                        backgroundColor: ThemeColors.lightGrey,
                        border: `2px solid ${ThemeColors.lightGrey}`,
                        "&:focus": {
                            border: "none",
                        },
                    },
                },
                textSizeLarge: {
                    "&&&": {
                        padding: `${spacing * 1.25}px ${spacing * 3}px`,
                        height: 48,
                    },
                },
                text: {
                    height: 36,
                    textDecoration: "underline",
                    border: `2px solid transparent`,
                    padding: "7px 12px",
                    "&:focus": {
                        border: `2px solid ${ThemeColors.mediumGrey}`,
                    },
                    "&:hover": {
                        backgroundColor: ThemeColors.fullLightGrey,
                        color: ThemeColors.primary,
                        textDecoration: "underline",
                    },
                    "&:active": {
                        color: ThemeColors.redB3,
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                    },
                    "&.Mui-disabled": {
                        color: ThemeColors.lightGrey,
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                    },
                },
                outlinedSizeLarge: {
                    "&&&": {
                        padding: `${spacing * 1.25}px ${spacing * 3}px`,
                        height: 48,
                    },
                },
                outlined: {
                    height: 36,
                    padding: `${spacing * 0.875}px ${spacing * 3}px`,
                    lineHeight: `${spacing * 2.75}px`,
                    border: "none",
                    boxShadow: `inset 0px 0px 0px 1px ${ThemeColors.mediumGrey}`,
                    color: ThemeColors.mediumGrey,

                    "&:focus": {
                        boxShadow: `inset 0px 0px 0px 2px ${ThemeColors.mediumGrey}`,
                    },
                    "&:hover": {
                        boxShadow: `inset 0px 0px 0px 1px ${ThemeColors.primaryText}, 0px 16px 18px rgba(0, 0, 0, 0.13)`,
                        backgroundColor: ThemeColors.primaryText,
                        color: ThemeColors.white,
                    },
                    "&:active": {
                        boxShadow: `inset 0px 0px 0px 2px ${ThemeColors.mediumGrey}`,
                        backgroundColor: ThemeColors.mediumGrey,
                    },
                    "&.Mui-disabled": {
                        border: "none",
                        color: ThemeColors.white,
                        backgroundColor: ThemeColors.lightGrey,
                        boxShadow: `none`,
                        "&:focus": {
                            border: "none",
                        },
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    margin: 0,
                    fontSize: 12,
                    "&.Mui-error": {
                        color: ThemeColors.redB3,
                        backgroundColor: "transparent",
                    },
                },
                contained: {
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 0,
                    lineHeight: "1rem",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    paddingTop: "14.5px",
                    paddingBottom: "14.5px",
                },
                root: {
                    fontSize: "1rem",
                    "&:not(.Mui-disabled):hover fieldset, &.Mui-focused:not(.Mui-disabled) fieldset": {
                        border: `1px solid ${ThemeColors.mediumGrey}`,
                        boxShadow: "0px 5px 2px -4px rgba(179, 0, 0, 0.6)",
                    },
                    "&.Mui-focused:not(.Mui-disabled) fieldset": {
                        border: `1px solid ${ThemeColors.lightGrey}`,
                    },
                    "&.Mui-error fieldset.MuiOutlinedInput-notchedOutline": {
                        borderColor: ThemeColors.redB3,
                    },
                    "&$disabled": {
                        backgroundColor: ThemeColors.fullLightGrey,
                        "&:hover": {
                            borderColor: `${ThemeColors.lightGrey}`,
                        },
                        "& .MuiInputAdornment-root": {
                            color: ThemeColors.red,
                        },
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    background: "none",
                    color: ThemeColors.mediumGrey,
                },
                outlined: {
                    transform: "translate(14px, 16px) scale(1)",
                    "&$shrink": {
                        transform: "translate(0px, -16px) scale(0.75)",
                        color: ThemeColors.mediumGrey,
                    },
                    transition: "0.4s all",
                    "&.Mui-disabled": {
                        backgroundColor: "transparent",
                    },
                    "&.Mui-error.MuiInputLabel-shrink": {
                        color: ThemeColors.redB3,
                    },
                },
                // marginDense: {
                //     transform: "translate(20px, 10px)",
                // },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                colorSecondary: {
                    "&&&.MuiCheckbox-root:hover, &.Mui-checked:hover": {
                        backgroundColor: "transparent",
                    },
                    "&:not(.Mui-checked):hover > .MuiIconButton-label > span": {
                        border: `1px solid ${ThemeColors.mediumGrey}`,
                        backgroundColor: ThemeColors.fullLightGrey,
                    },
                    "& > .MuiIconButton-label > span": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                    "&.Mui-disabled .MuiIconButton-label > span": {
                        border: `1px solid ${ThemeColors.lightGrey}`,
                        backgroundColor: ThemeColors.fullLightGrey,
                        "& > svg": {
                            color: ThemeColors.lightGrey,
                        },
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                iconSmall: {
                    width: 28,
                    height: 28,
                    marginLeft: 0,
                    marginRight: 0,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    borderBottom: "1px solid",
                    borderBottomColor: ThemeColors.lightGrey,
                    "&.Mui-expanded": {
                        margin: 0,
                    },
                    "&&.Mui-expanded::before": {
                        opacity: 1,
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    "& .Mui-expanded.MuiAccordionSummary-expandIcon": {
                        transition: ".2s ease-in-out",
                    },
                },
                content: {
                    marginBottom: 16,
                    marginTop: 16,
                    "& h3, & a": {
                        width: "100%",
                    },
                    "& a": {
                        display: "block",
                        color: ThemeColors.primaryText,
                        textDecoration: "none",
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeLarge: {
                    fontSize: "2.25rem",
                },
                fontSizeSmall: {
                    fontSize: "1.75rem",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    "&:first-child": {
                        paddingTop: 0,
                    },
                    padding: 0,
                },
            },
        },
    },
});

// Desktop: Heading-XL
// Mobile: Heading-L
muiCustomTheme.typography.h1 = {
    fontSize: muiCustomTheme.typography.pxToRem(34),
    lineHeight: muiCustomTheme.typography.pxToRem(44),
    fontWeight: 400,
    [muiCustomTheme.breakpoints.down("sm")]: {
        fontSize: muiCustomTheme.typography.pxToRem(28),
        lineHeight: muiCustomTheme.typography.pxToRem(32),
    },
};

// Desktop: Heading-M
// Mobile: Heading-S-400
muiCustomTheme.typography.h2 = {
    fontSize: muiCustomTheme.typography.pxToRem(28),
    lineHeight: muiCustomTheme.typography.pxToRem(32),
    fontWeight: 400,
    [muiCustomTheme.breakpoints.down("sm")]: {
        fontSize: muiCustomTheme.typography.pxToRem(20),
        lineHeight: muiCustomTheme.typography.pxToRem(26),
    },
};

// Desktop: Heading-M (Expose headlines)
muiCustomTheme.typography.h3 = {
    fontSize: muiCustomTheme.typography.pxToRem(24),
    lineHeight: muiCustomTheme.typography.pxToRem(28),
    fontWeight: 400,
};

// Desktop: Heading-S-400
muiCustomTheme.typography.h4 = {
    fontSize: muiCustomTheme.typography.pxToRem(18),
    lineHeight: muiCustomTheme.typography.pxToRem(24),
    fontWeight: 400,
};

// Desktop: Heading-XS-400
muiCustomTheme.typography.h5 = {
    fontSize: muiCustomTheme.typography.pxToRem(18),
    lineHeight: muiCustomTheme.typography.pxToRem(22),
    fontWeight: 400,
};

// Desktop: Body-M-400-Naked
muiCustomTheme.typography.h6 = {
    fontSize: muiCustomTheme.typography.pxToRem(16),
    lineHeight: muiCustomTheme.typography.pxToRem(26),
    fontWeight: 400,
    [muiCustomTheme.breakpoints.down("sm")]: {
        fontSize: muiCustomTheme.typography.pxToRem(14),
        lineHeight: muiCustomTheme.typography.pxToRem(22),
    },
};

// Desktop: Detail-400
muiCustomTheme.typography.subtitle1 = {
    fontSize: muiCustomTheme.typography.pxToRem(12),
    lineHeight: muiCustomTheme.typography.pxToRem(16),
    fontWeight: 400,
};

// Desktop: Detail-S-400
muiCustomTheme.typography.subtitle2 = {
    fontSize: muiCustomTheme.typography.pxToRem(11),
    lineHeight: muiCustomTheme.typography.pxToRem(16),
    fontWeight: 400,
};

// Desktop: Body-M-Loose-400-Naked
// Mobile: Body-S-Narrow-400
muiCustomTheme.typography.body1 = {
    fontSize: muiCustomTheme.typography.pxToRem(16),
    lineHeight: muiCustomTheme.typography.pxToRem(26),
    fontWeight: 400,
    [muiCustomTheme.breakpoints.down("sm")]: {
        fontSize: muiCustomTheme.typography.pxToRem(14),
    },
};

// Desktop: Body-S-500-Naked
muiCustomTheme.typography.body2 = {
    fontFamily,
    fontSize: muiCustomTheme.typography.pxToRem(14),
    lineHeight: muiCustomTheme.typography.pxToRem(22),
    fontWeight: 500,
};

// Desktop: Detail-S-400
muiCustomTheme.typography.caption = {
    fontSize: muiCustomTheme.typography.pxToRem(14),
    lineHeight: muiCustomTheme.typography.pxToRem(22),
    fontWeight: 400,
};

// Desktop: Detail-XS-500-Caps
muiCustomTheme.typography.overline = {
    fontSize: muiCustomTheme.typography.pxToRem(10),
    lineHeight: muiCustomTheme.typography.pxToRem(16),
    fontWeight: 500,
};

export { muiCustomTheme };
