import { String } from './scalars';
import { NullInt } from './scalars';
import { NullNotes } from './scalars';
import { NullString } from './scalars';
import { NullText } from './scalars';
import { NullYN } from './scalars';
import { Text } from './scalars';
import { YN } from './scalars';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: String;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  Currency: any;
  Date: any;
  NullDate: any;
  NullFloat1: any;
  NullFloat2: any;
  NullInt: NullInt;
  NullNotes: NullNotes;
  NullRef: any;
  NullString: NullString;
  NullText: NullText;
  NullYN: NullYN;
  NullYear: any;
  PropreslettingSubType: any;
  PropressaleSubType: any;
  Ref: any;
  Text: Text;
  Time: any;
  Timestamp: any;
  Void: any;
  YN: YN;
};






export enum Activestatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type Activity = {
  __typename?: 'Activity';
  key: Scalars['Int'];
  utag: Scalars['String'];
  unified_date: Scalars['Date'];
  type: Scalars['String'];
  agent: Scalars['NullRef'];
  createdfor?: Maybe<Agent>;
  status: ActivityStatus;
  title: Scalars['String'];
  link_contacts: Array<Scalars['Ref']>;
  link_contacts_entities: Array<Contact>;
  link_propressales: Array<Scalars['Ref']>;
  link_propressales_entities: Array<Propressale>;
  link_propreslettings: Array<Scalars['Ref']>;
  link_propreslettings_entities: Array<Propresletting>;
};

export type ActivityQueryOrder = {
  status?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  createdfor?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
};

export type ActivityQueryResponse = {
  __typename?: 'ActivityQueryResponse';
  rows?: Maybe<Array<Activity>>;
};

export type ActivityQueryWhere = {
  type?: Maybe<Activitytype>;
  status?: Maybe<ActivityStatus>;
  createdfor?: Maybe<Scalars['String']>;
  datefrom?: Maybe<Scalars['Date']>;
  dateto?: Maybe<Scalars['Date']>;
  contact?: Maybe<Scalars['String']>;
  propressale?: Maybe<Scalars['String']>;
  propresletting?: Maybe<Scalars['String']>;
};

export enum ActivityStatus {
  Open = 'open',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Inbox = 'inbox'
}

export enum Addresscategory {
  Standard = 'standard',
  History = 'history',
  Other = 'other'
}

export enum Addresscom {
  Fileas = 'fileas',
  Streetname1Streetnumber = 'streetname1_streetnumber',
  StreetnumberStreetname1 = 'streetnumber_streetname1',
  Streetname1 = 'streetname1',
  PostcodeCity = 'postcode_city',
  City = 'city',
  Area = 'area',
  District = 'district',
  Coverletteraddress = 'coverletteraddress',
  Propertyname = 'propertyname'
}

export enum Addressformat {
  Germany = 'germany',
  Uk = 'uk',
  Othereurope = 'othereurope',
  Us = 'us',
  Hungary = 'hungary',
  Uk2 = 'uk2'
}

export enum Addressres {
  Fileas = 'fileas',
  Streetname1Streetnumber = 'streetname1_streetnumber',
  StreetnumberStreetname1 = 'streetnumber_streetname1',
  Streetname1 = 'streetname1',
  PostcodeCity = 'postcode_city',
  City = 'city',
  Area = 'area',
  District = 'district',
  Coverletteraddress = 'coverletteraddress',
  Towername = 'towername',
  Propertyname = 'propertyname',
  Villaapartmentnumber = 'villaapartmentnumber',
  Country = 'country',
  Region = 'region'
}

export enum Addresstype {
  Private = 'private',
  Business = 'business',
  Holiday = 'holiday',
  Secret = 'secret',
  Indefinite = 'indefinite',
  Weekend = 'weekend',
  Secondinhomecountry = 'secondinhomecountry',
  Secondinforeigncountry = 'secondinforeigncountry'
}

export type Agent = {
  __typename?: 'Agent';
  key: Scalars['Int'];
  utag: Scalars['String'];
  foreign_key?: Maybe<Scalars['Int']>;
  title: Title;
  surname: Scalars['Text'];
  firstname: Scalars['NullText'];
  academictitle: Scalars['NullText'];
  agentstatus: Staffstatus;
  agentcategory: Agentcategory;
  agentpermission: Agentpermission;
  agentlanguage: Language;
  locale?: Maybe<Locale>;
  department: Department;
};

export enum Agentcategory {
  Departmentmanager = 'departmentmanager',
  Agent = 'agent',
  Assistant = 'assistant'
}

export enum Agentpermission {
  Departmentmanager = 'departmentmanager',
  Agent = 'agent',
  Agentlight = 'agentlight'
}


export enum Apmtactiontype {
  Valuation = 'valuation',
  Notary = 'notary',
  Seatrial = 'seatrial',
  Miscellaneous = 'miscellaneous',
  Pricereduction = 'pricereduction',
  Propertypresentation = 'propertypresentation',
  Handover = 'handover'
}

export enum Apmtcategory {
  Appointment = 'appointment',
  Visit = 'visit',
  Openhouse = 'openhouse'
}

export type Appointment = {
  __typename?: 'Appointment';
  key: Scalars['Int'];
  utag: Scalars['String'];
  owner_agent_key: Scalars['Int'];
  createdfor?: Maybe<Agent>;
  apmtcategory: Apmtcategory;
  apmtstatus: Taskstatus;
  laststatuschangeddate: Scalars['NullDate'];
  apmtactiontype: Apmtactiontype;
  apmtsubject: Scalars['NullText'];
  apmtdate: Scalars['Date'];
  apmtstarttime: Scalars['Time'];
  apmtendtime: Scalars['Time'];
  apmtcomments: Scalars['NullNotes'];
  apmtlinkcontacts: Array<Scalars['Ref']>;
  apmtlinkcontacts_entities: Array<Contact>;
  apmtlinkpropressales: Array<Scalars['Ref']>;
  apmtlinkpropressales_entities: Array<Propressale>;
  apmtlinkpropreslettings: Array<Scalars['Ref']>;
  apmtlinkpropreslettings_entities: Array<Propresletting>;
  unifieddate: Scalars['NullDate'];
  apmtattachments: Array<Attachment>;
};

export type Area = {
  __typename?: 'Area';
  key: Scalars['Int'];
  areaname: Scalars['Text'];
  linkregion: Scalars['NullRef'];
  supportcomments: Scalars['NullNotes'];
};

export type Attachment = {
  __typename?: 'Attachment';
  url: Scalars['String'];
};

export enum Cdftype {
  Customizeddemandfield1 = 'customizeddemandfield1',
  Customizeddemandfield2 = 'customizeddemandfield2'
}

export enum Classification {
  Industrialarea = 'industrialarea',
  Retailarea = 'retailarea',
  Retailareawithrestrictions = 'retailareawithrestrictions',
  Mixarea = 'mixarea',
  Residentialarea = 'residentialarea',
  Farmingarea = 'farmingarea',
  Norestrictions = 'norestrictions',
  Commercialarea = 'commercialarea',
  Corearea = 'corearea',
  Pureresidentialarea = 'pureresidentialarea',
  Outskirtarea = 'outskirtarea',
  Buildinglaw = 'buildinglaw',
  Specialarea = 'specialarea'
}

export enum Co2Emissionrating {
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
  E = 'e',
  F = 'f',
  G = 'g'
}

export enum Commissiontype {
  Commissionownerside = 'commissionownerside',
  Commissionbyertenantside = 'commissionbyertenantside',
  Commissionmixed = 'commissionmixed'
}

export enum Conditionbelgium {
  Ademolir = 'ademolir',
  Arestaurerprofessionnel = 'arestaurerprofessionnel',
  Arafrairchirbricoleur = 'arafrairchirbricoleur',
  Enbonetatpetitstravaux = 'enbonetatpetitstravaux',
  Primiereoccupation = 'primiereoccupation',
  Ventresurplan = 'ventresurplan',
  Arenoverbricoleurassidu = 'arenoverbricoleurassidu',
  Enparfaitetat = 'enparfaitetat'
}

export enum Conformity {
  Yesconform = 'yesconform',
  Yesnotconform = 'yesnotconform',
  No = 'no'
}

export type Contact = {
  __typename?: 'Contact';
  addresscategory1: Addresscategory;
  addresscategory2: Addresscategory;
  addressformat1: Addressformat;
  addressformat2: Addressformat;
  addresstype1: Addresstype;
  addresstype2: Addresstype;
  birthday: Scalars['NullDate'];
  city1: Scalars['Text'];
  city2: Scalars['NullText'];
  commentsgenera: Scalars['NullNotes'];
  company: Scalars['NullText'];
  contactcategory: Contactcategory;
  country1: Country;
  country2: Country;
  creator_agent?: Maybe<Agent>;
  creator_login_key: Scalars['Int'];
  department: Scalars['NullText'];
  email1: Scalars['NullText'];
  email2: Scalars['NullText'];
  envsource: Envsource;
  favouredwayofcontact: Favouredwayofcontact;
  fax1: Scalars['NullText'];
  firstname: Scalars['NullText'];
  firstnamepartner: Scalars['NullText'];
  idpassport: Scalars['YN'];
  idpassportcomments: Scalars['NullText'];
  key: Scalars['Int'];
  lastaction: Scalars['NullDate'];
  latitude1: Scalars['NullFloat2'];
  latitude2: Scalars['NullFloat2'];
  logitude1: Scalars['NullFloat2'];
  logitude2: Scalars['NullFloat2'];
  mobileoffice: Scalars['NullText'];
  mobileprivate: Scalars['NullText'];
  newtitle: Scalars['NullText'];
  nomail: Scalars['YN'];
  owner_agent_key: Scalars['Int'];
  owner_agent_entity?: Maybe<Agent>;
  phoneoffice1: Scalars['NullText'];
  pobox1: Scalars['NullText'];
  pobox2: Scalars['NullText'];
  postalcode1: Scalars['NullText'];
  postalcode2: Scalars['NullText'];
  preferredlanguage: Language;
  propressales?: Maybe<Array<Propressale>>;
  province1: Scalars['NullText'];
  province2: Scalars['NullText'];
  secondagent: Scalars['NullRef'];
  secondagent_entity?: Maybe<Agent>;
  secondcategory: Contactcategory;
  secondstatus: Contactstatus;
  source: Envsource;
  status: Contactstatus;
  streetname1: Scalars['NullText'];
  streetname2: Scalars['NullText'];
  streetnumber1: Scalars['NullText'];
  streetnumber2: Scalars['NullText'];
  surname: Scalars['NullText'];
  surnamepartner: Scalars['NullText'];
  telprivate1: Scalars['NullText'];
  thirdagent: Scalars['NullRef'];
  thirdagent_entity?: Maybe<Agent>;
  thirdcategory: Contactcategory;
  thirdstatus: Contactstatus;
  title: Scalars['NullInt'];
  title_value?: Maybe<Title>;
  utag: Scalars['String'];
};

export type ContactQueryOrder = {
  key?: Maybe<OrderBy>;
  utag?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  firstname?: Maybe<OrderBy>;
  surname?: Maybe<OrderBy>;
  contactcategory?: Maybe<OrderBy>;
  mobileoffice?: Maybe<OrderBy>;
  mobileprivate?: Maybe<OrderBy>;
  email1?: Maybe<OrderBy>;
  email2?: Maybe<OrderBy>;
  lastaction?: Maybe<OrderBy>;
};

export type ContactQueryResponse = {
  __typename?: 'ContactQueryResponse';
  rows?: Maybe<Array<Contact>>;
};

export type ContactQueryWhere = {
  key?: Maybe<Scalars['Int']>;
  utag?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  streetname?: Maybe<Scalars['String']>;
  streetnumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  category?: Maybe<Contactcategory>;
  status?: Maybe<Contactstatus>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdfor?: Maybe<Scalars['String']>;
};

export enum Contactcategory {
  Buyer = 'buyer',
  Owner = 'owner',
  Servicecontact = 'servicecontact',
  Contact = 'contact',
  Captain = 'captain',
  Contactperson = 'contactperson',
  Charterbroker = 'charterbroker',
  Charterer = 'charterer',
  Shipyard = 'shipyard',
  Yachtmanagement = 'yachtmanagement',
  Exteriordesigner = 'exteriordesigner',
  Interiordesigner = 'interiordesigner',
  Brokercompany = 'brokercompany',
  Potentiallp = 'potentiallp',
  Tenant = 'tenant'
}

export enum Contactconsent {
  Yes = 'yes',
  Requested = 'requested',
  No = 'no'
}

export enum Contactstatus {
  Active = 'active',
  Inactive = 'inactive',
  Closed = 'closed'
}

export enum Country {
  Hn = 'hn',
  Hk = 'hk',
  Is = 'is',
  In = 'in',
  Id = 'id',
  Ir = 'ir',
  Au = 'au',
  Iq = 'iq',
  Il = 'il',
  Ci = 'ci',
  Jm = 'jm',
  Jp = 'jp',
  Kz = 'kz',
  Jo = 'jo',
  Ke = 'ke',
  Kp = 'kp',
  Kr = 'kr',
  Kw = 'kw',
  Kg = 'kg',
  Tw = 'tw',
  Cx = 'cx',
  Cc = 'cc',
  Co = 'co',
  Km = 'km',
  Yt = 'yt',
  Cg = 'cg',
  Cd = 'cd',
  Ck = 'ck',
  Cr = 'cr',
  Hr = 'hr',
  Cu = 'cu',
  Cz = 'cz',
  Bj = 'bj',
  Dk = 'dk',
  Dm = 'dm',
  Ec = 'ec',
  Sv = 'sv',
  Gq = 'gq',
  Et = 'et',
  Er = 'er',
  Ee = 'ee',
  Fo = 'fo',
  Pm = 'pm',
  Vc = 'vc',
  Sm = 'sm',
  St = 'st',
  Sa = 'sa',
  Cn = 'cn',
  Sn = 'sn',
  Gs = 'gs',
  Be = 'be',
  De = 'de',
  Gb = 'gb',
  Fr = 'fr',
  Ie = 'ie',
  It = 'it',
  Lu = 'lu',
  At = 'at',
  Pt = 'pt',
  Ch = 'ch',
  Es = 'es',
  Za = 'za',
  Th = 'th',
  Hu = 'hu',
  Us = 'us',
  Ae = 'ae',
  Cy = 'cy',
  Af = 'af',
  Al = 'al',
  Aq = 'aq',
  Dz = 'dz',
  As = 'as',
  Ad = 'ad',
  Ao = 'ao',
  Az = 'az',
  Ar = 'ar',
  Mr = 'mr',
  Mu = 'mu',
  Bs = 'bs',
  Bh = 'bh',
  Bd = 'bd',
  Am = 'am',
  Bb = 'bb',
  Bm = 'bm',
  Bt = 'bt',
  Bo = 'bo',
  Ba = 'ba',
  Bw = 'bw',
  Bv = 'bv',
  Br = 'br',
  Bz = 'bz',
  Io = 'io',
  Sb = 'sb',
  Vg = 'vg',
  Bn = 'bn',
  Bg = 'bg',
  Bi = 'bi',
  By = 'by',
  Kh = 'kh',
  Cm = 'cm',
  Ca = 'ca',
  Cv = 'cv',
  Ky = 'ky',
  Fj = 'fj',
  Fi = 'fi',
  Ax = 'ax',
  Gf = 'gf',
  Pf = 'pf',
  Tf = 'tf',
  Dj = 'dj',
  Ga = 'ga',
  Ge = 'ge',
  Gm = 'gm',
  Ps = 'ps',
  Gi = 'gi',
  Ki = 'ki',
  Gr = 'gr',
  Gl = 'gl',
  Gd = 'gd',
  Gp = 'gp',
  Gu = 'gu',
  Gt = 'gt',
  Gn = 'gn',
  Gy = 'gy',
  Ht = 'ht',
  Hm = 'hm',
  Sc = 'sc',
  Sl = 'sl',
  Sg = 'sg',
  Sk = 'sk',
  Vn = 'vn',
  Si = 'si',
  So = 'so',
  Zw = 'zw',
  Do = 'do',
  Fk = 'fk',
  Ag = 'ag',
  Mm = 'mm',
  Cf = 'cf',
  Va = 'va',
  Lb = 'lb',
  Li = 'li',
  Mq = 'mq',
  Ug = 'ug',
  Mk = 'mk',
  Vu = 'vu',
  Pr = 'pr',
  Kn = 'kn',
  Bl = 'bl',
  Me = 'me',
  Mf = 'mf',
  Rs = 'rs',
  La = 'la',
  Ls = 'ls',
  Lv = 'lv',
  Lr = 'lr',
  Lt = 'lt',
  Mo = 'mo',
  Mg = 'mg',
  Mw = 'mw',
  My = 'my',
  Mv = 'mv',
  Ml = 'ml',
  Mt = 'mt',
  Lk = 'lk',
  Td = 'td',
  Cl = 'cl',
  Eh = 'eh',
  Sd = 'sd',
  Sr = 'sr',
  Sj = 'sj',
  Sz = 'sz',
  Se = 'se',
  Sy = 'sy',
  Tj = 'tj',
  Tg = 'tg',
  Tk = 'tk',
  To = 'to',
  Tt = 'tt',
  Tn = 'tn',
  Tr = 'tr',
  Tm = 'tm',
  Tc = 'tc',
  Ua = 'ua',
  Eg = 'eg',
  Gg = 'gg',
  Je = 'je',
  Im = 'im',
  Tz = 'tz',
  Vi = 'vi',
  Bf = 'bf',
  Uy = 'uy',
  Uz = 'uz',
  Ve = 've',
  Wf = 'wf',
  Ws = 'ws',
  Ye = 'ye',
  Cs = 'cs',
  Zm = 'zm',
  Mx = 'mx',
  Mc = 'mc',
  Mn = 'mn',
  Md = 'md',
  Ms = 'ms',
  Ma = 'ma',
  Mz = 'mz',
  Om = 'om',
  Na = 'na',
  Nr = 'nr',
  Np = 'np',
  Nl = 'nl',
  An = 'an',
  Aw = 'aw',
  Nc = 'nc',
  Nz = 'nz',
  Ni = 'ni',
  Ne = 'ne',
  Ng = 'ng',
  Nu = 'nu',
  Nf = 'nf',
  No = 'no',
  Mp = 'mp',
  Um = 'um',
  Fm = 'fm',
  Mh = 'mh',
  Pw = 'pw',
  Pk = 'pk',
  Pa = 'pa',
  Pg = 'pg',
  Py = 'py',
  Pe = 'pe',
  Ph = 'ph',
  Pn = 'pn',
  Pl = 'pl',
  Gw = 'gw',
  Tl = 'tl',
  Qa = 'qa',
  Re = 're',
  Ro = 'ro',
  Ru = 'ru',
  Rw = 'rw',
  Sh = 'sh',
  Ai = 'ai',
  Lc = 'lc',
  Gh = 'gh',
  Ly = 'ly',
  Tv = 'tv'
}

export enum Coverlettertitle {
  Coverletteraddress = 'coverletteraddress',
  Personalizedcoverlettertitle = 'personalizedcoverlettertitle'
}


export type CustomizedDemand = {
  __typename?: 'CustomizedDemand';
  key: Scalars['Int'];
  cdfname: Scalars['Text'];
};


export enum Demandstatus {
  Active = 'active',
  Inactive = 'inactive',
  Closed = 'closed',
  Expired = 'expired'
}

export enum Demandtype {
  A = 'a',
  B = 'b',
  C = 'c'
}

export type Department = {
  __typename?: 'Department';
  key: Scalars['Int'];
  utag: Scalars['String'];
  foreignKey?: Maybe<Scalars['Int']>;
  office: Office;
  departmentinternalname: Scalars['Text'];
  abbreviatedname: Scalars['NullText'];
};

export type District = {
  __typename?: 'District';
  key: Scalars['Int'];
  districtname: Scalars['Text'];
  typeoflicence: Typeoflicence2;
  linkarea: Scalars['NullRef'];
  supportcomments: Scalars['NullNotes'];
};

export type Document = {
  __typename?: 'Document';
  key: Scalars['Int'];
  utag: Scalars['String'];
  owner_agent_key: Scalars['Int'];
  createdfor?: Maybe<Agent>;
  documenttype: Documenttype;
  documentstatus: Taskstatus;
  documentcomments: Scalars['NullNotes'];
  documentlinkcontacts: Array<Scalars['Ref']>;
  documentlinkcontacts_entities: Array<Contact>;
  documentlinkpropressales: Array<Scalars['Ref']>;
  documentlinkpropressales_entities: Array<Propressale>;
  documentlinkpropreslettings: Array<Scalars['Ref']>;
  documentlinkpropreslettings_entities: Array<Propresletting>;
  unifieddate: Scalars['NullDate'];
  documentsavedfile?: Maybe<Attachment>;
};

export enum Documentaction {
  Pap = 'pap',
  Pps = 'pps',
  Emd = 'emd',
  Eda = 'eda',
  Esi = 'esi',
  Eia = 'eia'
}

export enum Documenttype {
  Letter = 'letter',
  Expose = 'expose',
  Viewingreport = 'viewingreport'
}

export type Email = {
  __typename?: 'Email';
  key: Scalars['Int'];
  utag: Scalars['String'];
  owner_agent_key: Scalars['Int'];
  createdfor?: Maybe<Agent>;
  unifieddate: Scalars['NullDate'];
  emailtype: Emailtype;
  emailfrom: Scalars['Text'];
  emailto: Scalars['NullNotes'];
  emailsubject: Scalars['Text'];
  emaillinkcontacts: Array<Scalars['Ref']>;
  emaillinkcontacts_entities: Array<Contact>;
  emaillinkpropressales: Array<Scalars['Ref']>;
  emaillinkpropressales_entities: Array<Propressale>;
  emaillinkpropreslettings: Array<Scalars['Ref']>;
  emaillinkpropreslettings_entities: Array<Propresletting>;
  emailbody: Scalars['NullNotes'];
  emailattachments: Array<Attachment>;
};

export enum Emailfolder {
  Inbox = 'inbox',
  Sentmessages = 'sentmessages',
  Drafts = 'drafts',
  Trashcan = 'trashcan'
}

export enum Emailformat {
  Plaintext = 'plaintext',
  Html = 'html'
}

export enum Emailtype {
  Letter = 'letter',
  Expose = 'expose',
  Viewingreport = 'viewingreport',
  Others = 'others'
}

export enum Energylabel {
  Aplus = 'aplus',
  Aplusa = 'aplusa',
  Aplusb = 'aplusb',
  Aplusbminus = 'aplusbminus',
  Aplusc = 'aplusc',
  Aplusd = 'aplusd',
  Apluse = 'apluse',
  Aplusf = 'aplusf',
  Aplusg = 'aplusg',
  A = 'a',
  Ab = 'ab',
  Abminus = 'abminus',
  Ac = 'ac',
  Ad = 'ad',
  Ae = 'ae',
  Af = 'af',
  Ag = 'ag',
  B = 'b',
  Bbminus = 'bbminus',
  Bc = 'bc',
  Bd = 'bd',
  Be = 'be',
  Bf = 'bf',
  Bg = 'bg',
  Bminus = 'bminus',
  Bminusc = 'bminusc',
  Bminusd = 'bminusd',
  Bminuse = 'bminuse',
  Bminusf = 'bminusf',
  Bminusg = 'bminusg',
  C = 'c',
  Cd = 'cd',
  Ce = 'ce',
  Cf = 'cf',
  Cg = 'cg',
  D = 'd',
  De = 'de',
  Df = 'df',
  Dg = 'dg',
  E = 'e',
  Ef = 'ef',
  Eg = 'eg',
  F = 'f',
  Fg = 'fg',
  G = 'g'
}

export enum Envsource {
  Publicity = 'publicity',
  Existingcontact = 'existingcontact',
  Office = 'office',
  Outdooradvertising = 'outdooradvertising',
  Recommendation = 'recommendation',
  Gg = 'gg',
  Flyer = 'flyer',
  Coldcalling = 'coldcalling',
  Neighbourhoodflyer = 'neighbourhoodflyer',
  Privatecontact = 'privatecontact',
  Signboard = 'signboard',
  Initiativefromthecustomersside = 'initiativefromthecustomersside',
  Hintfrom = 'hintfrom',
  Event = 'event',
  Referral = 'referral',
  Internetportalev = 'internetportalev',
  Internetportalsdifferent = 'internetportalsdifferent',
  Internetportalis24 = 'internetportalis24',
  Internetportalimmonet = 'internetportalimmonet',
  Internetportalprimelocation = 'internetportalprimelocation',
  Exibition = 'exibition',
  Mailing = 'mailing',
  Telephonebook = 'telephonebook',
  Subsidiaries = 'subsidiaries',
  Othersunknown = 'othersunknown',
  Internetportals = 'internetportals',
  Farminglead = 'farminglead',
  Collaboration = 'collaboration'
}

export enum Escalendarfoldername {
  Kalender = 'kalender',
  Calendar = 'calendar'
}

export enum Esinboxfoldername {
  Posteingang = 'posteingang',
  Inbox = 'inbox'
}

export enum Exchangeserverip {
  Ev2003 = 'ev2003',
  Ev2003s = 'ev2003s',
  Evlab2003 = 'evlab2003',
  Evlab2003s = 'evlab2003s',
  Janz2010 = 'janz2010',
  Janzlab2010 = 'janzlab2010'
}

export enum Exfldcomindustrialrent {
  Totalsurfacewithunitscom = 'totalsurfacewithunitscom',
  Amountunitswithunits = 'amountunitswithunits',
  Subdividablefromwithunits = 'subdividablefromwithunits',
  Subdividabletowithunits = 'subdividabletowithunits',
  Amountavailableunits = 'amountavailableunits',
  Totaloccupiedsurfacewithunits = 'totaloccupiedsurfacewithunits',
  Totalavailablesurfacewu = 'totalavailablesurfacewu',
  Netrentsurfsystpmfrom = 'netrentsurfsystpmfrom',
  Netrentsurfsystpmto = 'netrentsurfsystpmto',
  Netrentyearfrom = 'netrentyearfrom',
  Netrentyearto = 'netrentyearto',
  Monthlynetrent = 'monthlynetrent',
  Numberparkingbays = 'numberparkingbays',
  Numbergarages = 'numbergarages',
  Servicingattributes = 'servicingattributes',
  Yearconstruction = 'yearconstruction',
  Hallheight = 'hallheight',
  Coverpagetext = 'coverpagetext'
}

export enum Exfldcomretailletting {
  Totalsurfacewithunitscom = 'totalsurfacewithunitscom',
  Amountunitswithunits = 'amountunitswithunits',
  Subdividablefromwithunits = 'subdividablefromwithunits',
  Subdividabletowithunits = 'subdividabletowithunits',
  Amountavailableunits = 'amountavailableunits',
  Totaloccupiedsurfacewithunits = 'totaloccupiedsurfacewithunits',
  Totalavailablesurfacewu = 'totalavailablesurfacewu',
  Netrentsurfsystpmfrom = 'netrentsurfsystpmfrom',
  Netrentsurfsystpmto = 'netrentsurfsystpmto',
  Netrentyearfrom = 'netrentyearfrom',
  Netrentyearto = 'netrentyearto',
  Numberparkingbays = 'numberparkingbays',
  Numbergarages = 'numbergarages',
  Yearconstruction = 'yearconstruction',
  Coverpagetext = 'coverpagetext'
}

export enum Exfldindustrialsale {
  Askingprice = 'askingprice',
  Askingpricesurfsystcom = 'askingpricesurfsystcom',
  Plotprice = 'plotprice',
  Plotpricesurfsyst = 'plotpricesurfsyst',
  Roicurrentpercent = 'roicurrentpercent',
  Roitargetpercent = 'roitargetpercent',
  Priceratiocurrent = 'priceratiocurrent',
  Priceratiotarget = 'priceratiotarget',
  Totalsurfacewithoutunitscom = 'totalsurfacewithoutunitscom',
  Plotsurface = 'plotsurface',
  Surfacetolet = 'surfacetolet',
  Occupiedsurfacewithoutunits = 'occupiedsurfacewithoutunits',
  Availablesurfacewithoutunits = 'availablesurfacewithoutunits',
  Occupancypercent = 'occupancypercent',
  Netrentpmcom = 'netrentpmcom',
  Netrentsurfsystpmcom = 'netrentsurfsystpmcom',
  Monthlynetrentcurrent = 'monthlynetrentcurrent',
  Monthlynetrenttarget = 'monthlynetrenttarget',
  Totalnetrentpycurrent = 'totalnetrentpycurrent',
  Totalnetrentpytarget = 'totalnetrentpytarget',
  Numberparkingbays = 'numberparkingbays',
  Numbergarages = 'numbergarages',
  Yearconstruction = 'yearconstruction',
  Coverpagetext = 'coverpagetext'
}

export enum Exfldofficeletting {
  Amountunitswithunits = 'amountunitswithunits',
  Subdividablefromwithunits = 'subdividablefromwithunits',
  Subdividabletowithunits = 'subdividabletowithunits',
  Amountavailableunits = 'amountavailableunits',
  Totaloccupiedsurfacewithunits = 'totaloccupiedsurfacewithunits',
  Totalavailablesurfacewu = 'totalavailablesurfacewu',
  Netrentsurfsystpmfrom = 'netrentsurfsystpmfrom',
  Netrentsurfsystpmto = 'netrentsurfsystpmto',
  Utilitiessurfsystmonthfrom = 'utilitiessurfsystmonthfrom',
  Numberparkingbays = 'numberparkingbays',
  Numbergarages = 'numbergarages',
  Yearconstruction = 'yearconstruction',
  Coverpagetext = 'coverpagetext'
}

export enum Exfldsmallcapinvest {
  Askingprice = 'askingprice',
  Askingpricesurfsystcom = 'askingpricesurfsystcom',
  Plotprice = 'plotprice',
  Plotpricesurfsyst = 'plotpricesurfsyst',
  Roicurrentpercent = 'roicurrentpercent',
  Roitargetpercent = 'roitargetpercent',
  Priceratiocurrent = 'priceratiocurrent',
  Priceratiotarget = 'priceratiotarget',
  Totalsurfacewithoutunitscom = 'totalsurfacewithoutunitscom',
  Plotsurface = 'plotsurface',
  Occupancypercent = 'occupancypercent',
  Totalnetrentpycurrent = 'totalnetrentpycurrent',
  Totalnetrentpytarget = 'totalnetrentpytarget',
  Yearconstruction = 'yearconstruction',
  Coverpagetext = 'coverpagetext'
}

export enum Exfldsreslettingapartment {
  Surfacelivinarea = 'surfacelivinarea',
  Usablesurface = 'usablesurface',
  Totalsurfaceres = 'totalsurfaceres',
  Numberlivingareas = 'numberlivingareas',
  Totalnumberbathrooms = 'totalnumberbathrooms',
  Numberbedrooms = 'numberbedrooms',
  Floordesc = 'floordesc',
  Netrentpmres = 'netrentpmres',
  Utilitiespmres = 'utilitiespmres',
  Totalrentpm = 'totalrentpm',
  Netrentyear = 'netrentyear',
  Utilitiesyear = 'utilitiesyear',
  Commissionamount = 'commissionamount',
  Numberparkingbays = 'numberparkingbays',
  Numbergarages = 'numbergarages',
  Yearconstruction = 'yearconstruction',
  Ownership = 'ownership',
  Terracesize = 'terracesize',
  Rooftopterracesize = 'rooftopterracesize',
  Numberstorieswithoutunits = 'numberstorieswithoutunits',
  Propertyavailablefromtext = 'propertyavailablefromtext',
  Coverpagetext = 'coverpagetext',
  Energyefficiencyrating = 'energyefficiencyrating',
  Ipekwh = 'ipekwh',
  Hwb = 'hwb',
  Fgee = 'fgee',
  Energyinfoes = 'energyinfoes',
  Energyinfobe = 'energyinfobe',
  Energyinfobeflanders = 'energyinfobeflanders',
  Vatamount = 'vatamount'
}

export enum Exfldsreslettinghouse {
  Surfacelivinarea = 'surfacelivinarea',
  Totalsurfaceres = 'totalsurfaceres',
  Plotsurface = 'plotsurface',
  Usablesurface = 'usablesurface',
  Numberlivingareas = 'numberlivingareas',
  Totalnumberbathrooms = 'totalnumberbathrooms',
  Numberbedrooms = 'numberbedrooms',
  Netrentpmres = 'netrentpmres',
  Utilitiespmres = 'utilitiespmres',
  Totalrentpm = 'totalrentpm',
  Netrentyear = 'netrentyear',
  Utilitiesyear = 'utilitiesyear',
  Yearconstruction = 'yearconstruction',
  Commissionamount = 'commissionamount',
  Ownership = 'ownership',
  Terracesize = 'terracesize',
  Rooftopterracesize = 'rooftopterracesize',
  Numbergarages = 'numbergarages',
  Numberparkingbays = 'numberparkingbays',
  Numberstorieswithoutunits = 'numberstorieswithoutunits',
  Propertyavailablefromtext = 'propertyavailablefromtext',
  Coverpagetext = 'coverpagetext',
  Energyefficiencyrating = 'energyefficiencyrating',
  Ipekwh = 'ipekwh',
  Hwb = 'hwb',
  Fgee = 'fgee',
  Energyinfoes = 'energyinfoes',
  Energyinfobe = 'energyinfobe',
  Energyinfobeflanders = 'energyinfobeflanders',
  Vatamount = 'vatamount'
}

export enum Exfldsressalesapartment {
  Surfacelivinarea = 'surfacelivinarea',
  Usablesurface = 'usablesurface',
  Totalsurfaceres = 'totalsurfaceres',
  Numberlivingareas = 'numberlivingareas',
  Totalnumberbathrooms = 'totalnumberbathrooms',
  Numberbedrooms = 'numberbedrooms',
  Floordesc = 'floordesc',
  Askingprice = 'askingprice',
  Numberparkingbays = 'numberparkingbays',
  Numbergarages = 'numbergarages',
  Yearconstruction = 'yearconstruction',
  Ownership = 'ownership',
  Terracesize = 'terracesize',
  Rooftopterracesize = 'rooftopterracesize',
  Numberstorieswithoutunits = 'numberstorieswithoutunits',
  Propertyavailablefromtext = 'propertyavailablefromtext',
  Coverpagetext = 'coverpagetext',
  Energyefficiencyrating = 'energyefficiencyrating',
  Ipekwh = 'ipekwh',
  Hwb = 'hwb',
  Fgee = 'fgee',
  Energyinfoes = 'energyinfoes',
  Energyinfobe = 'energyinfobe',
  Energyinfobeflanders = 'energyinfobeflanders',
  Servantsquarter = 'servantsquarter',
  Additionalflatattenands = 'additionalflatattenands',
  Laundryroom = 'laundryroom'
}

export enum Exfldsressaleshouse {
  Surfacelivinarea = 'surfacelivinarea',
  Totalsurfaceres = 'totalsurfaceres',
  Plotsurface = 'plotsurface',
  Usablesurface = 'usablesurface',
  Numberlivingareas = 'numberlivingareas',
  Totalnumberbathrooms = 'totalnumberbathrooms',
  Numberbedrooms = 'numberbedrooms',
  Askingprice = 'askingprice',
  Yearconstruction = 'yearconstruction',
  Ownership = 'ownership',
  Terracesize = 'terracesize',
  Rooftopterracesize = 'rooftopterracesize',
  Numbergarages = 'numbergarages',
  Numberparkingbays = 'numberparkingbays',
  Numberstorieswithoutunits = 'numberstorieswithoutunits',
  Propertyavailablefromtext = 'propertyavailablefromtext',
  Coverpagetext = 'coverpagetext',
  Energyefficiencyrating = 'energyefficiencyrating',
  Ipekwh = 'ipekwh',
  Hwb = 'hwb',
  Fgee = 'fgee',
  Energyinfoes = 'energyinfoes',
  Energyinfobe = 'energyinfobe',
  Energyinfobeflanders = 'energyinfobeflanders',
  Servantsquarter = 'servantsquarter',
  Additionalflatattenands = 'additionalflatattenands',
  Laundryroom = 'laundryroom'
}

export enum Exfldsressalesland {
  Plotsurface = 'plotsurface',
  Askingprice = 'askingprice',
  Orientation = 'orientation',
  Totalfloorsurface = 'totalfloorsurface',
  Typeusage = 'typeusage',
  Maxallowedfullfloors = 'maxallowedfullfloors',
  Ownership = 'ownership',
  Propertyavailablefromtext = 'propertyavailablefromtext',
  Coverpagetext = 'coverpagetext',
  Energyefficiencyrating = 'energyefficiencyrating',
  Ipekwh = 'ipekwh',
  Energyinfoes = 'energyinfoes',
  Energyinfobe = 'energyinfobe',
  Energyinfobeflanders = 'energyinfobeflanders'
}

export enum ExposeeDelivery {
  Preview = 'preview',
  Persist = 'persist'
}

export enum ExposeeField {
  Blank = 'blank',
  Coticode = 'coticode',
  Subdividable = 'subdividable',
  StandardGroundValue = 'standard_ground_value',
  LandTax = 'land_tax',
  EstimationOfConnectivityValue = 'estimation_of_connectivity_value',
  Orientation = 'orientation',
  LandGuidelines = 'land_guidelines',
  AllowedBuildingSurface = 'allowed_building_surface',
  TypeOfUsage = 'type_of_usage',
  TypeOfGround = 'type_of_ground',
  ErfCoverage = 'erf_coverage',
  BuildingLines = 'building_lines',
  LegallyBindingLandUsePlan = 'legally_binding_land_use_plan',
  MaxAllowedFullFloors = 'max_allowed_full_floors',
  MaxBuildingHeight = 'max_building_height',
  RoofGradient = 'roof_gradient',
  TypeOfRoof = 'type_of_roof',
  SiteOcccupancyIndexFrom = 'site_occcupancy_index_from',
  SiteOcccupancyIndexTo = 'site_occcupancy_index_to',
  FloorSpaceIndexFrom = 'floor_space_index_from',
  FloorSpaceIndexTo = 'floor_space_index_to',
  ErfCoverage1 = 'erf_coverage1',
  LeaseBegin = 'lease_begin',
  CommissionMmnet = 'commission_mmnet',
  CommissionAmount = 'commission_amount',
  CommissionType = 'commission_type',
  MonthlyNetRent = 'monthly_net_rent',
  MonthlyUtilities = 'monthly_utilities',
  MonthlyTotalRent = 'monthly_total_rent',
  YearlyNetRent = 'yearly_net_rent',
  YearlyUtilities = 'yearly_utilities',
  Utilities = 'utilities',
  DepositValueMonthlyRent = 'deposit_value_monthly_rent',
  Premium = 'premium',
  RentalPeriod = 'rental_period',
  WaterRates = 'water_rates',
  MonthlyHeatingCosts = 'monthly_heating_costs',
  HeatingCostsIncludedInMonthlyUtilities = 'heating_costs_included_in_monthly_utilities',
  Vat = 'vat',
  PropertyAvailableFrom = 'property_available_from',
  Ownership = 'ownership',
  PropertyAvailableFromText = 'property_available_from_text',
  RooftopTerraceSize = 'rooftop_terrace_size',
  SurfaceGarden = 'surface_garden',
  ServantsQuarter = 'servants_quarter',
  IndependentStudioFlat = 'independent_studio_flat',
  Hwb = 'hwb',
  IpeKwh = 'ipe_kwh',
  Fgee = 'fgee',
  CoverPageText = 'cover_page_text',
  TotalEnergyEfficiencyFactor = 'total_energy_efficiency_factor',
  TotalSurfaces = 'total_surfaces',
  SurfaceLivingArea = 'surface_living_area',
  UsableSurface = 'usable_surface',
  Garden = 'garden',
  PlotSurface = 'plot_surface',
  NumberOfRooms = 'number_of_rooms',
  TotalNumberOfBathrooms = 'total_number_of_bathrooms',
  NumberOfMasterBathrooms = 'number_of_master_bathrooms',
  NumberOfBedrooms = 'number_of_bedrooms',
  Basement = 'basement',
  AskingPrice = 'asking_price',
  TotalNumberOfGarages = 'total_number_of_garages',
  YearOfConstruction = 'year_of_construction',
  Balcony = 'balcony',
  RooftopTerrace = 'rooftop_terrace',
  Terrace = 'terrace',
  TerraceSize = 'terrace_size',
  Fireplace = 'fireplace',
  Flooring = 'flooring',
  Heating = 'heating',
  Heatingres = 'heatingres',
  AirCondition = 'air_condition',
  NumberOfStories = 'number_of_stories',
  TotalNumberOfParkingBays = 'total_number_of_parking_bays',
  Floor = 'floor',
  Views = 'views',
  LastModernisationOrRestoration = 'last_modernisation_or_restoration',
  Elevator = 'elevator',
  PricePerParkingBay = 'price_per_parking_bay',
  PricePerGarage = 'price_per_garage',
  MonthlyRentPerParkingBay = 'monthly_rent_per_parking_bay',
  MonthlyRentPerGarage = 'monthly_rent_per_garage',
  HotWaterEnergyIncluded = 'hot_water_energy_included',
  LaundryRoom = 'laundry_room',
  PropertyStreetAndHouseNumber = 'property_street_and_house_number',
  PropertyCity = 'property_city',
  PropertyType = 'property_type',
  HousingBenefits = 'housing_benefits',
  MaintainanceFee = 'maintainance_fee',
  MarketingHeadline = 'marketing_headline',
  MarketingPropertyDescription = 'marketing_property_description',
  MarketingLocationDescription = 'marketing_location_description',
  PropertyUtag = 'property_utag',
  ListingUtag = 'listing_utag',
  FloorPlanTitle = 'floor_plan_title',
  LandRegisterMapTitle = 'land_register_map_title',
  SitePlanTitle = 'site_plan_title',
  FinalEnergyConsumption = 'final_energy_consumption',
  FinalEnergyDemand = 'final_energy_demand',
  EnergyConsumption = 'energy_consumption',
  TypeOfEnergyCertificate = 'type_of_energy_certificate',
  EnergyEfficiencyRating = 'energy_efficiency_rating',
  EnergyEfficiencyRatingFull = 'energy_efficiency_rating_full',
  EnergyInformationSpain = 'energy_information_spain',
  EnergyInformationBelgium = 'energy_information_belgium',
  EnergyInformationBelgiumFlanders = 'energy_information_belgium_flanders',
  QcEnergy = 'qc_energy',
  QcEnergyType = 'qc_energy_type',
  QcEnergyYn = 'qc_energy_yn',
  QcEnergyHotWater = 'qc_energy_hot_water',
  AdditionalFlatAttendants = 'additional_flat_attendants',
  PropertyTitle = 'property_title',
  PropertyHouseNumberAndStreet = 'property_house_number_and_street',
  PropertyStreet = 'property_street',
  PropertyPostalCodeAndCity = 'property_postal_code_and_city',
  PropertyDistrictParentDisplayName = 'property_district_parent_display_name',
  PropertyDistrictDisplayName = 'property_district_display_name',
  CoverLetterAddress = 'cover_letter_address',
  AddressTowerName = 'address_tower_name',
  AddressPropertyName = 'address_property_name',
  PropertyApartmentNumber = 'property_apartment_number',
  AddressCountry = 'address_country',
  AddressRegion = 'address_region'
}

export type ExposeeRequest = {
  source_type: ExposeeSourceType;
  template: ExposeeTemplate;
  label_language: Array<Language>;
  marketing_langauge: Array<Language2>;
  photo_language: Array<PhotoTitleLanguage>;
  agent_utag: Scalars['String'];
  source_utag: Scalars['String'];
  contacts_utags?: Maybe<Array<Scalars['String']>>;
  delivery: ExposeeDelivery;
  widerruf: Scalars['Boolean'];
  interhyp: Scalars['Boolean'];
  refresh: Scalars['Boolean'];
};

export enum ExposeeSourceType {
  PropertySale = 'property_sale',
  PropertyLetting = 'property_letting',
  Commercial = 'commercial'
}

export enum ExposeeTemplate {
  Exposee = 'exposee',
  Shop = 'shop'
}

export enum Favouredwayofcontact {
  Phoneprivate = 'phoneprivate',
  Phonebusiness = 'phonebusiness',
  Mobilephone = 'mobilephone',
  Mail = 'mail',
  Fax = 'fax',
  Email = 'email'
}

export type Flag = {
  __typename?: 'Flag';
  key: Scalars['Int'];
  utag: Scalars['String'];
  flagname: Scalars['Text'];
  flagdesc: Scalars['NullNotes'];
};

export enum Flagcategory {
  Contact = 'contact',
  Property = 'property'
}

export type FloatRange = {
  __typename?: 'FloatRange';
  Min: Scalars['Float'];
  Max: Scalars['Float'];
};

export type FloatRangeInput = {
  Min?: Maybe<Scalars['Float']>;
  Max?: Maybe<Scalars['Float']>;
};

export enum Floodzones {
  Recognisedflood = 'recognisedflood',
  Locatedrecognisedflood = 'locatedrecognisedflood',
  Locatedcircumscribedwaterside = 'locatedcircumscribedwaterside',
  Possibleflood = 'possibleflood',
  Locatedfloodwaterside = 'locatedfloodwaterside',
  Possiblecircumscribedwaterside = 'possiblecircumscribedwaterside',
  Locatedcircumscribedflood = 'locatedcircumscribedflood',
  Circumscribed = 'circumscribed',
  Nonflood = 'nonflood'
}

export enum Gender {
  Male = 'male',
  Female = 'female'
}

export type GenerateExposeeResponse = {
  __typename?: 'GenerateExposeeResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Go3Location = {
  __typename?: 'Go3Location';
  district_key: Scalars['Int'];
  district_name: Scalars['String'];
  area_key: Scalars['Int'];
  area_name: Scalars['String'];
  region_key: Scalars['Int'];
  region_name: Scalars['String'];
  country_key: Scalars['Int'];
  country_Name: Scalars['String'];
};

export enum Googleappname {
  Go3 = 'go3',
  Go3lab = 'go3lab'
}

export type ImageResize = {
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export type IntRangeInput = {
  Min?: Maybe<Scalars['Int']>;
  Max?: Maybe<Scalars['Int']>;
};

export enum Landbasicdata {
  Ownership = 'ownership',
  Coticode = 'coticode',
  Plotsurface = 'plotsurface',
  Subdividable = 'subdividable',
  Askingprice = 'askingprice',
  Standardgroundvalue = 'standardgroundvalue',
  Landtax = 'landtax',
  Estimationconectivityvalue = 'estimationconectivityvalue',
  Orientation = 'orientation',
  Landguidelines = 'landguidelines',
  Totalfloorsurface = 'totalfloorsurface',
  Typeusage = 'typeusage',
  Typeground = 'typeground',
  Erfcoverage = 'erfcoverage',
  Buildinglines = 'buildinglines',
  Legallyindinglanduseplan = 'legallyindinglanduseplan',
  Maxallowedfullfloors = 'maxallowedfullfloors',
  Maxbuildingheight = 'maxbuildingheight',
  Roofgradient = 'roofgradient',
  Typeroof = 'typeroof',
  Utilities = 'utilities',
  Propertyavailablefromtext = 'propertyavailablefromtext',
  Siteoccupancyindexfrom = 'siteoccupancyindexfrom',
  Siteoccupancyindexto = 'siteoccupancyindexto',
  Floorspaceindexfrom = 'floorspaceindexfrom',
  Floorspaceindexto = 'floorspaceindexto',
  Erfcoverage_1 = 'erfcoverage_1',
  Propresviews = 'propresviews'
}

export enum Language {
  Caes = 'caes',
  Meme = 'meme',
  Dede = 'dede',
  Dech = 'dech',
  Deat = 'deat',
  Enau = 'enau',
  Enhk = 'enhk',
  Enme = 'enme',
  Enth = 'enth',
  Engb = 'engb',
  Eses = 'eses',
  Essa = 'essa',
  Frbe = 'frbe',
  Frfr = 'frfr',
  Hrhr = 'hrhr',
  Itit = 'itit',
  Huhu = 'huhu',
  Nlnl = 'nlnl',
  Plpl = 'plpl',
  Ptpt = 'ptpt',
  Roro = 'roro',
  Fifi = 'fifi',
  Svsv = 'svsv',
  Trtr = 'trtr',
  Bgbg = 'bgbg',
  Czcz = 'czcz',
  Elgr = 'elgr',
  Ruru = 'ruru',
  Zhcn = 'zhcn',
  Zhtw = 'zhtw'
}

export enum Language2 {
  German = 'german',
  English = 'english',
  Frenchfrance = 'frenchfrance',
  Frenchbelgium = 'frenchbelgium',
  Italian = 'italian',
  Greek = 'greek',
  Hungarian = 'hungarian',
  Spanish = 'spanish',
  Portuguese = 'portuguese',
  Dutch = 'dutch',
  Czech = 'czech',
  Catalan = 'catalan',
  Chinesecn = 'chinesecn',
  Chinesetw = 'chinesetw',
  Turkish = 'turkish',
  Russian = 'russian',
  Danish = 'danish',
  Flemish = 'flemish',
  Croatian = 'croatian',
  Serbian = 'serbian',
  Montenegrin = 'montenegrin',
  Polish = 'polish',
  Ukrainian = 'ukrainian',
  Bulgarian = 'bulgarian',
  Swedish = 'swedish',
  Finnish = 'finnish',
  Norwegian = 'norwegian',
  Romanian = 'romanian',
  Estonian = 'estonian',
  Latvian = 'latvian',
  Lithuanian = 'lithuanian',
  Cantonese = 'cantonese',
  Thai = 'thai'
}

export enum LanguageCodeA2 {
  Aa = 'AA',
  Ab = 'AB',
  Af = 'AF',
  Ak = 'AK',
  Sq = 'SQ',
  Am = 'AM',
  Ar = 'AR',
  An = 'AN',
  Hy = 'HY',
  As = 'AS',
  Av = 'AV',
  Ae = 'AE',
  Ay = 'AY',
  Az = 'AZ',
  Ba = 'BA',
  Bm = 'BM',
  Eu = 'EU',
  Be = 'BE',
  Bn = 'BN',
  Bh = 'BH',
  Bi = 'BI',
  Bs = 'BS',
  Br = 'BR',
  Bg = 'BG',
  My = 'MY',
  Ca = 'CA',
  Ch = 'CH',
  Ce = 'CE',
  Zh = 'ZH',
  Cu = 'CU',
  Cv = 'CV',
  Kw = 'KW',
  Co = 'CO',
  Cr = 'CR',
  Cs = 'CS',
  Da = 'DA',
  Dv = 'DV',
  Nl = 'NL',
  Dz = 'DZ',
  En = 'EN',
  Eo = 'EO',
  Et = 'ET',
  Ee = 'EE',
  Fo = 'FO',
  Fj = 'FJ',
  Fi = 'FI',
  Fr = 'FR',
  Fy = 'FY',
  Ff = 'FF',
  Ka = 'KA',
  De = 'DE',
  Gd = 'GD',
  Ga = 'GA',
  Gl = 'GL',
  Gv = 'GV',
  El = 'EL',
  Gn = 'GN',
  Gu = 'GU',
  Ht = 'HT',
  Ha = 'HA',
  He = 'HE',
  Hz = 'HZ',
  Hi = 'HI',
  Ho = 'HO',
  Hr = 'HR',
  Hu = 'HU',
  Ig = 'IG',
  Is = 'IS',
  Io = 'IO',
  Ii = 'II',
  Iu = 'IU',
  Ie = 'IE',
  Ia = 'IA',
  Id = 'ID',
  Ik = 'IK',
  It = 'IT',
  Jv = 'JV',
  Ja = 'JA',
  Kl = 'KL',
  Kn = 'KN',
  Ks = 'KS',
  Kr = 'KR',
  Kk = 'KK',
  Km = 'KM',
  Ki = 'KI',
  Rw = 'RW',
  Ky = 'KY',
  Kv = 'KV',
  Kg = 'KG',
  Ko = 'KO',
  Kj = 'KJ',
  Ku = 'KU',
  Lo = 'LO',
  La = 'LA',
  Lv = 'LV',
  Li = 'LI',
  Ln = 'LN',
  Lt = 'LT',
  Lb = 'LB',
  Lu = 'LU',
  Lg = 'LG',
  Mk = 'MK',
  Mh = 'MH',
  Ml = 'ML',
  Mi = 'MI',
  Mr = 'MR',
  Ms = 'MS',
  Mg = 'MG',
  Mt = 'MT',
  Mn = 'MN',
  Na = 'NA',
  Nv = 'NV',
  Nr = 'NR',
  Nd = 'ND',
  Ng = 'NG',
  Ne = 'NE',
  Nn = 'NN',
  Nb = 'NB',
  No = 'NO',
  Ny = 'NY',
  Oc = 'OC',
  Oj = 'OJ',
  Or = 'OR',
  Om = 'OM',
  Os = 'OS',
  Pa = 'PA',
  Fa = 'FA',
  Pi = 'PI',
  Pl = 'PL',
  Pt = 'PT',
  Ps = 'PS',
  Qu = 'QU',
  Rm = 'RM',
  Ro = 'RO',
  Rn = 'RN',
  Ru = 'RU',
  Sg = 'SG',
  Sa = 'SA',
  Si = 'SI',
  Sk = 'SK',
  Sl = 'SL',
  Se = 'SE',
  Sm = 'SM',
  Sn = 'SN',
  Sd = 'SD',
  So = 'SO',
  St = 'ST',
  Es = 'ES',
  Sc = 'SC',
  Sr = 'SR',
  Ss = 'SS',
  Su = 'SU',
  Sw = 'SW',
  Sv = 'SV',
  Ty = 'TY',
  Ta = 'TA',
  Tt = 'TT',
  Te = 'TE',
  Tg = 'TG',
  Tl = 'TL',
  Th = 'TH',
  Bo = 'BO',
  Ti = 'TI',
  To = 'TO',
  Tn = 'TN',
  Ts = 'TS',
  Tk = 'TK',
  Tr = 'TR',
  Tw = 'TW',
  Ug = 'UG',
  Uk = 'UK',
  Ur = 'UR',
  Uz = 'UZ',
  Ve = 'VE',
  Vi = 'VI',
  Vo = 'VO',
  Cy = 'CY',
  Wa = 'WA',
  Wo = 'WO',
  Xh = 'XH',
  Yi = 'YI',
  Yo = 'YO',
  Za = 'ZA',
  Zu = 'ZU'
}

export enum Latestlandusedesignation {
  Livingarearesidentialurbanorru = 'livingarearesidentialurbanorru',
  Mixedlivingareaaccommodationec = 'mixedlivingareaaccommodationec',
  Economicactivityareashopsoffic = 'economicactivityareashopsoffic',
  Industrialarea = 'industrialarea',
  Leisurearea = 'leisurearea',
  Greenarea = 'greenarea',
  Forestarea = 'forestarea',
  Farmingarea = 'farmingarea',
  Reservedarea = 'reservedarea',
  Extractionarea = 'extractionarea',
  Parkarea = 'parkarea',
  Naturalarea = 'naturalarea',
  Anothertypeofareaspecifyinthef = 'anothertypeofareaspecifyinthef',
  Residentialareawithculturalhis = 'residentialareawithculturalhis',
  Residentialpark = 'residentialpark',
  Extendedresidentialarea = 'extendedresidentialarea',
  Industrialcommercialarea = 'industrialcommercialarea',
  Residentialrecreationarea = 'residentialrecreationarea',
  Residentialrecreationarea_1 = 'residentialrecreationarea_1',
  Areaoflandscapeinterest = 'areaoflandscapeinterest',
  Naturereserve = 'naturereserve'
}

export enum Laundryroom {
  Privateinsideappartment = 'privateinsideappartment',
  Privateoutsideappartment = 'privateoutsideappartment',
  Shared = 'shared'
}

export enum Ldapserverip {
  Ev2003 = 'ev2003',
  Ev2010 = 'ev2010',
  Evlab = 'evlab',
  Janz2010 = 'janz2010'
}

export enum LettingApartmentbasicdata {
  Ownership = 'ownership',
  Coticode = 'coticode',
  Propertyavailablefrom = 'propertyavailablefrom',
  Leasebegin = 'leasebegin',
  Commissionmmnet = 'commissionmmnet',
  Commissiontype = 'commissiontype',
  Totalsurfaceres = 'totalsurfaceres',
  Surfacelivinarea = 'surfacelivinarea',
  Usablesurface = 'usablesurface',
  Numberlivingareas = 'numberlivingareas',
  Totalnumberbathrooms = 'totalnumberbathrooms',
  Ofwhichnumberofmasterbathrooms = 'ofwhichnumberofmasterbathrooms',
  Numberbedrooms = 'numberbedrooms',
  Basement = 'basement',
  Floordesc = 'floordesc',
  Netrentpmres = 'netrentpmres',
  Utilitiespmres = 'utilitiespmres',
  Totalrentpm = 'totalrentpm',
  Netrentyear = 'netrentyear',
  Utilitiesyear = 'utilitiesyear',
  Depositvaluemonthlyrent = 'depositvaluemonthlyrent',
  Premium = 'premium',
  Rentalperiod = 'rentalperiod',
  Waterrates = 'waterrates',
  Numbergarages = 'numbergarages',
  Yearconstruction = 'yearconstruction',
  Balcony = 'balcony',
  Rooftopterrace = 'rooftopterrace',
  Rooftopterracesize = 'rooftopterracesize',
  Garden = 'garden',
  Surfacegarden = 'surfacegarden',
  Terrace = 'terrace',
  Terracesize = 'terracesize',
  Fireplace = 'fireplace',
  Flooring = 'flooring',
  Heating = 'heating',
  Heatingres = 'heatingres',
  Airconditionres = 'airconditionres',
  Numberparkingbays = 'numberparkingbays',
  Numberstorieswithoutunits = 'numberstorieswithoutunits',
  Propertyavailablefromtext = 'propertyavailablefromtext',
  Floordesc_1 = 'floordesc_1',
  Propresviews = 'propresviews',
  Lastmodernisationrestoration = 'lastmodernisationrestoration',
  Elevator = 'elevator',
  Parkingbayprice = 'parkingbayprice',
  Garageprice = 'garageprice',
  Monthlyrentparkingbay = 'monthlyrentparkingbay',
  Monthlyrentgarage = 'monthlyrentgarage',
  Monthlyheatingcosts = 'monthlyheatingcosts',
  Heatingcostsincludedmu = 'heatingcostsincludedmu',
  Hwb = 'hwb',
  Fgee = 'fgee',
  Qcenergyhotwater = 'qcenergyhotwater',
  Qcenergytype = 'qcenergytype',
  Qcenergy = 'qcenergy',
  Finalenergyconsumption = 'finalenergyconsumption',
  Finalenergydemand = 'finalenergydemand',
  Energyefficiencyrating = 'energyefficiencyrating',
  Vatamount = 'vatamount'
}

export enum LettingHousebasicdata {
  Ownership = 'ownership',
  Coticode = 'coticode',
  Propertyavailablefrom = 'propertyavailablefrom',
  Leasebegin = 'leasebegin',
  Commissionmmnet = 'commissionmmnet',
  Commissiontype = 'commissiontype',
  Totalsurfaceres = 'totalsurfaceres',
  Surfacelivinarea = 'surfacelivinarea',
  Usablesurface = 'usablesurface',
  Plotsurface = 'plotsurface',
  Numberlivingareas = 'numberlivingareas',
  Totalnumberbathrooms = 'totalnumberbathrooms',
  Ofwhichnumberofmasterbathrooms = 'ofwhichnumberofmasterbathrooms',
  Numberbedrooms = 'numberbedrooms',
  Basement = 'basement',
  Netrentpmres = 'netrentpmres',
  Utilitiespmres = 'utilitiespmres',
  Totalrentpm = 'totalrentpm',
  Netrentyear = 'netrentyear',
  Utilitiesyear = 'utilitiesyear',
  Depositvaluemonthlyrent = 'depositvaluemonthlyrent',
  Premium = 'premium',
  Rentalperiod = 'rentalperiod',
  Waterrates = 'waterrates',
  Numbergarages = 'numbergarages',
  Yearconstruction = 'yearconstruction',
  Balcony = 'balcony',
  Rooftopterrace = 'rooftopterrace',
  Rooftopterracesize = 'rooftopterracesize',
  Garden = 'garden',
  Surfacegarden = 'surfacegarden',
  Terrace = 'terrace',
  Terracesize = 'terracesize',
  Fireplace = 'fireplace',
  Flooring = 'flooring',
  Heating = 'heating',
  Heatingres = 'heatingres',
  Airconditionres = 'airconditionres',
  Numberparkingbays = 'numberparkingbays',
  Numberstorieswithoutunits = 'numberstorieswithoutunits',
  Propertyavailablefromtext = 'propertyavailablefromtext',
  Floordesc = 'floordesc',
  Propresviews = 'propresviews',
  Lastmodernisationrestoration = 'lastmodernisationrestoration',
  Elevator = 'elevator',
  Parkingbayprice = 'parkingbayprice',
  Garageprice = 'garageprice',
  Monthlyrentparkingbay = 'monthlyrentparkingbay',
  Monthlyrentgarage = 'monthlyrentgarage',
  Monthlyheatingcosts = 'monthlyheatingcosts',
  Heatingcostsincludedmu = 'heatingcostsincludedmu',
  Hwb = 'hwb',
  Fgee = 'fgee',
  Qcenergyhotwater = 'qcenergyhotwater',
  Qcenergytype = 'qcenergytype',
  Qcenergy = 'qcenergy',
  Finalenergyconsumption = 'finalenergyconsumption',
  Finalenergydemand = 'finalenergydemand',
  Energyefficiencyrating = 'energyefficiencyrating',
  Vatamount = 'vatamount'
}

export enum LettingPropertystatus {
  Active = 'active',
  Pending = 'pending',
  Offershow = 'offershow',
  Offernoshow = 'offernoshow',
  Letbyev = 'letbyev',
  Letbyothers = 'letbyothers',
  Withdrawn = 'withdrawn',
  Expired = 'expired',
  Cancelled = 'cancelled',
  Compprospect = 'compprospect',
  Inactive = 'inactive'
}

export enum LettingPropertytype {
  Apartment = 'apartment',
  House = 'house'
}

export enum Locale {
  EnHk = 'en_hk',
  DeAt = 'de_at',
  FiFi = 'fi_fi',
  HuHu = 'hu_hu',
  ItIt = 'it_it',
  DeCh = 'de_ch',
  SrMe = 'sr_me',
  DeDe = 'de_de',
  PtPt = 'pt_pt',
  CaEs = 'ca_es',
  HrHr = 'hr_hr',
  TrTr = 'tr_tr',
  ZhCn = 'zh_cn',
  EnGb = 'en_gb',
  EnAu = 'en_au',
  EsMx = 'es_mx',
  ElGr = 'el_gr',
  FrFr = 'fr_fr',
  SvSv = 'sv_sv',
  FrBe = 'fr_be',
  EsEs = 'es_es',
  RuRu = 'ru_ru',
  EnTh = 'en_th',
  BgBg = 'bg_bg',
  ZhTw = 'zh_tw',
  PlPl = 'pl_pl',
  RoRo = 'ro_ro',
  EnSy = 'en_sy',
  CsCz = 'cs_cz',
  NlNl = 'nl_nl'
}

export enum Location {
  Top = 'top',
  Verygood = 'verygood',
  Good = 'good',
  Average = 'average',
  Other = 'other'
}

export type Login = {
  __typename?: 'Login';
  key: Scalars['Int'];
  utag: Scalars['String'];
  loginname: Scalars['Text'];
  loginstatus: Activestatus;
  emailaddress: Scalars['NullText'];
  loginsurname: Scalars['Text'];
  loginfirstname: Scalars['NullText'];
  agents?: Maybe<Array<Agent>>;
};

export enum Motivecommercial {
  Investor = 'investor',
  Resale = 'resale',
  Developer = 'developer',
  Separator = 'separator',
  Owneroccupant = 'owneroccupant'
}

export enum Motiveresidential {
  Image = 'image',
  Health = 'health',
  Security = 'security',
  Convenience = 'convenience',
  Business = 'business',
  Profit = 'profit',
  Avantgarde = 'avantgarde',
  Socialcommitment = 'socialcommitment'
}

export type Mutation = {
  __typename?: 'Mutation';
  dummy?: Maybe<Scalars['Void']>;
  register_offline_token: RegisterOfflineTokenResponse;
  signin_google: SigninResponse;
  exchange_refresh_token: SigninResponse;
  generate_exposee: GenerateExposeeResponse;
};


export type MutationRegister_Offline_TokenArgs = {
  code: Scalars['String'];
};


export type MutationSignin_GoogleArgs = {
  token: Scalars['String'];
  expire_at?: Maybe<Scalars['Int']>;
};


export type MutationExchange_Refresh_TokenArgs = {
  refresh_token: Scalars['String'];
  expire_at?: Maybe<Scalars['Int']>;
};


export type MutationGenerate_ExposeeArgs = {
  exposee_request?: Maybe<ExposeeRequest>;
};

export enum Newstatus {
  Old = 'old',
  New = 'new'
}











export enum Numberoffacades {
  Numberoffacades_1 = 'numberoffacades_1',
  Numberoffacades_2 = 'numberoffacades_2',
  Numberoffacades_3 = 'numberoffacades_3',
  Numberoffacades_4 = 'numberoffacades_4'
}

export type Office = {
  __typename?: 'Office';
  key: Scalars['Int'];
  foreignKey?: Maybe<Scalars['Int']>;
  officeinternalname: Scalars['Text'];
};

export enum OrderBy {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Orientation {
  North = 'north',
  Northwest = 'northwest',
  West = 'west',
  Southwest = 'southwest',
  South = 'south',
  Southeast = 'southeast',
  East = 'east',
  Northeast = 'northeast'
}

export enum Orientationofthegarden {
  East = 'east',
  North = 'north',
  Northeast = 'northeast',
  Northwest = 'northwest',
  South = 'south',
  Southeast = 'southeast',
  Southwest = 'southwest',
  West = 'west'
}

export enum Ownership {
  Freehold = 'freehold',
  Leasehold = 'leasehold'
}

export type Photo = {
  __typename?: 'Photo';
  url: Scalars['String'];
};

export enum PhotoTitleLanguage {
  De = 'de',
  En = 'en',
  Be = 'be',
  Fr = 'fr',
  It = 'it',
  El = 'el',
  Hu = 'hu',
  Es = 'es',
  Pt = 'pt',
  Nl = 'nl',
  Cz = 'cz',
  Ca = 'ca',
  Cn = 'cn',
  Tw = 'tw',
  Tr = 'tr',
  Ru = 'ru',
  Da = 'da',
  Fl = 'fl',
  Hr = 'hr',
  Rs = 'rs',
  Me = 'me',
  Pl = 'pl',
  Ua = 'ua',
  Bg = 'bg',
  Se = 'se',
  Fi = 'fi',
  No = 'no',
  Ro = 'ro',
  Ee = 'ee',
  Lv = 'lv',
  Lt = 'lt',
  Ct = 'ct',
  Th = 'th'
}

export enum Priority {
  Low = 'low',
  Normal = 'normal',
  High = 'high'
}

export enum Propcomfileasformat {
  Default = 'default',
  Propertyname = 'propertyname',
  Streetname1Streetnumber = 'streetname1_streetnumber',
  StreetnumberStreetname1 = 'streetnumber_streetname1'
}

export enum PropertyType {
  Sales = 'sales',
  Letting = 'letting'
}

export type PropresLettingSearchCriteria = {
  __typename?: 'PropresLettingSearchCriteria';
  type: Array<Scalars['String']>;
  subType: Array<Scalars['String']>;
  linkcdf1Key: Array<Scalars['Ref']>;
  linkcdf1?: Maybe<Array<CustomizedDemand>>;
  linkcdf2Key: Array<Scalars['Ref']>;
  linkcdf2?: Maybe<Array<CustomizedDemand>>;
  areaKeyList: Array<Scalars['Ref']>;
  areaList: Array<Area>;
  districtKeyList: Array<Scalars['Ref']>;
  districtList: Array<District>;
  totalSurfaceres?: Maybe<FloatRange>;
  surfaceLivinArea?: Maybe<FloatRange>;
  plotSurface?: Maybe<FloatRange>;
  numberLivingAreas?: Maybe<FloatRange>;
  numberBedrooms?: Maybe<FloatRange>;
  netRentPmres?: Maybe<FloatRange>;
  totalRentPm?: Maybe<FloatRange>;
  shortTermRental?: Maybe<FloatRange>;
};

export type PropresSalesSearchCriteria = {
  __typename?: 'PropresSalesSearchCriteria';
  type: Array<Scalars['String']>;
  subType: Array<Scalars['String']>;
  linkcdf1Key: Array<Scalars['Ref']>;
  linkcdf1?: Maybe<Array<CustomizedDemand>>;
  linkcdf2Key: Array<Scalars['Ref']>;
  linkcdf2?: Maybe<Array<CustomizedDemand>>;
  areaKeyList: Array<Scalars['Ref']>;
  areaList: Array<Area>;
  districtKeyList: Array<Scalars['Ref']>;
  districtList: Array<District>;
  totalSurfaceres?: Maybe<FloatRange>;
  surfaceLivinArea?: Maybe<FloatRange>;
  plotSurface?: Maybe<FloatRange>;
  numberLivingAreas?: Maybe<FloatRange>;
  numberBedrooms?: Maybe<FloatRange>;
  askingPrice?: Maybe<FloatRange>;
};

export enum Propresfileasformat {
  Default = 'default',
  Propertyname = 'propertyname',
  Villaapartmentnumber = 'villaapartmentnumber',
  Towername = 'towername',
  Streetname1Streetnumber = 'streetname1_streetnumber',
  StreetnumberStreetname1 = 'streetnumber_streetname1'
}

export type Propresletting = {
  __typename?: 'Propresletting';
  key: Scalars['Int'];
  utag: Scalars['String'];
  streetname1: Scalars['NullText'];
  streetnumber: Scalars['NullText'];
  propertyname: Scalars['NullText'];
  postcode: Scalars['NullText'];
  city: Scalars['NullText'];
  area: Scalars['NullRef'];
  area_entity?: Maybe<Area>;
  district: Scalars['NullRef'];
  district_entity?: Maybe<District>;
  listingowner: Scalars['NullRef'];
  listingowner_entity?: Maybe<Contact>;
  netrentpmres: Scalars['Currency'];
  netrentsurfsystpmres: Scalars['Currency'];
  propertytype?: Maybe<LettingPropertytype>;
  propertysubtype: Scalars['PropreslettingSubType'];
  propertysubtypeValue: Scalars['String'];
  totalsurfaceres: Scalars['NullFloat2'];
  surfacelivinarea: Scalars['NullInt'];
  plotsurface: Scalars['NullInt'];
  floordesc: Scalars['NullText'];
  balcony: Scalars['NullYN'];
  garden: Scalars['NullYN'];
  numberlivingareas: Scalars['NullFloat1'];
  numberbedrooms: Scalars['NullInt'];
  totalnumberbathrooms: Scalars['NullInt'];
  yearconstruction: Scalars['NullYear'];
  propertystatus: LettingPropertystatus;
  owner_agent_key: Scalars['Int'];
  createdfor?: Maybe<Agent>;
  lastaction: Scalars['NullDate'];
  typeofmandate: Typeofmandate;
  mandateto: Scalars['NullDate'];
  commissionpercent: Scalars['NullFloat2'];
  commissionpercentowner: Scalars['NullFloat2'];
  sign: Scalars['NullYN'];
  keyonshop: Scalars['NullYN'];
  keylabel: Scalars['NullText'];
  envsource: Envsource;
  photoset: Array<Maybe<Photo>>;
  commentsbasicdata: Scalars['NullNotes'];
};

export type PropreslettingQueryOrder = {
  utag?: Maybe<OrderBy>;
  propertytype?: Maybe<OrderBy>;
  propertystatus?: Maybe<OrderBy>;
  streetname1?: Maybe<OrderBy>;
  streetnumber?: Maybe<OrderBy>;
  postcode?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  area?: Maybe<OrderBy>;
  district?: Maybe<OrderBy>;
  netrentpmres?: Maybe<OrderBy>;
  netrentsurfsystpmres?: Maybe<OrderBy>;
  propertyname?: Maybe<OrderBy>;
  surfacelivinarea?: Maybe<OrderBy>;
  plotsurface?: Maybe<OrderBy>;
  balcony?: Maybe<OrderBy>;
  garden?: Maybe<OrderBy>;
  numberbedrooms?: Maybe<OrderBy>;
  sign?: Maybe<OrderBy>;
  keyonshop?: Maybe<OrderBy>;
};

export type PropreslettingQueryResponse = {
  __typename?: 'PropreslettingQueryResponse';
  rows?: Maybe<Array<Propresletting>>;
};

export type PropreslettingQueryWhere = {
  utag?: Maybe<Scalars['String']>;
  createdfor?: Maybe<Scalars['String']>;
  propertytype?: Maybe<LettingPropertytype>;
  propertystatus?: Maybe<LettingPropertystatus>;
  streetname1?: Maybe<Scalars['String']>;
  streetnumber?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  netrentpmres?: Maybe<FloatRangeInput>;
  netrentsurfsystpmres?: Maybe<FloatRangeInput>;
  propertyname?: Maybe<Scalars['String']>;
  surfacelivinarea?: Maybe<IntRangeInput>;
  plotsurface?: Maybe<IntRangeInput>;
  balcony?: Maybe<Scalars['NullYN']>;
  garden?: Maybe<Scalars['NullYN']>;
  numberbedrooms?: Maybe<Scalars['Int']>;
  sign?: Maybe<Scalars['NullYN']>;
  keyonshop?: Maybe<Scalars['NullYN']>;
};


export type Propressale = {
  __typename?: 'Propressale';
  key: Scalars['Int'];
  utag: Scalars['String'];
  streetname1: Scalars['NullText'];
  streetnumber: Scalars['NullText'];
  propertyname: Scalars['NullText'];
  postcode: Scalars['NullText'];
  city: Scalars['NullText'];
  area: Scalars['NullRef'];
  area_entity?: Maybe<Area>;
  district: Scalars['NullRef'];
  district_entity?: Maybe<District>;
  listingowner: Scalars['NullRef'];
  listingowner_entity?: Maybe<Contact>;
  askingprice: Scalars['Currency'];
  askingpricesurfsystres: Scalars['Currency'];
  propertytype?: Maybe<SalesPropertytype>;
  propertysubtype: Scalars['PropressaleSubType'];
  propertysubtypeValue: Scalars['String'];
  totalsurfaceres: Scalars['NullFloat2'];
  surfacelivinarea: Scalars['NullInt'];
  plotsurface: Scalars['NullInt'];
  floordesc: Scalars['NullText'];
  numberlivingareas: Scalars['NullFloat1'];
  numberbedrooms: Scalars['NullInt'];
  totalnumberbathrooms: Scalars['NullInt'];
  yearconstruction: Scalars['NullYear'];
  plotprice: Scalars['NullFloat2'];
  propertystatus: SalesPropertystatus;
  owner_agent_key: Scalars['Int'];
  createdfor?: Maybe<Agent>;
  lastaction: Scalars['NullDate'];
  typeofmandate: Typeofmandate;
  mandateto: Scalars['NullDate'];
  commissionpercent: Scalars['NullFloat2'];
  commissionpercentowner: Scalars['NullFloat2'];
  sign: Scalars['NullYN'];
  keyonshop: Scalars['NullYN'];
  keylabel: Scalars['NullText'];
  envsource: Envsource;
  photoset: Array<Maybe<Photo>>;
  commentsbasicdata: Scalars['NullNotes'];
  pslinkflags: Array<Scalars['Ref']>;
  pslinkflag_entities?: Maybe<Array<Maybe<Flag>>>;
};

export type PropressaleQueryOrder = {
  utag?: Maybe<OrderBy>;
  propertytype?: Maybe<OrderBy>;
  propertystatus?: Maybe<OrderBy>;
  streetname1?: Maybe<OrderBy>;
  streetnumber?: Maybe<OrderBy>;
  postcode?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  area?: Maybe<OrderBy>;
  district?: Maybe<OrderBy>;
  propertyname?: Maybe<OrderBy>;
  surfacelivinarea?: Maybe<OrderBy>;
  plotsurface?: Maybe<OrderBy>;
  numberbedrooms?: Maybe<OrderBy>;
  askingprice?: Maybe<OrderBy>;
  sign?: Maybe<OrderBy>;
  keyonshop?: Maybe<OrderBy>;
  pslinkflags?: Maybe<OrderBy>;
};

export type PropressaleQueryResponse = {
  __typename?: 'PropressaleQueryResponse';
  rows?: Maybe<Array<Propressale>>;
};

export type PropressaleQueryWhere = {
  utag?: Maybe<Scalars['String']>;
  createdfor?: Maybe<Scalars['String']>;
  propertytype?: Maybe<SalesPropertytype>;
  propertystatus?: Maybe<SalesPropertystatus>;
  streetname1?: Maybe<Scalars['String']>;
  streetnumber?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  propertyname?: Maybe<Scalars['String']>;
  surfacelivinarea?: Maybe<IntRangeInput>;
  plotsurface?: Maybe<IntRangeInput>;
  numberbedrooms?: Maybe<Scalars['Int']>;
  askingprice?: Maybe<FloatRangeInput>;
  sign?: Maybe<Scalars['NullYN']>;
  keyonshop?: Maybe<Scalars['NullYN']>;
  pslinkflags?: Maybe<Scalars['String']>;
};


export enum Qcenergytype {
  Enddemand = 'enddemand',
  Usingeigenvalue = 'usingeigenvalue'
}

export type Query = {
  __typename?: 'Query';
  dummy?: Maybe<Scalars['Void']>;
  Activities: ActivityQueryResponse;
  Appointment?: Maybe<Appointment>;
  contacts: ContactQueryResponse;
  contact?: Maybe<Contact>;
  Document?: Maybe<Document>;
  Email?: Maybe<Email>;
  Propreslettings: PropreslettingQueryResponse;
  Propresletting?: Maybe<Propresletting>;
  Propressales: PropressaleQueryResponse;
  Propressale?: Maybe<Propressale>;
  SearchCriteria?: Maybe<SearchCriteria>;
  SearchCriteriaList: SearchCriteriaQueryResponse;
  Task?: Maybe<Task>;
  Viewing?: Maybe<Viewing>;
};


export type QueryActivitiesArgs = {
  where?: Maybe<ActivityQueryWhere>;
  order?: Maybe<ActivityQueryOrder>;
  page?: Maybe<Scalars['Int']>;
  rpp?: Maybe<Scalars['Int']>;
};


export type QueryAppointmentArgs = {
  key: Scalars['Int'];
};


export type QueryContactsArgs = {
  where?: Maybe<ContactQueryWhere>;
  order?: Maybe<ContactQueryOrder>;
  page?: Maybe<Scalars['Int']>;
  rpp?: Maybe<Scalars['Int']>;
};


export type QueryContactArgs = {
  utag: Scalars['String'];
};


export type QueryDocumentArgs = {
  key: Scalars['Int'];
};


export type QueryEmailArgs = {
  key: Scalars['Int'];
};


export type QueryPropreslettingsArgs = {
  where?: Maybe<PropreslettingQueryWhere>;
  order?: Maybe<PropreslettingQueryOrder>;
  page?: Maybe<Scalars['Int']>;
  rpp?: Maybe<Scalars['Int']>;
};


export type QueryPropreslettingArgs = {
  utag: Scalars['String'];
};


export type QueryPropressalesArgs = {
  where?: Maybe<PropressaleQueryWhere>;
  order?: Maybe<PropressaleQueryOrder>;
  page?: Maybe<Scalars['Int']>;
  rpp?: Maybe<Scalars['Int']>;
};


export type QueryPropressaleArgs = {
  utag: Scalars['String'];
};


export type QuerySearchCriteriaArgs = {
  utag: Scalars['String'];
};


export type QuerySearchCriteriaListArgs = {
  where?: Maybe<SearchCriteriaQueryWhere>;
  order?: Maybe<SearchCriteriaQueryOrder>;
  page?: Maybe<Scalars['Int']>;
  rpp?: Maybe<Scalars['Int']>;
};


export type QueryTaskArgs = {
  key: Scalars['Int'];
};


export type QueryViewingArgs = {
  key: Scalars['Int'];
};

export enum Rating {
  Aplusplus = 'aplusplus',
  Aplus = 'aplus',
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
  E = 'e',
  F = 'f',
  G = 'g',
  H = 'h',
  I = 'i'
}


export type RegisterOfflineTokenResponse = {
  __typename?: 'RegisterOfflineTokenResponse';
  success: Scalars['Boolean'];
};

export enum Rentalperiod {
  Yearround = 'yearround',
  Memorialdaytolaborday = 'memorialdaytolaborday',
  June = 'june',
  July = 'july',
  Augustlaborday = 'augustlaborday',
  Julylaborday = 'julylaborday',
  Junejuly = 'junejuly',
  Shorttermpermonth = 'shorttermpermonth'
}

export enum Ressubtype {
  Residential = 'residential',
  Privateoffice = 'privateoffice',
  Capital = 'capital'
}

export enum SalesApartmentbasicdata {
  Ownership = 'ownership',
  Coticode = 'coticode',
  Propertyavailablefrom = 'propertyavailablefrom',
  Totalsurfaceres = 'totalsurfaceres',
  Surfacelivinarea = 'surfacelivinarea',
  Usablesurface = 'usablesurface',
  Numberlivingareas = 'numberlivingareas',
  Totalnumberbathrooms = 'totalnumberbathrooms',
  Ofwhichnumberofmasterbathrooms = 'ofwhichnumberofmasterbathrooms',
  Numberbedrooms = 'numberbedrooms',
  Basement = 'basement',
  Floordesc = 'floordesc',
  Askingprice = 'askingprice',
  Housingbenefits = 'housingbenefits',
  Maintanancefee = 'maintanancefee',
  Qcenergyyn = 'qcenergyyn',
  Numbergarages = 'numbergarages',
  Yearconstruction = 'yearconstruction',
  Balcony = 'balcony',
  Rooftopterrace = 'rooftopterrace',
  Rooftopterracesize = 'rooftopterracesize',
  Garden = 'garden',
  Surfacegarden = 'surfacegarden',
  Terrace = 'terrace',
  Terracesize = 'terracesize',
  Fireplace = 'fireplace',
  Flooring = 'flooring',
  Heating = 'heating',
  Heatingres = 'heatingres',
  Airconditionres = 'airconditionres',
  Numberparkingbays = 'numberparkingbays',
  Numberstorieswithoutunits = 'numberstorieswithoutunits',
  Propertyavailablefromtext = 'propertyavailablefromtext',
  Floordesc_1 = 'floordesc_1',
  Propresviews = 'propresviews',
  Lastmodernisationrestoration = 'lastmodernisationrestoration',
  Elevator = 'elevator',
  Parkingbayprice = 'parkingbayprice',
  Garageprice = 'garageprice',
  Monthlyrentparkingbay = 'monthlyrentparkingbay',
  Monthlyrentgarage = 'monthlyrentgarage',
  Hwb = 'hwb',
  Fgee = 'fgee',
  Qcenergytype = 'qcenergytype',
  Qcenergy = 'qcenergy',
  Qcenergyhotwater = 'qcenergyhotwater',
  Energyefficiencyrating = 'energyefficiencyrating',
  Finalenergyconsumption = 'finalenergyconsumption',
  Finalenergydemand = 'finalenergydemand',
  Servantsquarter = 'servantsquarter',
  Additionalflatattenands = 'additionalflatattenands',
  Laundryroom = 'laundryroom'
}

export enum SalesCondition {
  Top = 'top',
  Verygood = 'verygood',
  Good = 'good',
  Average = 'average',
  Needsrenovation = 'needsrenovation',
  Rehabilitationdone = 'rehabilitationdone',
  Partlyrehabilitated = 'partlyrehabilitated',
  Needsrehabilitation = 'needsrehabilitation',
  Others = 'others'
}

export enum SalesHousebasicdata {
  Ownership = 'ownership',
  Coticode = 'coticode',
  Propertyavailablefrom = 'propertyavailablefrom',
  Totalsurfaceres = 'totalsurfaceres',
  Surfacelivinarea = 'surfacelivinarea',
  Usablesurface = 'usablesurface',
  Plotsurface = 'plotsurface',
  Numberlivingareas = 'numberlivingareas',
  Totalnumberbathrooms = 'totalnumberbathrooms',
  Ofwhichnumberofmasterbathrooms = 'ofwhichnumberofmasterbathrooms',
  Numberbedrooms = 'numberbedrooms',
  Basement = 'basement',
  Askingprice = 'askingprice',
  Numbergarages = 'numbergarages',
  Yearconstruction = 'yearconstruction',
  Balcony = 'balcony',
  Rooftopterrace = 'rooftopterrace',
  Rooftopterracesize = 'rooftopterracesize',
  Garden = 'garden',
  Surfacegarden = 'surfacegarden',
  Terrace = 'terrace',
  Terracesize = 'terracesize',
  Fireplace = 'fireplace',
  Flooring = 'flooring',
  Heating = 'heating',
  Heatingres = 'heatingres',
  Airconditionres = 'airconditionres',
  Numberstorieswithoutunits = 'numberstorieswithoutunits',
  Numberparkingbays = 'numberparkingbays',
  Propertyavailablefromtext = 'propertyavailablefromtext',
  Floordesc = 'floordesc',
  Propresviews = 'propresviews',
  Lastmodernisationrestoration = 'lastmodernisationrestoration',
  Elevator = 'elevator',
  Parkingbayprice = 'parkingbayprice',
  Garageprice = 'garageprice',
  Monthlyrentparkingbay = 'monthlyrentparkingbay',
  Monthlyrentgarage = 'monthlyrentgarage',
  Hwb = 'hwb',
  Fgee = 'fgee',
  Qcenergytype = 'qcenergytype',
  Qcenergy = 'qcenergy',
  Qcenergyhotwater = 'qcenergyhotwater',
  Energyefficiencyrating = 'energyefficiencyrating',
  Finalenergyconsumption = 'finalenergyconsumption',
  Finalenergydemand = 'finalenergydemand',
  Servantsquarter = 'servantsquarter',
  Additionalflatattenands = 'additionalflatattenands',
  Laundryroom = 'laundryroom'
}

export enum SalesPropertystatus {
  Active = 'active',
  Pending = 'pending',
  Offershow = 'offershow',
  Offernoshow = 'offernoshow',
  Soldbyev = 'soldbyev',
  Soldbyothers = 'soldbyothers',
  Withdrawn = 'withdrawn',
  Expired = 'expired',
  Cancelled = 'cancelled',
  Compprospect = 'compprospect',
  Inactive = 'inactive'
}

export enum SalesPropertytype {
  Apartment = 'apartment',
  House = 'house',
  Land = 'land'
}

export type SearchCriteria = {
  __typename?: 'SearchCriteria';
  key: Scalars['Int'];
  utag: Scalars['String'];
  owner_agent_key: Scalars['Int'];
  createdfor?: Maybe<Agent>;
  demandstatus: Demandstatus;
  demandexpiration: Scalars['Date'];
  demandabc: Demandtype;
  demandcomments: Scalars['NullNotes'];
  propresSalesSearchCriteria?: Maybe<PropresSalesSearchCriteria>;
  propresLettingSearchCriteria?: Maybe<PropresLettingSearchCriteria>;
};

export type SearchCriteriaQueryOrder = {
  utag?: Maybe<OrderBy>;
  demandstatus?: Maybe<OrderBy>;
  demandexpiration?: Maybe<OrderBy>;
  demandabc?: Maybe<OrderBy>;
};

export type SearchCriteriaQueryResponse = {
  __typename?: 'SearchCriteriaQueryResponse';
  rows?: Maybe<Array<SearchCriteria>>;
};

export type SearchCriteriaQueryWhere = {
  utag?: Maybe<Scalars['String']>;
  demandstatus?: Maybe<Demandstatus>;
  demandexpirationfrom?: Maybe<Scalars['Date']>;
  demandexpirationto?: Maybe<Scalars['Date']>;
  demandabc?: Maybe<Demandtype>;
  contact?: Maybe<Scalars['String']>;
};

export enum Seminar {
  Educationlicencepartner = 'educationlicencepartner',
  Seminarsalesoflicences = 'seminarsalesoflicences',
  Educationofficemanager = 'educationofficemanager',
  Readytogo = 'readytogo',
  Assistenceseminar = 'assistenceseminar',
  Traineeseminar = 'traineeseminar',
  Smallcapinvestmentseminar = 'smallcapinvestmentseminar',
  Officespaceseminar = 'officespaceseminar'
}

export type SigninResponse = {
  __typename?: 'SigninResponse';
  token: Scalars['String'];
  expires_at: Scalars['Int'];
  refresh_token: Scalars['String'];
  refresh_expires_at: Scalars['Int'];
  issued_at: Scalars['Int'];
  user_id: Scalars['Int'];
  login?: Maybe<Login>;
};

export enum Staffstatus {
  Appliedfor = 'appliedfor',
  Active = 'active',
  Open = 'open',
  Enabled = 'enabled',
  Inactive = 'inactive',
  Blockedaccess = 'blockedaccess'
}

export enum Status {
  Active = 'active',
  Pending = 'pending',
  Inactive = 'inactive',
  Noaccess = 'noaccess'
}

export type Subscription = {
  __typename?: 'Subscription';
  dummy?: Maybe<Scalars['Void']>;
};

export type Task = {
  __typename?: 'Task';
  key: Scalars['Int'];
  utag: Scalars['String'];
  owner_agent_key: Scalars['Int'];
  createdfor?: Maybe<Agent>;
  taskstatus: Taskstatus;
  laststatuschangeddate: Scalars['NullDate'];
  taskactiontype: Taskactiontype;
  tasksubject: Scalars['NullText'];
  taskduedate: Scalars['NullDate'];
  taskduetime: Taskduetime;
  taskcomments: Scalars['NullNotes'];
  tasklinkcontacts: Array<Scalars['Ref']>;
  tasklinkcontacts_entities: Array<Contact>;
  tasklinkpropressales: Array<Scalars['Ref']>;
  tasklinkpropressales_entities: Array<Propressale>;
  tasklinkpropreslettings: Array<Scalars['Ref']>;
  tasklinkpropreslettings_entities: Array<Propresletting>;
  unifieddate: Scalars['NullDate'];
};

export enum Taskactiontype {
  Acc = 'acc',
  Asc = 'asc',
  Followup = 'followup',
  Makeanappointment = 'makeanappointment',
  Offer = 'offer',
  Todo = 'todo',
  Activityreport = 'activityreport',
  Acquisation = 'acquisation',
  Placeadvertisement = 'placeadvertisement',
  Pricereduction = 'pricereduction',
  Newsletter = 'newsletter',
  Ownershipanniversary = 'ownershipanniversary',
  Miscellaneous = 'miscellaneous',
  Notice = 'notice',
  Marketreport = 'marketreport',
  Incomingcall = 'incomingcall',
  Outgoingcall = 'outgoingcall'
}

export enum Taskduetime {
  Taskduetime_0000 = 'taskduetime_0000',
  Taskduetime_0030 = 'taskduetime_0030',
  Taskduetime_0100 = 'taskduetime_0100',
  Taskduetime_0130 = 'taskduetime_0130',
  Taskduetime_0200 = 'taskduetime_0200',
  Taskduetime_0230 = 'taskduetime_0230',
  Taskduetime_0300 = 'taskduetime_0300',
  Taskduetime_0330 = 'taskduetime_0330',
  Taskduetime_0400 = 'taskduetime_0400',
  Taskduetime_0430 = 'taskduetime_0430',
  Taskduetime_0500 = 'taskduetime_0500',
  Taskduetime_0530 = 'taskduetime_0530',
  Taskduetime_0600 = 'taskduetime_0600',
  Taskduetime_0630 = 'taskduetime_0630',
  Taskduetime_0700 = 'taskduetime_0700',
  Taskduetime_0730 = 'taskduetime_0730',
  Taskduetime_0800 = 'taskduetime_0800',
  Taskduetime_0830 = 'taskduetime_0830',
  Taskduetime_0900 = 'taskduetime_0900',
  Taskduetime_0930 = 'taskduetime_0930',
  Taskduetime_1000 = 'taskduetime_1000',
  Taskduetime_1030 = 'taskduetime_1030',
  Taskduetime_1100 = 'taskduetime_1100',
  Taskduetime_1130 = 'taskduetime_1130',
  Taskduetime_1200 = 'taskduetime_1200',
  Taskduetime_1230 = 'taskduetime_1230',
  Taskduetime_1300 = 'taskduetime_1300',
  Taskduetime_1330 = 'taskduetime_1330',
  Taskduetime_1400 = 'taskduetime_1400',
  Taskduetime_1430 = 'taskduetime_1430',
  Taskduetime_1500 = 'taskduetime_1500',
  Taskduetime_1530 = 'taskduetime_1530',
  Taskduetime_1600 = 'taskduetime_1600',
  Taskduetime_1630 = 'taskduetime_1630',
  Taskduetime_1700 = 'taskduetime_1700',
  Taskduetime_1730 = 'taskduetime_1730',
  Taskduetime_1800 = 'taskduetime_1800',
  Taskduetime_1830 = 'taskduetime_1830',
  Taskduetime_1900 = 'taskduetime_1900',
  Taskduetime_1930 = 'taskduetime_1930',
  Taskduetime_2000 = 'taskduetime_2000',
  Taskduetime_2030 = 'taskduetime_2030',
  Taskduetime_2100 = 'taskduetime_2100',
  Taskduetime_2130 = 'taskduetime_2130',
  Taskduetime_2200 = 'taskduetime_2200',
  Taskduetime_2230 = 'taskduetime_2230',
  Taskduetime_2300 = 'taskduetime_2300',
  Taskduetime_2330 = 'taskduetime_2330'
}

export enum Taskstatus {
  Open = 'open',
  Completed = 'completed',
  Cancelled = 'cancelled'
}

export enum Tenure {
  Tenure = 'tenure',
  Longleaseyears = 'longleaseyears',
  Longleasecomment = 'longleasecomment',
  Leaseholdwithfreehold = 'leaseholdwithfreehold',
  Swissfloorfreehold = 'swissfloorfreehold',
  Floorfreehold = 'floorfreehold',
  Communityofheirs = 'communityofheirs',
  Owner = 'owner',
  Shorttermlease = 'shorttermlease'
}




export enum Title {
  Family = 'family',
  Corporation = 'corporation',
  Mrs = 'mrs',
  Mrsdr = 'mrsdr',
  Frauprof = 'frauprof',
  Mr = 'mr',
  Mrdr = 'mrdr',
  Herrprof = 'herrprof',
  Herrprofdr = 'herrprofdr',
  Mrmrs = 'mrmrs',
  Other = 'other'
}

export enum Typebasement {
  Fulbasement = 'fulbasement',
  Partbasement = 'partbasement',
  Nobasement = 'nobasement'
}

export enum Typecontact {
  Owner = 'owner',
  Tenant = 'tenant',
  Administrator = 'administrator',
  Otheragency = 'otheragency',
  Caretaker = 'caretaker',
  Others = 'others'
}

export enum Typeofdepartment {
  Smallcapinvestment = 'smallcapinvestment',
  Investment = 'investment',
  Officeletting = 'officeletting',
  Industrialrealestate = 'industrialrealestate',
  Retailletting = 'retailletting',
  Corporaterealestate = 'corporaterealestate',
  Residential = 'residential',
  Yachting = 'yachting'
}

export enum Typeoflicence2 {
  Smallcapinvest = 'smallcapinvest',
  Investment = 'investment',
  Industrialsale = 'industrialsale',
  Officeletting = 'officeletting',
  Industrialrent = 'industrialrent',
  Retailletting = 'retailletting',
  Residential = 'residential'
}

export enum Typeofmandate {
  Brokermandate = 'brokermandate',
  Exclusivemandate = 'exclusivemandate',
  Qualifiedexclusivemandate = 'qualifiedexclusivemandate',
  Jointmandate = 'jointmandate',
  Jointhandling = 'jointhandling',
  Noformalmandate = 'noformalmandate',
  Generalmandate = 'generalmandate'
}

export enum Typeusage {
  Residential = 'residential',
  Commercial = 'commercial',
  Service = 'service'
}

export enum Uidevelopmentpermit {
  Vv = 'vv',
  Gvv = 'gvv',
  Vvnb = 'vvnb'
}

export enum Uioptiontobuy {
  Vkr = 'vkr',
  Gvkr = 'gvkr',
  Vkrnb = 'vkrnb'
}

export enum Uipermit {
  Vg = 'vg',
  Gvg = 'gvg',
  Vgnb = 'vgnb'
}

export enum Uiplanningdestination {
  Wg = 'wg',
  Wche = 'wche',
  Wp = 'wp',
  Wug = 'wug',
  Igb = 'igb',
  Iab = 'iab',
  Gdr = 'gdr',
  Gvr = 'gvr',
  Ag = 'ag',
  Lwag = 'lwag',
  Bg = 'bg',
  Ng = 'ng',
  Nr = 'nr',
  Pg = 'pg',
  Sbnb = 'sbnb'
}

export enum Uisummons {
  Dv = 'dv',
  Gmo = 'gmo',
  Nb = 'nb',
  Ho = 'ho',
  Bo = 'bo',
  Lod = 'lod',
  Ms = 'ms'
}

export enum Usage1 {
  Forownuse = 'forownuse',
  Forthirdpartyuse = 'forthirdpartyuse'
}

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  email: Scalars['String'];
};

export type Viewing = {
  __typename?: 'Viewing';
  key: Scalars['Int'];
  utag: Scalars['String'];
  owner_agent_key: Scalars['Int'];
  createdfor?: Maybe<Agent>;
  unifieddate: Scalars['NullDate'];
  viewingstatus: Taskstatus;
  laststatuschangeddate: Scalars['NullDate'];
  viewingsubject: Scalars['NullText'];
  viewingdate: Scalars['Date'];
  viewingstarttime: Scalars['Time'];
  viewingendtime: Scalars['Time'];
  viewingcomments: Scalars['NullNotes'];
  viewingfeedback: Scalars['NullNotes'];
  viewinglinklinkcontact: Scalars['Ref'];
  viewinglinklinkcontact_entity?: Maybe<Contact>;
  viewinglinkpropressale: Scalars['NullRef'];
  viewinglinkpropressale_entity?: Maybe<Propressale>;
  viewinglinkpropresletting: Scalars['NullRef'];
  viewinglinkpropresletting_entity?: Maybe<Propresletting>;
};

export enum Visibilityoption {
  Medium = 'medium',
  Large = 'large',
  Extralarge = 'extralarge',
  Inthespotlight = 'inthespotlight'
}


export type Weblead = {
  __typename?: 'Weblead';
  id: Scalars['Int'];
  status: WebleadStatus;
  provider_type: WebleadProviderType;
  provider_ref: Scalars['String'];
  last_error?: Maybe<Scalars['String']>;
  data?: Maybe<WebleadData>;
  origin?: Maybe<Scalars['String']>;
};

export type WebleadData = {
  __typename?: 'WebleadData';
  property_utag: Scalars['String'];
  property_type: PropertyType;
  department_key: Scalars['Int'];
  internet_portal_key: Scalars['Int'];
  email1?: Maybe<Scalars['String']>;
  mobile_office?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type WebleadLog = {
  __typename?: 'WebleadLog';
  id: Scalars['Int'];
  weblead_id: Scalars['Int'];
  status: WebleadStatus;
  provider_type: WebleadProviderType;
  provider_ref: Scalars['String'];
  last_error?: Maybe<Scalars['String']>;
  data?: Maybe<WebleadData>;
  origin?: Maybe<Scalars['String']>;
};

export type WebleadProvider = {
  __typename?: 'WebleadProvider';
  id: Scalars['Int'];
  provider_type: WebleadProviderType;
  canonical_name: Scalars['String'];
  credentials: Scalars['String'];
};

export enum WebleadProviderType {
  Gmail = 'gmail',
  Idealista = 'idealista'
}

export enum WebleadStatus {
  Scanned = 'scanned',
  Error = 'error',
  Created = 'created'
}

export type WebleadSyncState = {
  __typename?: 'WebleadSyncState';
  provider_type: WebleadProviderType;
  ref: Scalars['String'];
  state: Scalars['String'];
};


export enum Activitytype {
  Email = 'email',
  Task = 'task',
  Appointment = 'appointment',
  Document = 'document',
  Viewing = 'viewing',
  All = 'all'
}

export type UserCredentialsFragment = (
  { __typename?: 'SigninResponse' }
  & Pick<SigninResponse, 'token' | 'refresh_token' | 'refresh_expires_at' | 'expires_at' | 'user_id'>
  & { login?: Maybe<(
    { __typename?: 'Login' }
    & Pick<Login, 'emailaddress' | 'loginfirstname' | 'loginname' | 'loginstatus' | 'loginsurname'>
    & { agents?: Maybe<Array<(
      { __typename?: 'Agent' }
      & Pick<Agent, 'agentpermission' | 'key' | 'utag' | 'firstname' | 'surname' | 'agentstatus' | 'agentlanguage'>
      & { department: (
        { __typename?: 'Department' }
        & Pick<Department, 'departmentinternalname' | 'abbreviatedname'>
      ) }
    )>> }
  )> }
);

export type ExchangeRefreshTokenMutationVariables = Exact<{
  refresh_token: Scalars['String'];
}>;


export type ExchangeRefreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { exchange_refresh_token: (
    { __typename?: 'SigninResponse' }
    & UserCredentialsFragment
  ) }
);

export type RegisterOfflineTokenMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type RegisterOfflineTokenMutation = (
  { __typename?: 'Mutation' }
  & { register_offline_token: (
    { __typename?: 'RegisterOfflineTokenResponse' }
    & Pick<RegisterOfflineTokenResponse, 'success'>
  ) }
);

export type SigninGoogleMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type SigninGoogleMutation = (
  { __typename?: 'Mutation' }
  & { signin_google: (
    { __typename?: 'SigninResponse' }
    & UserCredentialsFragment
  ) }
);

export type AgentCardFragment = (
  { __typename?: 'Agent' }
  & Pick<Agent, 'key' | 'utag' | 'title' | 'surname' | 'firstname' | 'academictitle' | 'agentstatus' | 'agentcategory' | 'agentpermission' | 'agentlanguage' | 'locale'>
  & { department: (
    { __typename?: 'Department' }
    & Pick<Department, 'key' | 'utag' | 'departmentinternalname' | 'abbreviatedname'>
  ) }
);

export type AgentShortCardFragment = (
  { __typename?: 'Agent' }
  & Pick<Agent, 'key' | 'utag' | 'surname' | 'firstname' | 'agentstatus' | 'agentcategory'>
  & { department: (
    { __typename?: 'Department' }
    & Pick<Department, 'departmentinternalname' | 'abbreviatedname'>
  ) }
);

export type AppointmentDetailsFragment = (
  { __typename?: 'Appointment' }
  & Pick<Appointment, 'apmtcategory' | 'apmtstatus' | 'laststatuschangeddate' | 'apmtactiontype' | 'apmtsubject' | 'apmtdate' | 'apmtstarttime' | 'apmtendtime' | 'apmtcomments' | 'unifieddate'>
  & { apmtlinkcontacts_entities: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'firstname' | 'surname' | 'utag'>
  )>, apmtlinkpropressales_entities: Array<(
    { __typename?: 'Propressale' }
    & Pick<Propressale, 'streetname1' | 'streetnumber'>
  )>, apmtlinkpropreslettings_entities: Array<(
    { __typename?: 'Propresletting' }
    & Pick<Propresletting, 'streetname1' | 'streetnumber'>
  )>, apmtattachments: Array<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'url'>
  )> }
);

export type DocumentDetailsFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'key' | 'utag' | 'unifieddate' | 'documenttype' | 'documentstatus'>
  & { documentsavedfile?: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'url'>
  )>, documentlinkcontacts_entities: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'utag' | 'firstname' | 'surname'>
  )>, documentlinkpropressales_entities: Array<(
    { __typename?: 'Propressale' }
    & Pick<Propressale, 'utag' | 'streetname1' | 'streetnumber'>
  )>, documentlinkpropreslettings_entities: Array<(
    { __typename?: 'Propresletting' }
    & Pick<Propresletting, 'utag' | 'streetname1' | 'streetnumber'>
  )> }
);

export type EmailDetailsFragment = (
  { __typename?: 'Email' }
  & Pick<Email, 'emailtype' | 'emailfrom' | 'emailto' | 'emailsubject' | 'emailbody' | 'unifieddate'>
  & { emaillinkcontacts_entities: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'utag' | 'firstname' | 'surname'>
  )>, emaillinkpropressales_entities: Array<(
    { __typename?: 'Propressale' }
    & Pick<Propressale, 'utag' | 'streetname1' | 'streetnumber'>
  )>, emaillinkpropreslettings_entities: Array<(
    { __typename?: 'Propresletting' }
    & Pick<Propresletting, 'utag' | 'streetname1' | 'streetnumber'>
  )>, emailattachments: Array<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'url'>
  )> }
);

export type TaskDetailsFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'taskstatus' | 'laststatuschangeddate' | 'taskactiontype' | 'tasksubject' | 'taskduedate' | 'taskduetime' | 'taskcomments' | 'unifieddate'>
  & { tasklinkcontacts_entities: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'utag' | 'firstname' | 'surname'>
  )>, tasklinkpropressales_entities: Array<(
    { __typename?: 'Propressale' }
    & Pick<Propressale, 'utag' | 'streetname1' | 'streetnumber'>
  )>, tasklinkpropreslettings_entities: Array<(
    { __typename?: 'Propresletting' }
    & Pick<Propresletting, 'utag' | 'streetname1' | 'streetnumber'>
  )> }
);

export type ViewingDetailsFragment = (
  { __typename?: 'Viewing' }
  & Pick<Viewing, 'key' | 'utag' | 'viewingstatus' | 'laststatuschangeddate' | 'viewingsubject' | 'viewingdate' | 'viewingstarttime' | 'viewingendtime' | 'viewingcomments' | 'viewingfeedback' | 'unifieddate' | 'viewinglinklinkcontact' | 'viewinglinkpropressale' | 'viewinglinkpropresletting'>
);

export type AppointmentsDetailQueryVariables = Exact<{
  key: Scalars['Int'];
}>;


export type AppointmentsDetailQuery = (
  { __typename?: 'Query' }
  & { Appointment?: Maybe<(
    { __typename?: 'Appointment' }
    & AppointmentDetailsFragment
  )> }
);

export type DocumentDetailQueryVariables = Exact<{
  key: Scalars['Int'];
}>;


export type DocumentDetailQuery = (
  { __typename?: 'Query' }
  & { Document?: Maybe<(
    { __typename?: 'Document' }
    & DocumentDetailsFragment
  )> }
);

export type EmailsDetailQueryVariables = Exact<{
  key: Scalars['Int'];
}>;


export type EmailsDetailQuery = (
  { __typename?: 'Query' }
  & { Email?: Maybe<(
    { __typename?: 'Email' }
    & EmailDetailsFragment
  )> }
);

export type TasksDetailQueryVariables = Exact<{
  key: Scalars['Int'];
}>;


export type TasksDetailQuery = (
  { __typename?: 'Query' }
  & { Task?: Maybe<(
    { __typename?: 'Task' }
    & TaskDetailsFragment
  )> }
);

export type ViewingsDetailQueryVariables = Exact<{
  key: Scalars['Int'];
}>;


export type ViewingsDetailQuery = (
  { __typename?: 'Query' }
  & { Viewing?: Maybe<(
    { __typename?: 'Viewing' }
    & ViewingDetailsFragment
  )> }
);

export type ActivitiesListRowFragment = (
  { __typename?: 'Activity' }
  & Pick<Activity, 'utag' | 'key' | 'unified_date' | 'type' | 'title' | 'status' | 'agent' | 'link_contacts' | 'link_propressales' | 'link_propreslettings'>
  & { createdfor?: Maybe<(
    { __typename?: 'Agent' }
    & Pick<Agent, 'utag' | 'surname' | 'firstname'>
  )>, link_contacts_entities: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'utag' | 'firstname' | 'surname'>
  )>, link_propressales_entities: Array<(
    { __typename?: 'Propressale' }
    & Pick<Propressale, 'utag' | 'streetname1' | 'streetnumber'>
  )>, link_propreslettings_entities: Array<(
    { __typename?: 'Propresletting' }
    & Pick<Propresletting, 'utag'>
  )> }
);

export type ActivitiesListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  where?: Maybe<ActivityQueryWhere>;
  orderBy?: Maybe<ActivityQueryOrder>;
}>;


export type ActivitiesListQuery = (
  { __typename?: 'Query' }
  & { Activities: (
    { __typename?: 'ActivityQueryResponse' }
    & { rows?: Maybe<Array<(
      { __typename?: 'Activity' }
      & ActivitiesListRowFragment
    )>> }
  ) }
);

export type ContactDetailsFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'key' | 'company' | 'department' | 'title' | 'title_value' | 'newtitle' | 'firstname' | 'surname' | 'birthday' | 'firstnamepartner' | 'surnamepartner' | 'phoneoffice1' | 'mobileoffice' | 'mobileprivate' | 'telprivate1' | 'fax1' | 'email1' | 'email2' | 'favouredwayofcontact' | 'streetname1' | 'streetnumber1' | 'postalcode1' | 'city1' | 'country1' | 'envsource' | 'lastaction' | 'idpassport' | 'idpassportcomments' | 'contactcategory' | 'status' | 'secondcategory' | 'secondstatus' | 'thirdcategory' | 'thirdstatus' | 'commentsgenera' | 'nomail' | 'utag'>
  & { owner_agent_entity?: Maybe<(
    { __typename?: 'Agent' }
    & AgentShortCardFragment
  )>, secondagent_entity?: Maybe<(
    { __typename?: 'Agent' }
    & AgentShortCardFragment
  )>, thirdagent_entity?: Maybe<(
    { __typename?: 'Agent' }
    & AgentShortCardFragment
  )> }
);

export type ContactsDetailQueryVariables = Exact<{
  utag: Scalars['String'];
}>;


export type ContactsDetailQuery = (
  { __typename?: 'Query' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & ContactDetailsFragment
  )> }
);

export type ContactsListRowFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'key' | 'utag' | 'surname' | 'firstname' | 'contactcategory' | 'mobileoffice' | 'email1' | 'status' | 'lastaction'>
  & { owner_agent_entity?: Maybe<(
    { __typename?: 'Agent' }
    & Pick<Agent, 'firstname' | 'surname'>
  )> }
);

export type ContactsListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  where?: Maybe<ContactQueryWhere>;
  orderBy?: Maybe<ContactQueryOrder>;
}>;


export type ContactsListQuery = (
  { __typename?: 'Query' }
  & { contacts: (
    { __typename?: 'ContactQueryResponse' }
    & { rows?: Maybe<Array<(
      { __typename?: 'Contact' }
      & ContactsListRowFragment
    )>> }
  ) }
);

export type LettingDetailsFragment = (
  { __typename?: 'Propresletting' }
  & Pick<Propresletting, 'key' | 'utag' | 'streetname1' | 'streetnumber' | 'propertyname' | 'postcode' | 'city' | 'area' | 'district' | 'listingowner' | 'netrentpmres' | 'netrentsurfsystpmres' | 'propertytype' | 'propertysubtype' | 'propertysubtypeValue' | 'totalsurfaceres' | 'surfacelivinarea' | 'plotsurface' | 'floordesc' | 'garden' | 'balcony' | 'numberlivingareas' | 'numberbedrooms' | 'totalnumberbathrooms' | 'yearconstruction' | 'propertystatus' | 'lastaction' | 'typeofmandate' | 'mandateto' | 'commissionpercent' | 'commissionpercentowner' | 'sign' | 'keyonshop' | 'keylabel' | 'envsource' | 'commentsbasicdata'>
  & { area_entity?: Maybe<(
    { __typename?: 'Area' }
    & Pick<Area, 'areaname'>
  )>, district_entity?: Maybe<(
    { __typename?: 'District' }
    & Pick<District, 'districtname'>
  )>, listingowner_entity?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'title' | 'company' | 'firstname' | 'surname' | 'streetname1' | 'streetnumber1' | 'postalcode1' | 'city1' | 'mobileoffice' | 'email1'>
  )>, createdfor?: Maybe<(
    { __typename?: 'Agent' }
    & Pick<Agent, 'key'>
  )>, photoset: Array<Maybe<(
    { __typename?: 'Photo' }
    & Pick<Photo, 'url'>
  )>> }
);

export type LettingsDetailQueryVariables = Exact<{
  utag: Scalars['String'];
}>;


export type LettingsDetailQuery = (
  { __typename?: 'Query' }
  & { Propresletting?: Maybe<(
    { __typename?: 'Propresletting' }
    & LettingDetailsFragment
  )> }
);

export type LettingsListRowFragment = (
  { __typename?: 'Propresletting' }
  & Pick<Propresletting, 'key' | 'utag' | 'propertytype' | 'streetname1' | 'streetnumber' | 'district' | 'netrentpmres' | 'propertystatus'>
  & { area_entity?: Maybe<(
    { __typename?: 'Area' }
    & Pick<Area, 'areaname'>
  )>, district_entity?: Maybe<(
    { __typename?: 'District' }
    & Pick<District, 'districtname'>
  )>, listingowner_entity?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'utag' | 'firstname' | 'surname'>
  )>, createdfor?: Maybe<(
    { __typename?: 'Agent' }
    & Pick<Agent, 'firstname' | 'surname'>
  )> }
);

export type LettingsListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  where?: Maybe<PropreslettingQueryWhere>;
  orderBy?: Maybe<PropreslettingQueryOrder>;
}>;


export type LettingsListQuery = (
  { __typename?: 'Query' }
  & { Propreslettings: (
    { __typename?: 'PropreslettingQueryResponse' }
    & { rows?: Maybe<Array<(
      { __typename?: 'Propresletting' }
      & LettingsListRowFragment
    )>> }
  ) }
);

export type SaleDetailsFragment = (
  { __typename?: 'Propressale' }
  & Pick<Propressale, 'propertysubtype' | 'propertysubtypeValue' | 'key' | 'utag' | 'streetname1' | 'streetnumber' | 'propertyname' | 'postcode' | 'city' | 'area' | 'district' | 'listingowner' | 'askingprice' | 'askingpricesurfsystres' | 'propertytype' | 'totalsurfaceres' | 'surfacelivinarea' | 'plotsurface' | 'floordesc' | 'numberlivingareas' | 'numberbedrooms' | 'totalnumberbathrooms' | 'yearconstruction' | 'plotprice' | 'propertystatus' | 'lastaction' | 'mandateto' | 'typeofmandate' | 'commissionpercent' | 'commissionpercentowner' | 'keyonshop' | 'sign' | 'keylabel' | 'envsource' | 'commentsbasicdata'>
  & { photoset: Array<Maybe<(
    { __typename?: 'Photo' }
    & Pick<Photo, 'url'>
  )>>, area_entity?: Maybe<(
    { __typename?: 'Area' }
    & Pick<Area, 'areaname'>
  )>, district_entity?: Maybe<(
    { __typename?: 'District' }
    & Pick<District, 'districtname'>
  )>, listingowner_entity?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'title' | 'company' | 'firstname' | 'surname' | 'streetname1' | 'streetnumber1' | 'postalcode1' | 'city1' | 'mobileoffice' | 'email1'>
  )>, createdfor?: Maybe<(
    { __typename?: 'Agent' }
    & Pick<Agent, 'key'>
  )>, pslinkflag_entities?: Maybe<Array<Maybe<(
    { __typename?: 'Flag' }
    & Pick<Flag, 'flagname' | 'flagdesc'>
  )>>> }
);

export type SalesDetailQueryVariables = Exact<{
  utag: Scalars['String'];
}>;


export type SalesDetailQuery = (
  { __typename?: 'Query' }
  & { Propressale?: Maybe<(
    { __typename?: 'Propressale' }
    & SaleDetailsFragment
  )> }
);

export type SalesListRowFragment = (
  { __typename?: 'Propressale' }
  & Pick<Propressale, 'key' | 'utag' | 'propertytype' | 'propertysubtype' | 'streetname1' | 'streetnumber' | 'district' | 'askingprice' | 'propertystatus'>
  & { area_entity?: Maybe<(
    { __typename?: 'Area' }
    & Pick<Area, 'areaname'>
  )>, district_entity?: Maybe<(
    { __typename?: 'District' }
    & Pick<District, 'districtname'>
  )>, listingowner_entity?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'utag' | 'firstname' | 'surname'>
  )>, createdfor?: Maybe<(
    { __typename?: 'Agent' }
    & Pick<Agent, 'firstname' | 'surname'>
  )> }
);

export type SalesListQueryVariables = Exact<{
  where?: Maybe<PropressaleQueryWhere>;
  order?: Maybe<PropressaleQueryOrder>;
  page?: Maybe<Scalars['Int']>;
  rpp?: Maybe<Scalars['Int']>;
}>;


export type SalesListQuery = (
  { __typename?: 'Query' }
  & { Propressales: (
    { __typename?: 'PropressaleQueryResponse' }
    & { rows?: Maybe<Array<(
      { __typename?: 'Propressale' }
      & SalesListRowFragment
    )>> }
  ) }
);

export type SearchDemandsRowFragment = (
  { __typename?: 'SearchCriteria' }
  & Pick<SearchCriteria, 'key' | 'utag' | 'demandstatus' | 'demandabc' | 'demandcomments' | 'demandexpiration'>
  & { createdfor?: Maybe<(
    { __typename?: 'Agent' }
    & Pick<Agent, 'firstname' | 'surname'>
  )>, propresSalesSearchCriteria?: Maybe<(
    { __typename?: 'PropresSalesSearchCriteria' }
    & Pick<PropresSalesSearchCriteria, 'type' | 'subType'>
    & { linkcdf1?: Maybe<Array<(
      { __typename?: 'CustomizedDemand' }
      & Pick<CustomizedDemand, 'cdfname'>
    )>>, linkcdf2?: Maybe<Array<(
      { __typename?: 'CustomizedDemand' }
      & Pick<CustomizedDemand, 'cdfname'>
    )>>, districtList: Array<(
      { __typename?: 'District' }
      & Pick<District, 'districtname'>
    )>, areaList: Array<(
      { __typename?: 'Area' }
      & Pick<Area, 'areaname'>
    )>, totalSurfaceres?: Maybe<(
      { __typename?: 'FloatRange' }
      & Pick<FloatRange, 'Min' | 'Max'>
    )>, surfaceLivinArea?: Maybe<(
      { __typename?: 'FloatRange' }
      & Pick<FloatRange, 'Min' | 'Max'>
    )>, plotSurface?: Maybe<(
      { __typename?: 'FloatRange' }
      & Pick<FloatRange, 'Min' | 'Max'>
    )>, numberLivingAreas?: Maybe<(
      { __typename?: 'FloatRange' }
      & Pick<FloatRange, 'Min' | 'Max'>
    )>, numberBedrooms?: Maybe<(
      { __typename?: 'FloatRange' }
      & Pick<FloatRange, 'Min' | 'Max'>
    )>, askingPrice?: Maybe<(
      { __typename?: 'FloatRange' }
      & Pick<FloatRange, 'Min' | 'Max'>
    )> }
  )>, propresLettingSearchCriteria?: Maybe<(
    { __typename?: 'PropresLettingSearchCriteria' }
    & Pick<PropresLettingSearchCriteria, 'type' | 'subType'>
    & { areaList: Array<(
      { __typename?: 'Area' }
      & Pick<Area, 'areaname'>
    )>, districtList: Array<(
      { __typename?: 'District' }
      & Pick<District, 'districtname'>
    )>, totalSurfaceres?: Maybe<(
      { __typename?: 'FloatRange' }
      & Pick<FloatRange, 'Min' | 'Max'>
    )>, surfaceLivinArea?: Maybe<(
      { __typename?: 'FloatRange' }
      & Pick<FloatRange, 'Min' | 'Max'>
    )>, plotSurface?: Maybe<(
      { __typename?: 'FloatRange' }
      & Pick<FloatRange, 'Min' | 'Max'>
    )>, numberLivingAreas?: Maybe<(
      { __typename?: 'FloatRange' }
      & Pick<FloatRange, 'Min' | 'Max'>
    )>, numberBedrooms?: Maybe<(
      { __typename?: 'FloatRange' }
      & Pick<FloatRange, 'Min' | 'Max'>
    )>, netRentPmres?: Maybe<(
      { __typename?: 'FloatRange' }
      & Pick<FloatRange, 'Min' | 'Max'>
    )>, totalRentPm?: Maybe<(
      { __typename?: 'FloatRange' }
      & Pick<FloatRange, 'Min' | 'Max'>
    )>, shortTermRental?: Maybe<(
      { __typename?: 'FloatRange' }
      & Pick<FloatRange, 'Min' | 'Max'>
    )>, linkcdf1?: Maybe<Array<(
      { __typename?: 'CustomizedDemand' }
      & Pick<CustomizedDemand, 'cdfname'>
    )>>, linkcdf2?: Maybe<Array<(
      { __typename?: 'CustomizedDemand' }
      & Pick<CustomizedDemand, 'cdfname'>
    )>> }
  )> }
);

export type SearchDemandsListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  where?: Maybe<SearchCriteriaQueryWhere>;
  orderBy?: Maybe<SearchCriteriaQueryOrder>;
}>;


export type SearchDemandsListQuery = (
  { __typename?: 'Query' }
  & { SearchCriteriaList: (
    { __typename?: 'SearchCriteriaQueryResponse' }
    & { rows?: Maybe<Array<(
      { __typename?: 'SearchCriteria' }
      & SearchDemandsRowFragment
    )>> }
  ) }
);

export const UserCredentialsFragmentDoc = gql`
    fragment UserCredentials on SigninResponse {
  token
  refresh_token
  refresh_expires_at
  expires_at
  user_id
  login {
    agents {
      agentpermission
      department {
        departmentinternalname
        abbreviatedname
      }
      key
      utag
      firstname
      surname
      agentstatus
      agentlanguage
    }
    emailaddress
    loginfirstname
    loginname
    loginstatus
    loginsurname
  }
}
    `;
export const AgentCardFragmentDoc = gql`
    fragment AgentCard on Agent {
  key
  utag
  title
  surname
  firstname
  academictitle
  agentstatus
  agentcategory
  agentpermission
  agentlanguage
  locale
  department {
    key
    utag
    departmentinternalname
    abbreviatedname
  }
}
    `;
export const AppointmentDetailsFragmentDoc = gql`
    fragment AppointmentDetails on Appointment {
  apmtcategory
  apmtstatus
  laststatuschangeddate
  apmtactiontype
  apmtsubject
  apmtdate
  apmtstarttime
  apmtendtime
  apmtcomments
  apmtlinkcontacts_entities {
    firstname
    surname
    utag
  }
  apmtlinkpropressales_entities {
    streetname1
    streetnumber
  }
  apmtlinkpropreslettings_entities {
    streetname1
    streetnumber
  }
  unifieddate
  apmtattachments {
    url
  }
}
    `;
export const DocumentDetailsFragmentDoc = gql`
    fragment DocumentDetails on Document {
  key
  utag
  unifieddate
  documenttype
  documentstatus
  documentsavedfile {
    url
  }
  documentlinkcontacts_entities {
    utag
    firstname
    surname
  }
  documentlinkpropressales_entities {
    utag
    streetname1
    streetnumber
  }
  documentlinkpropreslettings_entities {
    utag
    streetname1
    streetnumber
  }
}
    `;
export const EmailDetailsFragmentDoc = gql`
    fragment EmailDetails on Email {
  emailtype
  emailfrom
  emailto
  emailsubject
  emaillinkcontacts_entities {
    utag
    firstname
    surname
  }
  emaillinkpropressales_entities {
    utag
    streetname1
    streetnumber
  }
  emaillinkpropreslettings_entities {
    utag
    streetname1
    streetnumber
  }
  emailbody
  emailattachments {
    url
  }
  unifieddate
}
    `;
export const TaskDetailsFragmentDoc = gql`
    fragment TaskDetails on Task {
  taskstatus
  laststatuschangeddate
  taskactiontype
  tasksubject
  taskduedate
  taskduetime
  taskcomments
  unifieddate
  tasklinkcontacts_entities {
    utag
    firstname
    surname
  }
  tasklinkpropressales_entities {
    utag
    streetname1
    streetnumber
  }
  tasklinkpropreslettings_entities {
    utag
    streetname1
    streetnumber
  }
}
    `;
export const ViewingDetailsFragmentDoc = gql`
    fragment ViewingDetails on Viewing {
  key
  utag
  viewingstatus
  laststatuschangeddate
  viewingsubject
  viewingdate
  viewingstarttime
  viewingendtime
  viewingcomments
  viewingfeedback
  unifieddate
  viewinglinklinkcontact
  viewinglinkpropressale
  viewinglinkpropresletting
}
    `;
export const ActivitiesListRowFragmentDoc = gql`
    fragment ActivitiesListRow on Activity {
  utag
  key
  unified_date
  type
  title
  status
  agent
  createdfor {
    utag
    surname
    firstname
  }
  link_contacts
  link_contacts_entities {
    utag
    firstname
    surname
  }
  link_propressales
  link_propressales_entities {
    utag
    streetname1
    streetnumber
  }
  link_propreslettings
  link_propreslettings_entities {
    utag
  }
}
    `;
export const AgentShortCardFragmentDoc = gql`
    fragment AgentShortCard on Agent {
  key
  utag
  surname
  firstname
  agentstatus
  agentcategory
  department {
    departmentinternalname
    abbreviatedname
  }
}
    `;
export const ContactDetailsFragmentDoc = gql`
    fragment ContactDetails on Contact {
  key
  company
  department
  title
  title_value
  newtitle
  firstname
  surname
  birthday
  firstnamepartner
  surnamepartner
  phoneoffice1
  mobileoffice
  mobileprivate
  telprivate1
  fax1
  email1
  email2
  favouredwayofcontact
  streetname1
  streetnumber1
  postalcode1
  city1
  country1
  envsource
  lastaction
  idpassport
  idpassportcomments
  owner_agent_entity {
    ...AgentShortCard
  }
  contactcategory
  status
  secondagent_entity {
    ...AgentShortCard
  }
  secondcategory
  secondstatus
  thirdagent_entity {
    ...AgentShortCard
  }
  thirdcategory
  thirdstatus
  commentsgenera
  nomail
  utag
}
    ${AgentShortCardFragmentDoc}`;
export const ContactsListRowFragmentDoc = gql`
    fragment ContactsListRow on Contact {
  key
  utag
  owner_agent_entity {
    firstname
    surname
  }
  surname
  firstname
  contactcategory
  mobileoffice
  email1
  status
  lastaction
}
    `;
export const LettingDetailsFragmentDoc = gql`
    fragment LettingDetails on Propresletting {
  key
  utag
  streetname1
  streetnumber
  propertyname
  postcode
  city
  area
  area_entity {
    areaname
  }
  district
  district_entity {
    districtname
  }
  listingowner
  listingowner_entity {
    title
    company
    firstname
    surname
    streetname1
    streetnumber1
    postalcode1
    city1
    mobileoffice
    email1
  }
  netrentpmres
  netrentsurfsystpmres
  propertytype
  propertysubtype
  propertysubtypeValue
  totalsurfaceres
  surfacelivinarea
  plotsurface
  floordesc
  garden
  balcony
  numberlivingareas
  numberbedrooms
  totalnumberbathrooms
  yearconstruction
  propertystatus
  createdfor {
    key
  }
  lastaction
  typeofmandate
  mandateto
  commissionpercent
  commissionpercentowner
  sign
  keyonshop
  keylabel
  envsource
  photoset {
    url
  }
  commentsbasicdata
}
    `;
export const LettingsListRowFragmentDoc = gql`
    fragment LettingsListRow on Propresletting {
  key
  utag
  propertytype
  streetname1
  streetnumber
  area_entity {
    areaname
  }
  district
  district_entity {
    districtname
  }
  listingowner_entity {
    utag
    firstname
    surname
  }
  netrentpmres
  propertystatus
  createdfor {
    firstname
    surname
  }
}
    `;
export const SaleDetailsFragmentDoc = gql`
    fragment SaleDetails on Propressale {
  photoset {
    url
  }
  propertysubtype
  propertysubtypeValue
  key
  utag
  streetname1
  streetnumber
  propertyname
  postcode
  city
  area
  area_entity {
    areaname
  }
  district
  district_entity {
    districtname
  }
  listingowner
  listingowner_entity {
    title
    company
    firstname
    surname
    streetname1
    streetnumber1
    postalcode1
    city1
    mobileoffice
    email1
  }
  askingprice
  askingpricesurfsystres
  propertytype
  propertysubtype
  totalsurfaceres
  surfacelivinarea
  plotsurface
  floordesc
  numberlivingareas
  numberbedrooms
  totalnumberbathrooms
  yearconstruction
  plotprice
  propertystatus
  lastaction
  mandateto
  typeofmandate
  commissionpercent
  commissionpercentowner
  keyonshop
  sign
  keylabel
  envsource
  commentsbasicdata
  createdfor {
    key
  }
  pslinkflag_entities {
    flagname
    flagdesc
  }
}
    `;
export const SalesListRowFragmentDoc = gql`
    fragment SalesListRow on Propressale {
  key
  utag
  propertytype
  propertysubtype
  streetname1
  streetnumber
  area_entity {
    areaname
  }
  district
  district_entity {
    districtname
  }
  listingowner_entity {
    utag
    firstname
    surname
  }
  createdfor {
    firstname
    surname
  }
  askingprice
  propertystatus
}
    `;
export const SearchDemandsRowFragmentDoc = gql`
    fragment SearchDemandsRow on SearchCriteria {
  key
  utag
  createdfor {
    firstname
    surname
  }
  demandstatus
  demandabc
  demandcomments
  demandexpiration
  propresSalesSearchCriteria {
    type
    subType
    linkcdf1 {
      cdfname
    }
    linkcdf2 {
      cdfname
    }
    districtList {
      districtname
    }
    areaList {
      areaname
    }
    totalSurfaceres {
      Min
      Max
    }
    surfaceLivinArea {
      Min
      Max
    }
    plotSurface {
      Min
      Max
    }
    numberLivingAreas {
      Min
      Max
    }
    numberBedrooms {
      Min
      Max
    }
    askingPrice {
      Min
      Max
    }
    linkcdf1 {
      cdfname
    }
    linkcdf2 {
      cdfname
    }
  }
  propresLettingSearchCriteria {
    type
    subType
    areaList {
      areaname
    }
    districtList {
      districtname
    }
    totalSurfaceres {
      Min
      Max
    }
    surfaceLivinArea {
      Min
      Max
    }
    plotSurface {
      Min
      Max
    }
    numberLivingAreas {
      Min
      Max
    }
    numberBedrooms {
      Min
      Max
    }
    netRentPmres {
      Min
      Max
    }
    totalRentPm {
      Min
      Max
    }
    shortTermRental {
      Min
      Max
    }
    linkcdf1 {
      cdfname
    }
    linkcdf2 {
      cdfname
    }
  }
}
    `;
export const ExchangeRefreshTokenDocument = gql`
    mutation exchangeRefreshToken($refresh_token: String!) {
  exchange_refresh_token(refresh_token: $refresh_token) {
    ...UserCredentials
  }
}
    ${UserCredentialsFragmentDoc}`;
export type ExchangeRefreshTokenMutationFn = Apollo.MutationFunction<ExchangeRefreshTokenMutation, ExchangeRefreshTokenMutationVariables>;

/**
 * __useExchangeRefreshTokenMutation__
 *
 * To run a mutation, you first call `useExchangeRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangeRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangeRefreshTokenMutation, { data, loading, error }] = useExchangeRefreshTokenMutation({
 *   variables: {
 *      refresh_token: // value for 'refresh_token'
 *   },
 * });
 */
export function useExchangeRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<ExchangeRefreshTokenMutation, ExchangeRefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExchangeRefreshTokenMutation, ExchangeRefreshTokenMutationVariables>(ExchangeRefreshTokenDocument, options);
      }
export type ExchangeRefreshTokenMutationHookResult = ReturnType<typeof useExchangeRefreshTokenMutation>;
export type ExchangeRefreshTokenMutationResult = Apollo.MutationResult<ExchangeRefreshTokenMutation>;
export type ExchangeRefreshTokenMutationOptions = Apollo.BaseMutationOptions<ExchangeRefreshTokenMutation, ExchangeRefreshTokenMutationVariables>;
export const RegisterOfflineTokenDocument = gql`
    mutation registerOfflineToken($code: String!) {
  register_offline_token(code: $code) {
    success
  }
}
    `;
export type RegisterOfflineTokenMutationFn = Apollo.MutationFunction<RegisterOfflineTokenMutation, RegisterOfflineTokenMutationVariables>;

/**
 * __useRegisterOfflineTokenMutation__
 *
 * To run a mutation, you first call `useRegisterOfflineTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterOfflineTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerOfflineTokenMutation, { data, loading, error }] = useRegisterOfflineTokenMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useRegisterOfflineTokenMutation(baseOptions?: Apollo.MutationHookOptions<RegisterOfflineTokenMutation, RegisterOfflineTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterOfflineTokenMutation, RegisterOfflineTokenMutationVariables>(RegisterOfflineTokenDocument, options);
      }
export type RegisterOfflineTokenMutationHookResult = ReturnType<typeof useRegisterOfflineTokenMutation>;
export type RegisterOfflineTokenMutationResult = Apollo.MutationResult<RegisterOfflineTokenMutation>;
export type RegisterOfflineTokenMutationOptions = Apollo.BaseMutationOptions<RegisterOfflineTokenMutation, RegisterOfflineTokenMutationVariables>;
export const SigninGoogleDocument = gql`
    mutation signinGoogle($token: String!) {
  signin_google(token: $token) {
    ...UserCredentials
  }
}
    ${UserCredentialsFragmentDoc}`;
export type SigninGoogleMutationFn = Apollo.MutationFunction<SigninGoogleMutation, SigninGoogleMutationVariables>;

/**
 * __useSigninGoogleMutation__
 *
 * To run a mutation, you first call `useSigninGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinGoogleMutation, { data, loading, error }] = useSigninGoogleMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSigninGoogleMutation(baseOptions?: Apollo.MutationHookOptions<SigninGoogleMutation, SigninGoogleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SigninGoogleMutation, SigninGoogleMutationVariables>(SigninGoogleDocument, options);
      }
export type SigninGoogleMutationHookResult = ReturnType<typeof useSigninGoogleMutation>;
export type SigninGoogleMutationResult = Apollo.MutationResult<SigninGoogleMutation>;
export type SigninGoogleMutationOptions = Apollo.BaseMutationOptions<SigninGoogleMutation, SigninGoogleMutationVariables>;
export const AppointmentsDetailDocument = gql`
    query appointmentsDetail($key: Int!) {
  Appointment(key: $key) {
    ...AppointmentDetails
  }
}
    ${AppointmentDetailsFragmentDoc}`;

/**
 * __useAppointmentsDetailQuery__
 *
 * To run a query within a React component, call `useAppointmentsDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsDetailQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useAppointmentsDetailQuery(baseOptions: Apollo.QueryHookOptions<AppointmentsDetailQuery, AppointmentsDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsDetailQuery, AppointmentsDetailQueryVariables>(AppointmentsDetailDocument, options);
      }
export function useAppointmentsDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsDetailQuery, AppointmentsDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsDetailQuery, AppointmentsDetailQueryVariables>(AppointmentsDetailDocument, options);
        }
export type AppointmentsDetailQueryHookResult = ReturnType<typeof useAppointmentsDetailQuery>;
export type AppointmentsDetailLazyQueryHookResult = ReturnType<typeof useAppointmentsDetailLazyQuery>;
export type AppointmentsDetailQueryResult = Apollo.QueryResult<AppointmentsDetailQuery, AppointmentsDetailQueryVariables>;
export const DocumentDetailDocument = gql`
    query documentDetail($key: Int!) {
  Document(key: $key) {
    ...DocumentDetails
  }
}
    ${DocumentDetailsFragmentDoc}`;

/**
 * __useDocumentDetailQuery__
 *
 * To run a query within a React component, call `useDocumentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentDetailQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDocumentDetailQuery(baseOptions: Apollo.QueryHookOptions<DocumentDetailQuery, DocumentDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentDetailQuery, DocumentDetailQueryVariables>(DocumentDetailDocument, options);
      }
export function useDocumentDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentDetailQuery, DocumentDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentDetailQuery, DocumentDetailQueryVariables>(DocumentDetailDocument, options);
        }
export type DocumentDetailQueryHookResult = ReturnType<typeof useDocumentDetailQuery>;
export type DocumentDetailLazyQueryHookResult = ReturnType<typeof useDocumentDetailLazyQuery>;
export type DocumentDetailQueryResult = Apollo.QueryResult<DocumentDetailQuery, DocumentDetailQueryVariables>;
export const EmailsDetailDocument = gql`
    query emailsDetail($key: Int!) {
  Email(key: $key) {
    ...EmailDetails
  }
}
    ${EmailDetailsFragmentDoc}`;

/**
 * __useEmailsDetailQuery__
 *
 * To run a query within a React component, call `useEmailsDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailsDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailsDetailQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useEmailsDetailQuery(baseOptions: Apollo.QueryHookOptions<EmailsDetailQuery, EmailsDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailsDetailQuery, EmailsDetailQueryVariables>(EmailsDetailDocument, options);
      }
export function useEmailsDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailsDetailQuery, EmailsDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailsDetailQuery, EmailsDetailQueryVariables>(EmailsDetailDocument, options);
        }
export type EmailsDetailQueryHookResult = ReturnType<typeof useEmailsDetailQuery>;
export type EmailsDetailLazyQueryHookResult = ReturnType<typeof useEmailsDetailLazyQuery>;
export type EmailsDetailQueryResult = Apollo.QueryResult<EmailsDetailQuery, EmailsDetailQueryVariables>;
export const TasksDetailDocument = gql`
    query tasksDetail($key: Int!) {
  Task(key: $key) {
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;

/**
 * __useTasksDetailQuery__
 *
 * To run a query within a React component, call `useTasksDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksDetailQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useTasksDetailQuery(baseOptions: Apollo.QueryHookOptions<TasksDetailQuery, TasksDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksDetailQuery, TasksDetailQueryVariables>(TasksDetailDocument, options);
      }
export function useTasksDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksDetailQuery, TasksDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksDetailQuery, TasksDetailQueryVariables>(TasksDetailDocument, options);
        }
export type TasksDetailQueryHookResult = ReturnType<typeof useTasksDetailQuery>;
export type TasksDetailLazyQueryHookResult = ReturnType<typeof useTasksDetailLazyQuery>;
export type TasksDetailQueryResult = Apollo.QueryResult<TasksDetailQuery, TasksDetailQueryVariables>;
export const ViewingsDetailDocument = gql`
    query viewingsDetail($key: Int!) {
  Viewing(key: $key) {
    ...ViewingDetails
  }
}
    ${ViewingDetailsFragmentDoc}`;

/**
 * __useViewingsDetailQuery__
 *
 * To run a query within a React component, call `useViewingsDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewingsDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewingsDetailQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useViewingsDetailQuery(baseOptions: Apollo.QueryHookOptions<ViewingsDetailQuery, ViewingsDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewingsDetailQuery, ViewingsDetailQueryVariables>(ViewingsDetailDocument, options);
      }
export function useViewingsDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewingsDetailQuery, ViewingsDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewingsDetailQuery, ViewingsDetailQueryVariables>(ViewingsDetailDocument, options);
        }
export type ViewingsDetailQueryHookResult = ReturnType<typeof useViewingsDetailQuery>;
export type ViewingsDetailLazyQueryHookResult = ReturnType<typeof useViewingsDetailLazyQuery>;
export type ViewingsDetailQueryResult = Apollo.QueryResult<ViewingsDetailQuery, ViewingsDetailQueryVariables>;
export const ActivitiesListDocument = gql`
    query activitiesList($page: Int, $pageSize: Int, $where: ActivityQueryWhere, $orderBy: ActivityQueryOrder) {
  Activities(page: $page, rpp: $pageSize, where: $where, order: $orderBy) {
    rows {
      ...ActivitiesListRow
    }
  }
}
    ${ActivitiesListRowFragmentDoc}`;

/**
 * __useActivitiesListQuery__
 *
 * To run a query within a React component, call `useActivitiesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitiesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitiesListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useActivitiesListQuery(baseOptions?: Apollo.QueryHookOptions<ActivitiesListQuery, ActivitiesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivitiesListQuery, ActivitiesListQueryVariables>(ActivitiesListDocument, options);
      }
export function useActivitiesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivitiesListQuery, ActivitiesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivitiesListQuery, ActivitiesListQueryVariables>(ActivitiesListDocument, options);
        }
export type ActivitiesListQueryHookResult = ReturnType<typeof useActivitiesListQuery>;
export type ActivitiesListLazyQueryHookResult = ReturnType<typeof useActivitiesListLazyQuery>;
export type ActivitiesListQueryResult = Apollo.QueryResult<ActivitiesListQuery, ActivitiesListQueryVariables>;
export const ContactsDetailDocument = gql`
    query contactsDetail($utag: String!) {
  contact(utag: $utag) {
    ...ContactDetails
  }
}
    ${ContactDetailsFragmentDoc}`;

/**
 * __useContactsDetailQuery__
 *
 * To run a query within a React component, call `useContactsDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsDetailQuery({
 *   variables: {
 *      utag: // value for 'utag'
 *   },
 * });
 */
export function useContactsDetailQuery(baseOptions: Apollo.QueryHookOptions<ContactsDetailQuery, ContactsDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsDetailQuery, ContactsDetailQueryVariables>(ContactsDetailDocument, options);
      }
export function useContactsDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsDetailQuery, ContactsDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsDetailQuery, ContactsDetailQueryVariables>(ContactsDetailDocument, options);
        }
export type ContactsDetailQueryHookResult = ReturnType<typeof useContactsDetailQuery>;
export type ContactsDetailLazyQueryHookResult = ReturnType<typeof useContactsDetailLazyQuery>;
export type ContactsDetailQueryResult = Apollo.QueryResult<ContactsDetailQuery, ContactsDetailQueryVariables>;
export const ContactsListDocument = gql`
    query contactsList($page: Int, $pageSize: Int, $where: ContactQueryWhere, $orderBy: ContactQueryOrder) {
  contacts(page: $page, rpp: $pageSize, where: $where, order: $orderBy) {
    rows {
      ...ContactsListRow
    }
  }
}
    ${ContactsListRowFragmentDoc}`;

/**
 * __useContactsListQuery__
 *
 * To run a query within a React component, call `useContactsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useContactsListQuery(baseOptions?: Apollo.QueryHookOptions<ContactsListQuery, ContactsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsListQuery, ContactsListQueryVariables>(ContactsListDocument, options);
      }
export function useContactsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsListQuery, ContactsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsListQuery, ContactsListQueryVariables>(ContactsListDocument, options);
        }
export type ContactsListQueryHookResult = ReturnType<typeof useContactsListQuery>;
export type ContactsListLazyQueryHookResult = ReturnType<typeof useContactsListLazyQuery>;
export type ContactsListQueryResult = Apollo.QueryResult<ContactsListQuery, ContactsListQueryVariables>;
export const LettingsDetailDocument = gql`
    query lettingsDetail($utag: String!) {
  Propresletting(utag: $utag) {
    ...LettingDetails
  }
}
    ${LettingDetailsFragmentDoc}`;

/**
 * __useLettingsDetailQuery__
 *
 * To run a query within a React component, call `useLettingsDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useLettingsDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLettingsDetailQuery({
 *   variables: {
 *      utag: // value for 'utag'
 *   },
 * });
 */
export function useLettingsDetailQuery(baseOptions: Apollo.QueryHookOptions<LettingsDetailQuery, LettingsDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LettingsDetailQuery, LettingsDetailQueryVariables>(LettingsDetailDocument, options);
      }
export function useLettingsDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LettingsDetailQuery, LettingsDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LettingsDetailQuery, LettingsDetailQueryVariables>(LettingsDetailDocument, options);
        }
export type LettingsDetailQueryHookResult = ReturnType<typeof useLettingsDetailQuery>;
export type LettingsDetailLazyQueryHookResult = ReturnType<typeof useLettingsDetailLazyQuery>;
export type LettingsDetailQueryResult = Apollo.QueryResult<LettingsDetailQuery, LettingsDetailQueryVariables>;
export const LettingsListDocument = gql`
    query lettingsList($page: Int, $pageSize: Int, $where: PropreslettingQueryWhere, $orderBy: PropreslettingQueryOrder) {
  Propreslettings(page: $page, rpp: $pageSize, where: $where, order: $orderBy) {
    rows {
      ...LettingsListRow
    }
  }
}
    ${LettingsListRowFragmentDoc}`;

/**
 * __useLettingsListQuery__
 *
 * To run a query within a React component, call `useLettingsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLettingsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLettingsListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useLettingsListQuery(baseOptions?: Apollo.QueryHookOptions<LettingsListQuery, LettingsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LettingsListQuery, LettingsListQueryVariables>(LettingsListDocument, options);
      }
export function useLettingsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LettingsListQuery, LettingsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LettingsListQuery, LettingsListQueryVariables>(LettingsListDocument, options);
        }
export type LettingsListQueryHookResult = ReturnType<typeof useLettingsListQuery>;
export type LettingsListLazyQueryHookResult = ReturnType<typeof useLettingsListLazyQuery>;
export type LettingsListQueryResult = Apollo.QueryResult<LettingsListQuery, LettingsListQueryVariables>;
export const SalesDetailDocument = gql`
    query salesDetail($utag: String!) {
  Propressale(utag: $utag) {
    ...SaleDetails
  }
}
    ${SaleDetailsFragmentDoc}`;

/**
 * __useSalesDetailQuery__
 *
 * To run a query within a React component, call `useSalesDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesDetailQuery({
 *   variables: {
 *      utag: // value for 'utag'
 *   },
 * });
 */
export function useSalesDetailQuery(baseOptions: Apollo.QueryHookOptions<SalesDetailQuery, SalesDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesDetailQuery, SalesDetailQueryVariables>(SalesDetailDocument, options);
      }
export function useSalesDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesDetailQuery, SalesDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesDetailQuery, SalesDetailQueryVariables>(SalesDetailDocument, options);
        }
export type SalesDetailQueryHookResult = ReturnType<typeof useSalesDetailQuery>;
export type SalesDetailLazyQueryHookResult = ReturnType<typeof useSalesDetailLazyQuery>;
export type SalesDetailQueryResult = Apollo.QueryResult<SalesDetailQuery, SalesDetailQueryVariables>;
export const SalesListDocument = gql`
    query salesList($where: PropressaleQueryWhere, $order: PropressaleQueryOrder, $page: Int, $rpp: Int) {
  Propressales(where: $where, order: $order, page: $page, rpp: $rpp) {
    rows {
      ...SalesListRow
    }
  }
}
    ${SalesListRowFragmentDoc}`;

/**
 * __useSalesListQuery__
 *
 * To run a query within a React component, call `useSalesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      rpp: // value for 'rpp'
 *   },
 * });
 */
export function useSalesListQuery(baseOptions?: Apollo.QueryHookOptions<SalesListQuery, SalesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesListQuery, SalesListQueryVariables>(SalesListDocument, options);
      }
export function useSalesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesListQuery, SalesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesListQuery, SalesListQueryVariables>(SalesListDocument, options);
        }
export type SalesListQueryHookResult = ReturnType<typeof useSalesListQuery>;
export type SalesListLazyQueryHookResult = ReturnType<typeof useSalesListLazyQuery>;
export type SalesListQueryResult = Apollo.QueryResult<SalesListQuery, SalesListQueryVariables>;
export const SearchDemandsListDocument = gql`
    query SearchDemandsList($page: Int, $pageSize: Int, $where: SearchCriteriaQueryWhere, $orderBy: SearchCriteriaQueryOrder) {
  SearchCriteriaList(page: $page, rpp: $pageSize, where: $where, order: $orderBy) {
    rows {
      ...SearchDemandsRow
    }
  }
}
    ${SearchDemandsRowFragmentDoc}`;

/**
 * __useSearchDemandsListQuery__
 *
 * To run a query within a React component, call `useSearchDemandsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDemandsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDemandsListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSearchDemandsListQuery(baseOptions?: Apollo.QueryHookOptions<SearchDemandsListQuery, SearchDemandsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchDemandsListQuery, SearchDemandsListQueryVariables>(SearchDemandsListDocument, options);
      }
export function useSearchDemandsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchDemandsListQuery, SearchDemandsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchDemandsListQuery, SearchDemandsListQueryVariables>(SearchDemandsListDocument, options);
        }
export type SearchDemandsListQueryHookResult = ReturnType<typeof useSearchDemandsListQuery>;
export type SearchDemandsListLazyQueryHookResult = ReturnType<typeof useSearchDemandsListLazyQuery>;
export type SearchDemandsListQueryResult = Apollo.QueryResult<SearchDemandsListQuery, SearchDemandsListQueryVariables>;