import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set, unset } from "lodash-es";
import { DefaultPage, DefaultPageSize } from "const/pagination";
import { PropreslettingQueryWhere } from "lib/api";

import { RootState } from "store";

export const LETTINGS_LIST_SLICE_NAME = "lettings";

interface Pagination {
    page: number;
    pageSize: number;
}

interface LettingsListSliceState {
    filters: PropreslettingQueryWhere;
    pagination: Pagination;
}

const initialState: LettingsListSliceState = {
    filters: {},
    pagination: { page: DefaultPage, pageSize: DefaultPageSize },
};

export const lettingsListSlice = createSlice({
    name: LETTINGS_LIST_SLICE_NAME,
    initialState,
    reducers: {
        setFilter: (
            state,
            action: PayloadAction<{
                field: keyof PropreslettingQueryWhere;
                value: any;
            }>,
        ) => {
            const { field, value } = action.payload;
            // to accept false as filter values
            if (value || value === false) {
                set(state.filters, field, value);
            } else {
                unset(state.filters, field);
            }
            state.pagination.page = DefaultPage;
        },
        setPage: (state, action: PayloadAction<number>) => {
            const page = action.payload;
            state.pagination.page = page;
        },
        setPageSize: (state, action: PayloadAction<number>) => {
            const pageSize = action.payload;
            state.pagination.pageSize = pageSize;
            state.pagination.page = DefaultPage;
        },
    },
});

export const { setFilter, setPage, setPageSize } = lettingsListSlice.actions;

export const selectLettingsListSlice = (state: RootState) => state[LETTINGS_LIST_SLICE_NAME];
export const selectLettingsListFilters = (state: RootState) => selectLettingsListSlice(state).filters;

export default lettingsListSlice.reducer;
