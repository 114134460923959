import React, { FC, useMemo } from "react";
import Typography from "@mui/material/Typography/Typography";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useTranslation } from "react-i18next";
import { selectActivitiesListFilters, setFilter } from "store/slices/activitiesList.slice";
import { ActivityQueryWhere, Activitytype, ActivityStatus } from "lib/api";
import { SearchBar } from "components/utils/SearchBar";
import { EnumSelect } from "components/utils/EnumSelect";
import { enumToObject } from "utils/convertors";

const activityTypeOptions = enumToObject(Activitytype);
const activityStatusOptions = enumToObject(ActivityStatus, ["open", "completed", "cancelled"]);

export const ActivitiesListHeader: FC = () => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector(selectActivitiesListFilters);

    const { t } = useTranslation("backend");

    const onChange = useMemo(
        () => (field: string, value: string) => dispatch(setFilter({ field: field as keyof ActivityQueryWhere, value })),
        [dispatch],
    );

    const onSelect = useMemo(
        () =>
            <Option extends unknown>(field: string, value: Option) => {
                dispatch(setFilter({ field: field as keyof ActivityQueryWhere, value }));
            },
        [dispatch],
    );

    return (
        <Box paddingTop={2} paddingBottom={2}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>{t("translation:search_and_filter")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={4}>
                            <EnumSelect<Activitytype>
                                value={filters.type as Activitytype | undefined}
                                name="type"
                                onChange={onSelect}
                                options={activityTypeOptions}
                                enableNoneSelection
                                emptyLabel={t("translation:type")}
                                translationPrefix=""
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <EnumSelect<ActivityStatus>
                                value={filters.status as ActivityStatus | undefined}
                                name="status"
                                onChange={onSelect}
                                options={activityStatusOptions}
                                enableNoneSelection
                                emptyLabel={t("status")}
                                translationPrefix="backend:taskstatus."
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar
                                name="datefrom"
                                onChange={onChange}
                                placeholder={`${t("translation:date")} ${t("translation:min")}`}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="dateto" onChange={onChange} placeholder={`${t("translation:date")} ${t("translation:max")}`} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <SearchBar name="createdfor" onChange={onChange} placeholder={t("agent")} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
