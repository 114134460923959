/* global TableProps, Entity */
import React, { FC } from "react";
import { ActivitiesListRowFragment } from "lib/api";
import { useParams } from "react-router";
import { Box, Divider, TablePagination, Typography } from "@mui/material";
import { Loading } from "components/utils/Loading";
import { useNavigateTo } from "hooks/useNavigateTo";
import { PaginationSizes } from "const/pagination";
import { formatDate, getActivityPath } from "utils/formatters";
import { useTranslation } from "react-i18next";

const ActivitiesListRowMobile: FC<{ activity: ActivitiesListRowFragment; onClick: () => void }> = ({ activity, onClick }) => {
    const { status, type, createdfor, unified_date, link_propressales_entities } = activity;

    return (
        <>
            <Box
                sx={{
                    minHeight: "4ex",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: 1,
                    paddingRight: 1,
                    fontSize: "0.85rem",
                    paddingY: 1,
                }}
                onClick={onClick}
            >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h6">{type}</Typography>
                    <Typography variant="body1" component="div">
                        <br />
                        {`${link_propressales_entities.map((link) => `${link.streetname1} ${link.streetnumber}`).join("; ")}`}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", color: "#757575" }}>
                    <Typography variant="caption">{status}</Typography>
                    <Typography variant="caption">{`${createdfor?.firstname} ${createdfor?.surname}`}</Typography>
                    <Typography variant="caption">{formatDate(unified_date)}</Typography>
                </Box>
            </Box>
            <Divider />
        </>
    );
};

export const ActivitiesListMobileTable: FC<TableProps<ActivitiesListRowFragment> & { entity: Entity }> = ({
    data,
    loading,
    page,
    setPage,
    pageSize,
    setPageSize,
    entity,
}) => {
    const navigateTo = useNavigateTo();
    const { utag: entityUtag } = useParams();
    const { t } = useTranslation();

    if (loading) return <Loading />;

    return (
        <>
            {data?.map((activity) => (
                <ActivitiesListRowMobile
                    key={activity.utag}
                    activity={activity}
                    onClick={() => {
                        navigateTo(getActivityPath(activity.type as any, entity), {
                            utag: entityUtag,
                            [`${activity.type}key`]: activity.key,
                        });
                    }}
                />
            ))}
            <TablePagination
                rowsPerPageOptions={PaginationSizes}
                component="div"
                count={-1}
                rowsPerPage={pageSize}
                page={page}
                labelRowsPerPage={t("translation:rows_per_page")}
                labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
                onPageChange={(_, $page) => {
                    setPage($page);
                }}
                onRowsPerPageChange={(event) => {
                    const $pageSize = parseInt(event.target.value);
                    setPageSize($pageSize);
                }}
            />
        </>
    );
};
