import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set, unset } from "lodash-es";
import { DefaultPage, DefaultPageSize } from "const/pagination";

import { ContactQueryWhere } from "lib/api";
import { RootState } from "store";

export const CONTACT_LIST_SLICE_NAME = "contacts-list";

type Pagination = {
    page: number;
    pageSize: number;
};

type ContactsListSliceState = {
    filters: ContactQueryWhere;
    pagination: Pagination;
};

const initialState: ContactsListSliceState = {
    filters: {},
    pagination: { page: DefaultPage, pageSize: DefaultPageSize },
};

export const contactsListSlice = createSlice({
    name: CONTACT_LIST_SLICE_NAME,
    initialState,
    reducers: {
        setFilter: (state, action: PayloadAction<{ field: keyof ContactQueryWhere; value: any }>) => {
            const { field, value } = action.payload;
            if (value) {
                set(state.filters, field, value);
            } else {
                unset(state.filters, field);
            }
            state.pagination.page = DefaultPage;
        },
        setPage: (state, action: PayloadAction<number>) => {
            const page = action.payload;
            state.pagination.page = page;
        },
        setPageSize: (state, action: PayloadAction<number>) => {
            const pageSize = action.payload;
            state.pagination.pageSize = pageSize;
            state.pagination.page = DefaultPage;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setFilter, setPage, setPageSize } = contactsListSlice.actions;

export const selectContactsListSlice = (state: RootState) => state[CONTACT_LIST_SLICE_NAME];
export const selectContactsListFilters = (state: RootState) => selectContactsListSlice(state).filters;

export default contactsListSlice.reducer;
