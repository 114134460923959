import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import LoginBackground from "assets/loginBackground.svg";

export const AuthLayout: FC = () => (
    <Box
        sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
            background: `url(${LoginBackground}) left bottom / contain no-repeat`,
        }}
    >
        <Outlet />
    </Box>
);
