import { FC } from "react";
import { Box, Chip } from "@mui/material";

interface TagsProps {
    tags: (string | undefined)[];
}

export const Tags: FC<TagsProps> = ({ tags = [] }) => (
    <Box sx={{ display: "flex", gap: 0.5, flexWrap: "wrap" }}>
        {tags.length > 0 ? tags.map((tag) => tag && <Chip variant="outlined" size="small" label={tag} />) : "-"}
    </Box>
);
