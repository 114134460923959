import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set, unset } from "lodash-es";
import { DefaultPage, DefaultPageSize } from "const/pagination";
import { ActivityQueryWhere } from "lib/api";

import { RootState } from "store";

export const ACTIVITIES_LIST_SLICE_NAME = "activities";

interface Pagination {
    page: number;
    pageSize: number;
}

interface ActivitiesListSliceState {
    filters: ActivityQueryWhere;
    pagination: Pagination;
}

const initialState: ActivitiesListSliceState = {
    filters: {},
    pagination: { page: DefaultPage, pageSize: DefaultPageSize },
};

export const activitiesListSlice = createSlice({
    name: ACTIVITIES_LIST_SLICE_NAME,
    initialState,
    reducers: {
        setFilter: (
            state,
            action: PayloadAction<{
                field: keyof ActivityQueryWhere;
                value: any;
            }>,
        ) => {
            const { field, value } = action.payload;
            if (value || value === false) {
                set(state.filters, field, value);
            } else {
                unset(state.filters, field);
            }
            state.pagination.page = DefaultPage;
        },
        setPage: (state, action: PayloadAction<number>) => {
            const page = action.payload;
            state.pagination.page = page;
        },
        setPageSize: (state, action: PayloadAction<number>) => {
            const pageSize = action.payload;
            state.pagination.pageSize = pageSize;
            state.pagination.page = DefaultPage;
        },
    },
});

export const { setFilter, setPage, setPageSize } = activitiesListSlice.actions;

export const selectActivitiesListSlice = (state: RootState) => state[ACTIVITIES_LIST_SLICE_NAME];
export const selectActivitiesListFilters = (state: RootState) => selectActivitiesListSlice(state).filters;

export default activitiesListSlice.reducer;
