import { Routes as ReactRoutes, Route, Navigate } from "react-router-dom";
import { Go3ToolboxRoutes } from "const/Go3ToolboxRoutes";
import MainLayout from "components/layout/MainLayout";
import { AuthLayout } from "components/layout/AuthLayout";
import { LoginPage } from "pages/LoginPage";
import { ContactsListPage } from "pages/ContactsListPage";
import NotFoundPage from "components/layout/NotFoundPage";
import { ContactsDetailPage } from "pages/ContactsDetailPage";
import { SalesListPage } from "pages/SalesListPage";
import { SalesDetailPage } from "pages/SalesDetailPage";
import { LettingsListPage } from "pages/LettingsListPage";
import { LettingsDetailPage } from "pages/LettingsDetailPage";
import { ActivitiesListPage } from "pages/ActivitiesListPage";
import { SearchDemandsListPage } from "pages/SearchDemandsListPage";
import { TaskPage } from "pages/ActivitiesDetailPage/TaskPage";
import { EmailPage } from "pages/ActivitiesDetailPage/EmailPage";
import { AppointmentPage } from "pages/ActivitiesDetailPage/AppointmentPage";
import { DocumentPage } from "pages/ActivitiesDetailPage/DocumentPage";
import { ViewingPage } from "pages/ActivitiesDetailPage/ViewingPage";

export const Routes = () => (
    <ReactRoutes>
        <Route path={Go3ToolboxRoutes.App.path} element={<MainLayout />}>
            <Route path={Go3ToolboxRoutes.App.Contacts.path}>
                <Route path={Go3ToolboxRoutes.App.Contacts.ListPage.path} element={<ContactsListPage />} />
                <Route path={Go3ToolboxRoutes.App.Contacts.DetailsPage.path} element={<ContactsDetailPage />} />
                <Route path={Go3ToolboxRoutes.App.Contacts.DetailsPage.path}>
                    <Route
                        path={Go3ToolboxRoutes.App.Contacts.DetailsPage.Activities.path}
                        element={<ActivitiesListPage entity="Contacts" />}
                    />
                    <Route path={Go3ToolboxRoutes.App.Contacts.DetailsPage.path}>
                        <Route path={Go3ToolboxRoutes.App.Contacts.DetailsPage.Activities.DetailsPage.Task.path} element={<TaskPage />} />
                        <Route path={Go3ToolboxRoutes.App.Contacts.DetailsPage.Activities.DetailsPage.Email.path} element={<EmailPage />} />
                        <Route
                            path={Go3ToolboxRoutes.App.Contacts.DetailsPage.Activities.DetailsPage.Appointment.path}
                            element={<AppointmentPage />}
                        />
                        <Route
                            path={Go3ToolboxRoutes.App.Contacts.DetailsPage.Activities.DetailsPage.Document.path}
                            element={<DocumentPage />}
                        />
                        <Route
                            path={Go3ToolboxRoutes.App.Contacts.DetailsPage.Activities.DetailsPage.Viewing.path}
                            element={<ViewingPage />}
                        />
                    </Route>

                    <Route
                        path={Go3ToolboxRoutes.App.Contacts.DetailsPage.Demands.path}
                        element={<SearchDemandsListPage entity="Contacts" />}
                    />
                </Route>
            </Route>
            <Route path={Go3ToolboxRoutes.App.Sales.path}>
                <Route path={Go3ToolboxRoutes.App.Sales.ListPage.path} element={<SalesListPage />} />
                <Route path={Go3ToolboxRoutes.App.Sales.DetailsPage.path} element={<SalesDetailPage />} />
                <Route path={Go3ToolboxRoutes.App.Sales.DetailsPage.path}>
                    <Route path={Go3ToolboxRoutes.App.Sales.DetailsPage.Activities.path} element={<ActivitiesListPage entity="Sales" />} />
                    <Route path={Go3ToolboxRoutes.App.Sales.DetailsPage.path}>
                        <Route path={Go3ToolboxRoutes.App.Sales.DetailsPage.Activities.DetailsPage.Task.path} element={<TaskPage />} />
                        <Route path={Go3ToolboxRoutes.App.Sales.DetailsPage.Activities.DetailsPage.Email.path} element={<EmailPage />} />
                        <Route
                            path={Go3ToolboxRoutes.App.Sales.DetailsPage.Activities.DetailsPage.Appointment.path}
                            element={<AppointmentPage />}
                        />
                        <Route
                            path={Go3ToolboxRoutes.App.Sales.DetailsPage.Activities.DetailsPage.Document.path}
                            element={<DocumentPage />}
                        />
                        <Route
                            path={Go3ToolboxRoutes.App.Sales.DetailsPage.Activities.DetailsPage.Viewing.path}
                            element={<ViewingPage />}
                        />
                    </Route>
                </Route>
            </Route>
            <Route path={Go3ToolboxRoutes.App.Lettings.path}>
                <Route path={Go3ToolboxRoutes.App.Lettings.ListPage.path} element={<LettingsListPage />} />
                <Route path={Go3ToolboxRoutes.App.Lettings.DetailsPage.path} element={<LettingsDetailPage />} />
                <Route path={Go3ToolboxRoutes.App.Lettings.DetailsPage.path}>
                    <Route
                        path={Go3ToolboxRoutes.App.Lettings.DetailsPage.Activities.path}
                        element={<ActivitiesListPage entity="Lettings" />}
                    />
                    <Route path={Go3ToolboxRoutes.App.Lettings.DetailsPage.path}>
                        <Route path={Go3ToolboxRoutes.App.Lettings.DetailsPage.Activities.DetailsPage.Task.path} element={<TaskPage />} />
                        <Route path={Go3ToolboxRoutes.App.Lettings.DetailsPage.Activities.DetailsPage.Email.path} element={<EmailPage />} />
                        <Route
                            path={Go3ToolboxRoutes.App.Lettings.DetailsPage.Activities.DetailsPage.Appointment.path}
                            element={<AppointmentPage />}
                        />
                        <Route
                            path={Go3ToolboxRoutes.App.Lettings.DetailsPage.Activities.DetailsPage.Document.path}
                            element={<DocumentPage />}
                        />
                        <Route
                            path={Go3ToolboxRoutes.App.Lettings.DetailsPage.Activities.DetailsPage.Viewing.path}
                            element={<ViewingPage />}
                        />
                    </Route>
                </Route>
            </Route>
        </Route>
        <Route path={Go3ToolboxRoutes.path} element={<AuthLayout />}>
            <Route path={Go3ToolboxRoutes.Login.path} element={<LoginPage />} />
            <Route path={Go3ToolboxRoutes.NotFound.path} element={<NotFoundPage />} />
            <Navigate to={Go3ToolboxRoutes.App.Contacts.ListPage.fullPath} />
        </Route>
    </ReactRoutes>
);
