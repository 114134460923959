import React, { FC, useState } from "react";
import { DetailPageNavigation } from "components/containers/DetailPageNavigation";
import { Go3ToolboxRoutes } from "const/Go3ToolboxRoutes";
import { useParams } from "react-router-dom";
import { useAppSelector } from "hooks/redux";
import { useTranslation } from "react-i18next";
import { OrderBy, SearchCriteriaQueryOrder, useSearchDemandsListQuery } from "lib/api";
import { selectSearchDemandsListSlice } from "store/slices/searchDemandsList.slice";
import { SearchDemandsListContent } from "./SearchDemandsListContent";

interface SearchDemandsListPageProps {
    entity: "Contacts" | "Lettings" | "Sales";
}
export const SearchDemandsListPage: FC<SearchDemandsListPageProps> = ({ entity }) => {
    const { utag } = useParams();
    const { t } = useTranslation("backend");

    const {
        filters,
        pagination: { page, pageSize },
    } = useAppSelector(selectSearchDemandsListSlice);
    const [orderBy] = useState<SearchCriteriaQueryOrder>({
        demandabc: OrderBy.Asc,
    });
    const { data, loading } = useSearchDemandsListQuery({
        variables: {
            page: page + 1,
            pageSize,
            orderBy,
            where: { ...filters, contact: utag },
        },
    });

    const links = [
        { href: `${Go3ToolboxRoutes.App[entity].fullPath}/${utag}`, title: "Detail View" },
        { href: `${Go3ToolboxRoutes.App[entity].fullPath}/${utag}/activities`, title: t("translation:activities") },
    ];
    if (entity === "Contacts") {
        links.push({ href: `${Go3ToolboxRoutes.App[entity].fullPath}/${utag}/demands`, title: t("searchcriteria-plural") });
    }

    return (
        <>
            <DetailPageNavigation links={links} />
            <SearchDemandsListContent data={data?.SearchCriteriaList.rows || []} loading={loading} />
        </>
    );
};
