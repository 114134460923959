import React, { FC } from "react";
import { SearchDemandsRowFragment } from "lib/api";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useDesktopView } from "utils/useDesktopView";
import { selectSearchDemandsListSlice, setPage, setPageSize } from "store/slices/searchDemandsList.slice";
import { DesktopListViewLayout } from "components/containers/DesktopListViewLayout";
import { SearchDemandsListTable } from "./SearchDemandsListTable";
import { SearchDemandsListMobileTable } from "./SearchDemandsListMobileTable";

interface SearchDemandsListProps {
    data: Array<SearchDemandsRowFragment>;
    loading: boolean;
}
export const SearchDemandsListContent: FC<SearchDemandsListProps> = ({ data, loading }) => {
    const dispatch = useAppDispatch();
    const showDesktopView = useDesktopView();

    const {
        pagination: { page, pageSize },
    } = useAppSelector(selectSearchDemandsListSlice);

    const handleChangePage = (newPage: number) => {
        dispatch(setPage(newPage));
    };

    const handleChangePageSize = (newPageSize: number) => {
        dispatch(setPageSize(newPageSize));
    };

    return showDesktopView ? (
        <DesktopListViewLayout>
            <SearchDemandsListTable
                data={data || []}
                loading={loading}
                page={page}
                setPage={handleChangePage}
                pageSize={pageSize}
                setPageSize={handleChangePageSize}
            />
        </DesktopListViewLayout>
    ) : (
        <SearchDemandsListMobileTable
            data={data || []}
            loading={loading}
            page={page}
            setPage={handleChangePage}
            pageSize={pageSize}
            setPageSize={handleChangePageSize}
        />
    );
};
