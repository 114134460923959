import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { logout, selectUserCredentials } from "store/slices/auth.slice";

const AuthenticationRedirectChecker: FC = ({ children }) => {
    const userCredentials = useAppSelector(selectUserCredentials);
    const dispatch = useAppDispatch();
    // are you already logged in?
    if (!userCredentials) {
        dispatch(logout());
        return <Navigate to="/login" />;
    }

    return <>{children}</>;
};

export default AuthenticationRedirectChecker;
