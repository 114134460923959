import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { EnumSelect } from "components/utils/EnumSelect";

const languages = {
    "de-DE": "de-DE",
    "de-CH": "de-CH",
    "de-AT": "de-AT",
    "en-GB": "en-GB",
    "en-US": "en-US",
    "es-ES": "es-ES",
    "es-MX": "es-MX",
    "fr-FR": "fr-FR",
    "fr-BE": "fr-BE",
    "it-IT": "it-IT",
    "pt-PT": "pt-PT",
};

interface LanguageSelectorProps {
    defaultLanguage: string;
}

export const LanguageSelector: VFC<LanguageSelectorProps> = ({ defaultLanguage }) => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (_: any, lng: string) => {
        i18n.changeLanguage(lng);
    };
    return (
        <EnumSelect
            value={defaultLanguage}
            name="lng"
            onChange={changeLanguage}
            options={languages}
            enableNoneSelection
            emptyLabel={t("translation:language")}
            translationPrefix="translation:"
            SelectProps={{ sx: { fontSize: 14 } }}
        />
    );
};
