import { FC, useState, SyntheticEvent } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

interface DetailPageNavigationProps {
    links: { title: string; href: string }[];
}

export const DetailPageNavigation: FC<DetailPageNavigationProps> = ({ links }) => {
    const location = useLocation();
    const [tab, setTab] = useState<string>(location.pathname);
    const navigate = useNavigate();

    const handleChange = (event: SyntheticEvent, value: string) => {
        setTab(value);
        navigate(value);
    };

    return (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tab} onChange={handleChange}>
                {links.map((link) => (
                    <Tab key={link.href} label={link.title} value={link.href} />
                ))}
            </Tabs>
        </Box>
    );
};
