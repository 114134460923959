import * as React from "react";
import { FC, useCallback, useEffect, useState } from "react";
import { useDebounced } from "hooks/useDebounced";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, InputAdornment, styled, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export const SearchBarIcon = styled(SearchIcon)`
    margin-left: 12px;
    margin-right: 10px;
`;

export const SearchBarLoadingSpinner = styled(CircularProgress)`
    margin-left: 15px;
    margin-right: 13px;
`;

type SearchBarProps = {
    onChange: (name: string, value: string) => void;
    initialValue?: string;
    placeholder?: string;
    loading?: boolean;
    name: string;
    type?: React.InputHTMLAttributes<unknown>["type"];
};

export const SearchBar: FC<SearchBarProps> = ({ initialValue, placeholder, loading, onChange, name, type = "text" }) => {
    const { t } = useTranslation();
    const [searchInput, setSearchInput] = useState(initialValue || "");
    const debouncedSearchInput = useDebounced(searchInput, "");

    useEffect(() => {
        onChange(name, debouncedSearchInput);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchInput, name, onChange]);

    const clearSearch = useCallback(() => setSearchInput(""), []);

    return (
        <TextField
            variant="outlined"
            type={type}
            fullWidth
            InputProps={{
                value: searchInput,
                onChange: (event) => setSearchInput(event.target.value),
                placeholder: placeholder || t("search.placeholder"),
                startAdornment: (
                    <InputAdornment position="start">{loading ? <SearchBarLoadingSpinner size={18} /> : <SearchBarIcon />}</InputAdornment>
                ),
                endAdornment: searchInput && (
                    <InputAdornment position="end" onClick={clearSearch}>
                        <ClearIcon />
                    </InputAdornment>
                ),
                onKeyDown: ({ key }) => {
                    if (key === "Escape") {
                        clearSearch();
                    }
                },
            }}
        />
    );
};
