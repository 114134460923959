import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "store";

export const NOTIFICATIONS_SLICE_NAME = "notifications";

type NotificationType = "error" | "warning" | "info" | "success";

interface Notification {
    message: String;
    type: NotificationType;
}

interface NotificationsSliceState {
    notifications: Notification[];
}

const initialState: NotificationsSliceState = {
    notifications: [],
};

export const notificationsSlice = createSlice({
    name: NOTIFICATIONS_SLICE_NAME,
    initialState,
    reducers: {
        setNotification: (state, action: PayloadAction<Notification>) => {
            state.notifications.push(action.payload);
        },
        removeNotification: (state) => {
            state.notifications.shift();
        },
    },
});

// Action creators are generated for each case reducer function
export const { setNotification, removeNotification } = notificationsSlice.actions;

export const selectNotificationsSlice = (state: RootState) => state[NOTIFICATIONS_SLICE_NAME];
export const selectNotifications = (state: RootState) => selectNotificationsSlice(state).notifications;

export default notificationsSlice.reducer;
