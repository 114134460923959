import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { GoogleLoginResponse } from "react-google-login";
import { RootState } from "store";
import { getApolloClient, getPublicOperationContext } from "config/apolloClient";
import { SigninGoogleDocument, SigninGoogleMutation, UserCredentialsFragment } from "lib/api";

export const AUTH_SLICE_NAME = "auth";

export enum AuthStatus {
    AUTHORIZED = "AUTHORIZED",
    LOADING = "LOADING",
    LOGIN_ERROR = "LOGIN_ERROR",
    LOGIN_EXPIRED = "LOGIN_EXPIRED",
    UNAUTHORIZED = "UNAUTHORIZED",
}

type State = {
    userCredentials?: UserCredentialsFragment;
    selectedAgentId?: number;
    status: AuthStatus;
    error?: SerializedError;
};

const initialState: State = {
    userCredentials: undefined,
    selectedAgentId: undefined,
    status: AuthStatus.UNAUTHORIZED,
    error: undefined,
};

const isAuthorized = (state: State) => state.status === AuthStatus.AUTHORIZED;
const isLoading = (state: State) => state.status === AuthStatus.LOADING;

export const signinGoogle = createAsyncThunk(
    `${AUTH_SLICE_NAME}/signinGoogle`,
    async (googleLoginResponse: GoogleLoginResponse): Promise<UserCredentialsFragment> => {
        const token = (googleLoginResponse as GoogleLoginResponse).tokenId;
        const { data, errors } = await getApolloClient().mutate<SigninGoogleMutation>({
            mutation: SigninGoogleDocument,
            variables: {
                token,
            },
            context: getPublicOperationContext(),
        });

        if (errors) {
            // NOTE(kjellski): never shown, just to get into the signinGoogle.rejected part of the thunk reducer
            throw new Error("Login rejected, backend returned authorization errors.");
        }

        return data?.signin_google!;
    },
);

export const authSlice = createSlice({
    name: AUTH_SLICE_NAME,
    initialState,
    reducers: {
        setSelectedAgentId: (state: State, action: PayloadAction<number>) => {
            const selectedAgentId = action.payload;

            if (!state.userCredentials) {
                throw new Error(`${AUTH_SLICE_NAME}: setSelectedAgentId - no user credentials`);
            }

            if (!selectedAgentId) {
                throw new Error(`${AUTH_SLICE_NAME}: setSelectedAgentId - no agentId given`);
            }

            state.selectedAgentId = selectedAgentId;
        },

        logout: (state: State) => {
            state.userCredentials = undefined;
            state.error = undefined;
            state.status = AuthStatus.UNAUTHORIZED;
            state.selectedAgentId = undefined;
            getApolloClient().clearStore();
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signinGoogle.pending, (state) => {
                if (isAuthorized(state)) {
                    throw new Error(`${AUTH_SLICE_NAME}: signinGoogle.pending - invalid operation`);
                }

                state.userCredentials = undefined;
                state.status = AuthStatus.LOADING;
            })
            .addCase(signinGoogle.fulfilled, (state, action) => {
                getApolloClient().clearStore();
                const userCredentials = action.payload;

                state.userCredentials = userCredentials;
                const defaultAgent = userCredentials?.login?.agents && userCredentials?.login?.agents[0];
                const selectedAgentId = defaultAgent?.key;
                state.selectedAgentId = selectedAgentId;
                state.status = AuthStatus.AUTHORIZED;
            })
            .addCase(signinGoogle.rejected, (state, action) => {
                getApolloClient().clearStore();
                if (!isLoading(state)) {
                    throw new Error("userSlice::login.rejected - invalid operation");
                }

                state.userCredentials = undefined;
                state.status = AuthStatus.LOGIN_ERROR;
                state.error = action.error ?? undefined;
            });
    },
});

// Action creators are generated for each case reducer function
export const { logout, setSelectedAgentId } = authSlice.actions;

export const selectAuthSlice = (state: RootState) => state.auth;
export const selectUserCredentials = (state: RootState) => selectAuthSlice(state).userCredentials;
export const selectSelectedAgentId = (state: RootState) => selectAuthSlice(state).selectedAgentId;

export default authSlice.reducer;
