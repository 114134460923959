import { useState } from "react";
import { DefaultDebounceTimeoutInMs } from "const/timeouts";
import useDebounce from "react-use/lib/useDebounce";
import useEffectOnce from "react-use/lib/useEffectOnce";

export const useDebounced = <T>(value: T, initialValue: T, timeoutInMs: number = DefaultDebounceTimeoutInMs) => {
    const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, cancel] = useDebounce(
        () => {
            setDebouncedValue(value);
        },
        timeoutInMs,
        [value],
    );

    useEffectOnce(() => () => cancel());

    return debouncedValue;
};
