import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set, unset } from "lodash-es";
import { DefaultPage, DefaultPageSize } from "const/pagination";
import { SearchCriteriaQueryWhere } from "lib/api";

import { RootState } from "store";

export const SEARCH_DEMANDS_LIST_SLICE_NAME = "searchDemands";

interface Pagination {
    page: number;
    pageSize: number;
}

interface SearchDemandsListSliceState {
    filters: SearchCriteriaQueryWhere;
    pagination: Pagination;
}

const initialState: SearchDemandsListSliceState = {
    filters: {},
    pagination: { page: DefaultPage, pageSize: DefaultPageSize },
};

export const searchDemandsListSlice = createSlice({
    name: SEARCH_DEMANDS_LIST_SLICE_NAME,
    initialState,
    reducers: {
        setFilter: (
            state,
            action: PayloadAction<{
                field: keyof SearchCriteriaQueryWhere;
                value: any;
            }>,
        ) => {
            const { field, value } = action.payload;
            if (value || value === false) {
                set(state.filters, field, value);
            } else {
                unset(state.filters, field);
            }
            state.pagination.page = DefaultPage;
        },
        setPage: (state, action: PayloadAction<number>) => {
            const page = action.payload;
            state.pagination.page = page;
        },
        setPageSize: (state, action: PayloadAction<number>) => {
            const pageSize = action.payload;
            state.pagination.pageSize = pageSize;
            state.pagination.page = DefaultPage;
        },
    },
});

export const { setFilter, setPage, setPageSize } = searchDemandsListSlice.actions;

export const selectSearchDemandsListSlice = (state: RootState) => state[SEARCH_DEMANDS_LIST_SLICE_NAME];
export const selectSearchDemandsListFilters = (state: RootState) => selectSearchDemandsListSlice(state).filters;

export default searchDemandsListSlice.reducer;
