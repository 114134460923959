import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import rootReducer from "./slices";
import { NOTIFICATIONS_SLICE_NAME } from "./slices/notifications.slice";

const persistConfig = {
    key: "root",
    storage,
    blacklist: [NOTIFICATIONS_SLICE_NAME],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: false,
        }),
    ],
});

// @ts-ignore
if (process.env.NODE_ENV !== "production" && module.hot) {
    // @ts-ignore
    module.hot.accept("./slices", () => store.replaceReducer(rootReducer));
}

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
