import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import App from "App";
import { GlobalStyles } from "@mui/material";
import reportWebVitals from "reportWebVitals";
import { muiCustomTheme } from "theme";
import { globalStyles } from "components/layout/globalStyles";

// Note: see https://mui.com/customization/how-to-customize/#5-global-css-override
const inputGlobalStyles = <GlobalStyles styles={globalStyles} />;

ReactDOM.render(
    <React.StrictMode>
        {inputGlobalStyles}
        <CssBaseline />
        <ThemeProvider theme={muiCustomTheme}>
            <HashRouter>
                <App />
            </HashRouter>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root"),
);

// eslint-disable-next-line no-console
reportWebVitals(console.debug);
