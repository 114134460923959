import { FC } from "react";
import { Grid } from "@mui/material";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { DetailViewContainer } from "components/containers/DetailViewContainer";
import { DetailViewSection } from "components/containers/DetailViewSection";
import { DetailPageNavigation } from "components/containers/DetailPageNavigation";
import NotFoundPage from "components/layout/NotFoundPage";
import { Loading } from "components/utils/Loading";
import { LettingDetailsFragment, useLettingsDetailQuery } from "lib/api";
import { formatLettingsValue } from "utils/formatters";
import { Gallery } from "components/utils/Gallery";
// import { Tags } from "components/layout/Tags";
import { Go3ToolboxRoutes } from "const/Go3ToolboxRoutes";

export const LettingsDetailPage: FC = () => {
    const { utag } = useParams();
    const { t } = useTranslation();

    const { data, loading } = useLettingsDetailQuery({
        skip: !utag,
        variables: { utag },
    });

    if (loading) return <Loading />;

    if (!utag || !data?.Propresletting) return <NotFoundPage />;

    const { Propresletting } = data;
    const images = Propresletting.photoset.map((img) => ({ original: img?.url || "", thumbnail: img?.url || "" })) || [];
    // const tags = Propresletting.pslinkflag_entities?.map((pslink) => pslink?.flagname) || [];

    return (
        <>
            <DetailPageNavigation
                links={[
                    { href: `${Go3ToolboxRoutes.App.Lettings.fullPath}/${utag}`, title: "Detail View" },
                    { href: `${Go3ToolboxRoutes.App.Lettings.fullPath}/${utag}/activities`, title: t("translation:activities") },
                ]}
            />
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <DetailViewContainer<LettingDetailsFragment>
                        data={Propresletting}
                        fields={[
                            "streetname1",
                            "streetnumber",
                            "propertyname",
                            "postcode",
                            "city",
                            "district_entity.districtname",
                            "area_entity.areaname",
                        ]}
                        label="translation:contacts.address"
                        formatter={formatLettingsValue}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <DetailViewContainer<LettingDetailsFragment>
                        data={Propresletting}
                        fields={[
                            "listingowner_entity.title",
                            "listingowner_entity.company",
                            "listingowner_entity.firstname",
                            "listingowner_entity.surname",
                            "listingowner_entity.streetname1",
                            "listingowner_entity.streetnumber1",
                            "listingowner_entity.postalcode1",
                            "listingowner_entity.city1",
                            "listingowner_entity.mobileoffice",
                            "listingowner_entity.email1",
                        ]}
                        label="backend:listingowner"
                        formatter={formatLettingsValue}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <DetailViewContainer<LettingDetailsFragment>
                        data={Propresletting}
                        fields={[
                            "netrentpmres",
                            "netrentsurfsystpmres",
                            "propertytype",
                            "propertysubtypeValue",
                            "totalsurfaceres",
                            "surfacelivinarea",
                            "plotsurface",
                            "floordesc",
                            "balcony",
                            "garden",
                            "numberlivingareas",
                            "numberbedrooms",
                            "totalnumberbathrooms",
                            "yearconstruction",
                            "plotprice",
                        ]}
                        translations={{
                            netrentsurfsystpmres: "translation:lettings.netrentsurfsystpmres",
                            propertysubtypeValue: "propertysubtype",
                        }}
                        label="translation:key_facts"
                        formatter={formatLettingsValue}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <DetailViewContainer<LettingDetailsFragment>
                        data={Propresletting}
                        fields={[
                            "propertystatus",
                            "lastaction",
                            "typeofmandate",
                            "mandateto",
                            "commissionpercent",
                            "commissionpercentowner",
                            "sign",
                            "keyonshop",
                            "keylabel",
                            "envsource",
                            "utag",
                        ]}
                        translations={{ lastaction: "translation:properties.lastaction", utag: "translation:utag" }}
                        label="translation:internal"
                        formatter={formatLettingsValue}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <DetailViewContainer<LettingDetailsFragment>
                        data={Propresletting}
                        fields={["commentsbasicdata"]}
                        translations={{ commentsbasicdata: "commentsgeneral" }}
                        label="commentsbasicdata"
                        formatter={formatLettingsValue}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <DetailViewSection label={t("backend:photoset")}>
                        <Gallery images={images} />
                    </DetailViewSection>
                </Grid>
            </Grid>
        </>
    );
};
