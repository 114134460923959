/* global Entity */
import React, { FC, useState } from "react";
import { DetailPageNavigation } from "components/containers/DetailPageNavigation";
import { Go3ToolboxRoutes } from "const/Go3ToolboxRoutes";
import { useParams } from "react-router-dom";
import { selectActivitiesListSlice } from "store/slices/activitiesList.slice";
import { ActivityQueryOrder, OrderBy, useActivitiesListQuery } from "lib/api";
import { useAppSelector } from "hooks/redux";
import { useTranslation } from "react-i18next";
import { formatEntity } from "utils/formatters";
import { ActivitiesListContent } from "./ActivitiesListContent";
import { ActivitiesListHeader } from "./ActivitiesListHeader";

interface ActivitiesListPageProps {
    entity: Entity;
}
export const ActivitiesListPage: FC<ActivitiesListPageProps> = ({ entity }) => {
    const { utag } = useParams();
    const { t } = useTranslation("backend");

    const {
        filters,
        pagination: { page, pageSize },
    } = useAppSelector(selectActivitiesListSlice);
    const [orderBy] = useState<ActivityQueryOrder>({
        status: OrderBy.Asc,
    });
    const { data, loading } = useActivitiesListQuery({
        variables: {
            page: page + 1,
            pageSize,
            orderBy,
            where: { ...filters, [formatEntity(entity)]: utag },
        },
    });

    const links = [
        { href: `${Go3ToolboxRoutes.App[entity].fullPath}/${utag}`, title: "Detail View" },
        { href: `${Go3ToolboxRoutes.App[entity].fullPath}/${utag}/activities`, title: t("translation:activities") },
    ];
    if (entity === "Contacts") {
        links.push({ href: `${Go3ToolboxRoutes.App[entity].fullPath}/${utag}/demands`, title: t("searchcriteria-plural") });
    }

    return (
        <>
            <DetailPageNavigation links={links} />
            <ActivitiesListHeader />
            <ActivitiesListContent data={data?.Activities.rows || []} loading={loading} entity={entity} />
        </>
    );
};
