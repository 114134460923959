import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

export const configureI18next = () => {
    i18next
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            fallbackLng: "en-GB",

            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
        });
};
