import { FC, useState } from "react";

import { ContactQueryOrder, OrderBy, useContactsListQuery } from "lib/api";
import { selectContactsListSlice } from "store/slices/contactsList.slice";
import { useAppSelector } from "hooks/redux";
import { ContactListHeader } from "./ContactsListHeader";
import ContactsListContent from "./ContactsListContent";

export const ContactsListPage: FC = () => {
    const {
        filters,
        pagination: { page, pageSize },
    } = useAppSelector(selectContactsListSlice);
    const [orderBy] = useState<ContactQueryOrder>({
        surname: OrderBy.Asc,
    });

    const { data, loading } = useContactsListQuery({
        variables: {
            page: page + 1,
            pageSize,
            where: filters,
            orderBy,
        },
    });

    return (
        <>
            <ContactListHeader />
            <ContactsListContent data={data?.contacts.rows || []} loading={loading} />
        </>
    );
};
