export enum Environment {
    local = "local",
    dev = "dev",
    stage = "stage",
    prod = "prod",
}

export type EnvironmentConfig = {
    apiUrl: string;
};

export type Environments = Record<Environment, EnvironmentConfig>;

export const envs: Environments = {
    local: {
        apiUrl: "http://localhost:8000/query",
    },
    dev: {
        apiUrl: "https://go3-toolbox-api.envdevel.com/query",
    },
    stage: {
        apiUrl: "https://go3-toolbox-api.envstage.com/query",
    },
    prod: {
        apiUrl: "https://go3-toolbox-api.engelvoelkers.com/query",
    },
};

let currentEnv: EnvironmentConfig

export const configureEnv = (env?: Environment): EnvironmentConfig => {
    if (env) {
        currentEnv = envs[env];
    } else {
        currentEnv = envs[(process.env.REACT_APP_ENV as Environment) || Environment.local];
    }

    return currentEnv
};

export const getEnv = () => currentEnv

