import React, { FC } from "react";
import { Typography, Grid } from "@mui/material";

export const DetailViewField: FC<{ label: string }> = ({ children, label }) => (
    <Grid container spacing={1} justifyContent="space-between" sx={{ marginBottom: 1, overflowWrap: "break-word" }}>
        <Grid item xs={6}>
            <Typography variant="body1">
                <b>{label}</b>
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="body2">{children || "-"}</Typography>
        </Grid>
    </Grid>
);
