import React from "react";
import { useParams } from "react-router";
import { Grid } from "@mui/material";
import { DetailViewContainer } from "components/containers/DetailViewContainer";
import { useViewingsDetailQuery, ViewingDetailsFragment } from "lib/api";
import { Loading } from "components/utils/Loading";
import NotFoundPage from "components/layout/NotFoundPage";
import { formatViewingsValue } from "utils/formatters";

export const ViewingPage = () => {
    const { viewingkey } = useParams();
    const { data, loading } = useViewingsDetailQuery({ skip: !viewingkey, variables: { key: +viewingkey } });

    if (loading) return <Loading />;

    if (!viewingkey || !data?.Viewing) return <NotFoundPage />;

    const { Viewing } = data;
    return (
        <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <DetailViewContainer<ViewingDetailsFragment>
                    data={Viewing}
                    fields={[
                        "viewingstatus",
                        "laststatuschangeddate",
                        "viewingsubject",
                        "viewingdate",
                        "viewingstarttime",
                        "viewingendtime",
                        "viewingcomments",
                        "viewingfeedback",
                        "unifieddate",
                        "viewinglinklinkcontact",
                        "viewinglinkpropressale",
                        "viewinglinkpropresletting",
                    ]}
                    label="translation:viewing"
                    formatter={formatViewingsValue}
                />
            </Grid>
        </Grid>
    );
};
