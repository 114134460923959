/* global Maybe, ValueOf */
import React from "react";
import { isEmpty, isArray, isNil, every, isString, isObject } from "lodash-es";
import { Box, Typography } from "@mui/material";
import { PropresLettingSearchCriteria, PropresSalesSearchCriteria, FloatRange, CustomizedDemand, Area, District } from "lib/api";
import { formatPrice } from "utils/formatters";

interface TableCellFieldProps<T> {
    value: Maybe<T>;
    label: string;
    type?: "price";
}

const isFloatRange = (value: ValueOf<PropresSalesSearchCriteria & PropresLettingSearchCriteria>): value is FloatRange =>
    (value as FloatRange).Min !== undefined && (value as FloatRange).Max !== undefined;

const isCDFArray = (value: ValueOf<PropresSalesSearchCriteria & PropresLettingSearchCriteria>): value is CustomizedDemand[] =>
    (value as CustomizedDemand[]).every((cdf) => cdf.cdfname !== undefined);

const isAreaArray = (value: ValueOf<PropresSalesSearchCriteria & PropresLettingSearchCriteria>): value is Area[] =>
    (value as Area[]).every((area) => area.areaname !== undefined);

const isDistrictArray = (value: ValueOf<PropresSalesSearchCriteria & PropresLettingSearchCriteria>): value is District[] =>
    (value as District[]).every((area) => area.districtname !== undefined);

const isPrice = (type: string | undefined) => type === "price";

export const TableCellField = <T extends ValueOf<PropresSalesSearchCriteria & PropresLettingSearchCriteria>>({
    label,
    value,
    type,
}: TableCellFieldProps<T>) => {
    if (isNil(value) || isEmpty(value)) {
        return null;
    }

    if (isArray(value)) {
        if (every(value, isString)) {
            return (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body2">{label}</Typography>
                    <Typography variant="body2">{value.join(",")}</Typography>
                </Box>
            );
        }
        if (isCDFArray(value)) {
            return (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body2">{label}</Typography>
                    <Typography variant="body2">{value.map((lcdf) => lcdf.cdfname).join(",")}</Typography>
                </Box>
            );
        }
        if (isAreaArray(value)) {
            return (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body2">{label}</Typography>
                    <Typography variant="body2">{value.map((lcdf) => lcdf.areaname).join(",")}</Typography>
                </Box>
            );
        }
        if (isDistrictArray(value)) {
            return (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body2">{label}</Typography>
                    <Typography variant="body2">{value.map((district) => district.districtname).join(",")}</Typography>
                </Box>
            );
        }
    }

    if (isObject(value)) {
        if (isFloatRange(value)) {
            return isPrice(type) ? (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body2">{label}</Typography>
                    <Typography variant="body2">{`${formatPrice(value.Min)} to ${formatPrice(value.Max)}`}</Typography>
                </Box>
            ) : (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body2">{label}</Typography>
                    <Typography variant="body2">{`${value.Min} to ${value.Max}`}</Typography>
                </Box>
            );
        }
    }

    return null;
};
