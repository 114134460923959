import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { FloatRange, PropresLettingSearchCriteria, PropresSalesSearchCriteria, Maybe, CustomizedDemand, Area, District } from "lib/api";
import { useTranslation } from "react-i18next";
import { TableCellField } from "./TableCellField";

interface PropertySalesCriteriaCellProps {
    properties: Partial<PropresSalesSearchCriteria & PropresLettingSearchCriteria>;
    setRowHeight?: (height: number) => void;
}

export const PropertyCriteriaCell = ({ properties, setRowHeight }: PropertySalesCriteriaCellProps) => {
    const {
        type,
        subType,
        linkcdf1,
        linkcdf2,
        areaList,
        districtList,
        totalSurfaceres,
        surfaceLivinArea,
        plotSurface,
        numberLivingAreas,
        numberBedrooms,
        askingPrice,
        netRentPmres,
        totalRentPm,
        shortTermRental,
    } = properties;
    const { t } = useTranslation("backend");
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref?.current?.clientHeight && setRowHeight) {
            setRowHeight(ref?.current?.clientHeight);
        }
    }, []);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} ref={ref}>
            <TableCellField<string[] | undefined> label={t("propertytype")} value={type} />
            <TableCellField<string[] | undefined> label={t("propertysubtype")} value={subType} />
            <TableCellField<Maybe<CustomizedDemand[]> | undefined> label={t("linkcdf1")} value={linkcdf1} />
            <TableCellField<Maybe<CustomizedDemand[]> | undefined> label={t("linkcdf2")} value={linkcdf2} />
            <TableCellField<Area[] | undefined> label={t("area")} value={areaList} />
            <TableCellField<District[] | undefined> label={t("district")} value={districtList} />
            <TableCellField<Maybe<FloatRange> | undefined> label={t("totalsurfaceres")} value={totalSurfaceres} />
            <TableCellField<Maybe<FloatRange> | undefined> label={t("surfacelivinarea")} value={surfaceLivinArea} />
            <TableCellField<Maybe<FloatRange> | undefined> label={t("plotsurface")} value={plotSurface} />
            <TableCellField<Maybe<FloatRange> | undefined> label={t("numberlivingareas")} value={numberLivingAreas} />
            <TableCellField<Maybe<FloatRange> | undefined> label={t("numberbedrooms")} value={numberBedrooms} />
            <TableCellField<Maybe<FloatRange> | undefined> label={t("askingprice")} value={askingPrice} type="price" />
            <TableCellField<Maybe<FloatRange> | undefined> label={t("netrentpmres")} value={netRentPmres} type="price" />
            <TableCellField<Maybe<FloatRange> | undefined> label={t("totalrentpm")} value={totalRentPm} type="price" />
            <TableCellField<Maybe<FloatRange> | undefined> label={t("shorttermrental")} value={shortTermRental} type="price" />
        </Box>
    );
};
