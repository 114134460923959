import React, { FC } from "react";
import { useDesktopView } from "utils/useDesktopView";
import { ContactsListRowFragment } from "lib/api";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectContactsListSlice, setPage, setPageSize } from "store/slices/contactsList.slice";
import { DesktopListViewLayout } from "components/containers/DesktopListViewLayout";
import { ContactsListMobileTable } from "./ContactsListMobileTable";
import { ContactsListTable } from "./ContactsListTable";

export const ContactsListContent: FC<{
    data: Array<ContactsListRowFragment>;
    loading: boolean;
}> = ({ data, loading }) => {
    const showDesktopView = useDesktopView();
    const dispatch = useAppDispatch();
    const {
        pagination: { page, pageSize },
    } = useAppSelector(selectContactsListSlice);

    const handleChangePage = (newPage: number) => {
        dispatch(setPage(newPage));
    };

    const handleChangePageSize = (newPageSize: number) => {
        dispatch(setPageSize(newPageSize));
    };

    return showDesktopView ? (
        <DesktopListViewLayout>
            <ContactsListTable
                data={data || []}
                loading={loading}
                page={page}
                setPage={handleChangePage}
                pageSize={pageSize}
                setPageSize={handleChangePageSize}
            />
        </DesktopListViewLayout>
    ) : (
        <ContactsListMobileTable
            data={data || []}
            loading={loading}
            page={page}
            setPage={handleChangePage}
            pageSize={pageSize}
            setPageSize={handleChangePageSize}
        />
    );
};

ContactsListContent.propTypes = {};

export default ContactsListContent;
