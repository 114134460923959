/* global TableProps, Entity */
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ActivitiesListRowFragment } from "lib/api";
import { Loading } from "components/utils/Loading";
import { useNavigateTo } from "hooks/useNavigateTo";
import { PaginationSizes } from "const/pagination";
import { formatDate, getActivityPath } from "utils/formatters";
import { useParams } from "react-router";
import { tActivityStatus } from "utils/translateBackendEnums";

export const ActivitiesListTable: FC<TableProps<ActivitiesListRowFragment> & { entity: Entity }> = ({
    data,
    loading,
    page,
    setPage,
    pageSize,
    setPageSize,
    entity,
}) => {
    const { t } = useTranslation("backend");
    const { utag: entityUtag } = useParams();
    const navigateTo = useNavigateTo();

    const columns: GridColDef[] = [
        { field: "type", headerName: t("translation:type"), width: 120 },
        { field: "agent", headerName: t("agent"), width: 180 },
        { field: "status", headerName: t("status"), width: 120 },
        { field: "title", headerName: t("translation:title"), width: 180 },
        { field: "link_contacts", headerName: t("contact-plural"), width: 180, flex: 0.2 },
        { field: "link_properties", headerName: t("translation:link_properties"), width: 180, flex: 0.5 },
        { field: "unified_date", headerName: t("unitdate"), width: 160 },
    ];

    const rows =
        data?.map((activity: ActivitiesListRowFragment) => {
            const { createdfor, link_contacts_entities, link_propressales_entities, unified_date, utag, status, type } = activity;
            return {
                ...activity,
                id: utag,
                agent: `${createdfor?.firstname} ${createdfor?.surname}`,
                link_contacts: `${link_contacts_entities.map((link) => `${link.firstname} ${link.surname}`).join("; ")}`,
                link_properties: `${link_propressales_entities.map((link) => `${link.streetname1} ${link.streetnumber}`).join("; ")}`,
                unified_date: formatDate(unified_date),
                status: tActivityStatus(status),
                type: t(`backend:${type}`),
                typeEng: type,
            };
        }) || [];

    if (loading) return <Loading />;
    return (
        <DataGrid
            rows={rows}
            density="compact"
            columns={columns}
            onRowClick={({ row }) => navigateTo(getActivityPath(row.typeEng, entity), { utag: entityUtag, [`${row.typeEng}key`]: row.key })}
            autoHeight
            pagination
            rowCount={(page + 1) * pageSize + 1}
            paginationMode="server"
            rowsPerPageOptions={PaginationSizes}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            componentsProps={{
                pagination: {
                    labelRowsPerPage: t("translation:rows_per_page"),
                },
            }}
        />
    );
};
