import React from "react";
import { useParams } from "react-router";
import { Grid } from "@mui/material";
import { DetailViewContainer } from "components/containers/DetailViewContainer";
import { DocumentDetailsFragment, useDocumentDetailQuery } from "lib/api";
import { Loading } from "components/utils/Loading";
import NotFoundPage from "components/layout/NotFoundPage";
import { formatDocumentValue } from "utils/formatters";

export const DocumentPage = () => {
    const { documentkey } = useParams();
    const { data, loading } = useDocumentDetailQuery({ skip: !documentkey, variables: { key: +documentkey } });

    if (loading) return <Loading />;

    if (!documentkey || !data?.Document) return <NotFoundPage />;

    const { Document } = data;
    return (
        <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <DetailViewContainer<DocumentDetailsFragment>
                    data={Document}
                    fields={[
                        "documenttype",
                        "documentstatus",
                        "unifieddate",
                        "documentlinkcontacts_entities",
                        "documentlinkpropressales_entities",
                        "documentlinkpropreslettings_entities",
                        "documentsavedfile",
                    ]}
                    label="document"
                    translations={{
                        documentlinkcontacts_entities: "documentlinkcontacts",
                        documentlinkpropressales_entities: "documentlinkpropressales",
                        documentlinkpropreslettings_entities: "documentlinkpropreslettings",
                    }}
                    formatter={formatDocumentValue}
                />
            </Grid>
        </Grid>
    );
};
