import i18n from "i18next";

import { ActivityStatus, Contactcategory, Contactstatus, Maybe, SalesPropertystatus, SalesPropertytype } from "lib/api";

export const tContactstatus = (contactstatus: Maybe<Contactstatus>): string =>
    contactstatus ? i18n.t(`backend:contactstatus.${contactstatus}`) : "";

export const tContactcategory = (contactcategory: Maybe<Contactcategory>): string =>
    contactcategory ? i18n.t(`backend:contactcategory.${contactcategory}`) : "";

export const tPropertyStatus = (properytStatus: Maybe<SalesPropertystatus>): string =>
    properytStatus ? i18n.t(`backend:sales_propertystatus.${properytStatus}`) : "";

export const tPropertyType = (propertyType: Maybe<SalesPropertytype> | undefined): string =>
    propertyType ? i18n.t(`backend:sales_propertytype.${propertyType}`) : "";

export const tActivityStatus = (activityStatus: Maybe<ActivityStatus> | undefined): string =>
    activityStatus ? i18n.t(`backend:taskstatus.${activityStatus}`) : "";
